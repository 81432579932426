import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import SuccessIcon from '../icons/Success';
import ErrorIcon from '../icons/Error';
import WarningIcon from '../icons/Warning';
import InfoIcon from '../icons/Info';
import _CloseIcon from '../icons/Close';

export const NotificationsContext = React.createContext();

const NotificationText = styled.div`

`;
const CloseIcon = styled(_CloseIcon)`
  cursor: pointer;
`;
const NotificationBubble = styled.div`
  position: fixed;
  top: ${p=>(p.$i*70)+15}px;
  right: 1em;
  display: flex;
  background-color: ${p=>p.$background};
  color: white;
  padding: 1em;
  gap: .5em;
  border-radius: 4px;
  align-items: center;
  z-index: 31;
  transition: top 1s;
  height: 56px;
`;
const icons = {
  success: SuccessIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  info: InfoIcon,
}
const colors = {
  success: '#27ae60',
  error: '#e8282a',
  warning: '#FFA000',
  info: '#3498db',
}
const Notification = ({ notification, i, onClose, ...props }) => {
  const Actions = props.Actions || notification.Actions;

  // console.log(`Notification notification.text`, notification.text);
  // console.log(`Notification typeof Actions`, typeof Actions);
  const handleClose = () => onClose(notification._id);
  const { text, variant } = notification;
  const background = colors[variant] || colors.info;
  const Icon = icons[variant] || icons.info;
  return (
    <NotificationBubble $background={background} $i={i}>
      <Icon />
      <NotificationText>{text}</NotificationText>
      {Actions ?<Actions onClose={handleClose}/>:null}
      <CloseIcon onClick={handleClose}/>
    </NotificationBubble>
  )
}
// const sampleNotifiations = [
//   { _id:"1111", text:"Hello There!" },
//   { _id:"2222", text:"Are you sure you want to do this?", variant: "warning" },
//   { _id:"3333", text:"There was a problem!", variant: "error" },
//   { _id:"4444", text:"Process completed successfully.", variant: "success" },
// ]
export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])
  const notificationsRef = useRef(notifications);
  notificationsRef.current = notifications; 
  const hideMessage = _id => {
    const notifications = notificationsRef.current;
    const notification = notifications.filter(n=>n._id===_id);
    if(notification.timer) clearTimeout(notification.timer);
    // We need to wait a bit to animate the message going away
    const timer = setTimeout(() => removeMessage(_id), 500);
    setNotifications(notifications.map(n=>{
      if(n._id!==_id) return n;
      return { ...n, i: 0, timer }
    }))
  }
  const removeMessage = _id => {
    // console.log(`removing _id`,  _id);
    const notifications = notificationsRef.current;
    const notification = notifications.filter(n=>n._id===_id);
    if(notification.timer) clearTimeout(notification.timer);
    setNotifications(notifications.filter(n=>n._id!==_id))
  }
  const showMessage = (text, variant='info', options={}) => {
    const { _id=uuid(), delay=6000, Actions } = options;
    const timer = delay ? setTimeout(() => hideMessage(_id), delay) : null;
    // console.log(`showMessage text`, text);
    // console.log(`showMessage typeof Actions`, typeof Actions);
    setNotifications([ ...notifications, { _id, text, variant, timer, Actions } ])
  }
  return (
    <NotificationsContext.Provider value={showMessage}>
      {children}
      {notifications.map((notification, i) => <Notification key={notification._id} notification={notification} onClose={removeMessage} i={i}/>)}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => {
  const showMessage = useContext(NotificationsContext);
  return showMessage
}
export default useNotifications