import React, { useState } from 'react'
import styled from 'styled-components';
import TextField from '../../atoms/TextField';
import _Container from '../../atoms/Container';
import download from 'downloadjs';
import DownloadIcon from '../../icons/Download';
import { FabGroup, Fab } from '../../atoms/Fab';
import { listSnippets } from '../../graphql/queries';
import Query from '../../atoms/Query';
import Row from '../../atoms/Row';
import Pre from '../../atoms/Pre';
import Load from './Load';
import Tray from './Tray';

export const destHeader = "DATE,TYPE,VENDOR,EXPENSE,INCOME,CATEGORY,DESCRIPTION,DETAIL,FROM,RECEIPT,CHECK,NEGATIVE\r\n";
const VENDOR=2;
const CATEGORY=5;
const DETAIL=7;
const CHECK=10;
const Container = styled(_Container)`
  padding-top: 2em;
`;
const Table = styled.table`
  margin-top: 2em;
  width: 100%;
  border-collapse: collapse; 
`;
const TableRow = styled.tr`
`;
const HeaderCell = styled.th`
  padding: .5em;
  text-align: left;
`;
const Cell = styled.td`
  padding: .5em;
  border-top: solid 1px rgb(204, 204, 204);
`;
const Body = styled.tbody``;
const Head = styled.thead``;
export const Converter = ({ listSnippets={items:[]}, refetch }) => {
  const snippets = listSnippets.items;
  // const [from, setFrom] = useState("")
  const [values, setValues] = useState({
    bank:"truist",
    client:"4de0ed79-3fa0-4176-9d4c-9d90f3c6e751",
    from:"",
  });
  const [output, setOutput] = useState([])
  const [transactions, setTransactions] = useState([])
  const [modalOpen, setModalOpen] = useState()
  const handleMouseUp = () => {
    const key = window.getSelection().toString();
    if(key) {
      setValues({ ...values, key })
      setModalOpen(true)
    }
  }
  const handleChange = (e,i,j) => {
    // [...snippets.slice(0,index), ...snippets.slice(index+1)]
    const trx = [...transactions[i].slice(0,j), e.target.value, ...transactions[i].slice(j+1)];
    setTransactions([...transactions.slice(0,i), trx, ...transactions.slice(i+1)])
    // setVendors({...vendors, [i]:e.target.value});
  }
  const handleLoad = (final) => {
    setOutput(final)
    setTransactions(Array(final.length).fill(Array(11).fill("")))
  }
  const getSnippet = (description, field) => {
    console.log(`getSnippet`, description, field);
    for (var i = 0; i < snippets.length; i++) {
      if((description||"").indexOf(snippets[i].key)>-1) return snippets[i][field];
    }
    return ""
  }
  const getValue = (i,j) =>{
    if(j===VENDOR) return transactions[i][j]||getSnippet(output[i][6], "vendor");
    if(j===CATEGORY) return transactions[i][j]||getSnippet(output[i][6], "category");
    return transactions[i][j]||output[i][j];
  }
  const handleConvert = () => {
    let result = destHeader+output.map((trx,i)=>{
      const vendor = transactions[i][2]||getSnippet(trx[6]);
      return '"'+trx.map((field,j)=>getValue(i,j)).join(`","`)+'"';
      // return '"'+[...trx.slice(0,2),vendor,...trx.slice(3,8), from, ...trx.slice(9,12)]
    }).join("\r\n");
    // console.log(`result`, result);
    download(result, "transactions.csv", "text/plain");
  }
  // console.log(`transactions`, transactions);
  const editableIndexes = [VENDOR, CATEGORY, DETAIL, CHECK]
  return (
    <Container>
      <Load onLoad={handleLoad} values={values} setValues={setValues}/>
      <Table>
        <Head>
          <HeaderCell>DATE</HeaderCell>
          <HeaderCell>TYPE</HeaderCell>
          <HeaderCell>VENDOR</HeaderCell>
          <HeaderCell>EXPENSE</HeaderCell>
          <HeaderCell>INCOME</HeaderCell>
          <HeaderCell>CATEGORY</HeaderCell>
          <HeaderCell>DESCRIPTION</HeaderCell>
          <HeaderCell>DETAIL</HeaderCell>
          <HeaderCell>FROM</HeaderCell>
          <HeaderCell>RECEIPT</HeaderCell>
          <HeaderCell>CHECK</HeaderCell>
          <HeaderCell>NEGATIVE</HeaderCell>
        </Head>
        <Body>
          {output.map((trx,i)=>(
            <TableRow key={i}>
              {trx.map((field,j)=>{
                if(j===6) return <Cell onMouseUp={handleMouseUp}>{trx[j]}</Cell>;
                if(j===8) return <Cell>{values.from}</Cell>;
                if(editableIndexes.includes(j)) return <Cell><TextField value={getValue(i,j)} onChange={e=>handleChange(e,i,j)}/></Cell>;
                return <Cell>{trx[j]}</Cell>;
              })}
            </TableRow>
          ))}
        </Body>
      </Table>
      <Tray {...{ snippets, modalOpen, setModalOpen, refetch, values, setValues }}/>
      {/*<Pre>{JSON.stringify(values)}</Pre>*/}
      <FabGroup right>
        {output.length?<Fab primary Icon={DownloadIcon} onClick={handleConvert}/>:null}
      </FabGroup>
    </Container>
  )
}

const ConverterWithData = props => {
  const variables = { limit: 999999 }
  return <Query Component={Converter} query={listSnippets} variables={variables} {...props}/>
}
export default ConverterWithData;