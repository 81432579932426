import React from 'react'
import styled from 'styled-components';
import Query from '../../atoms/Query';
import Card from './Card';
import getUrl from '../../lib/getUrl';
import { listWriters } from '../../graphql/queries';
// import useUser from '../../hooks/useUser';
import { FabGroup, AddFab, EditFab } from '../../atoms/Fab';

const Wrapper = styled.div`
  padding: 4em;
`;
const Author = styled.div`
  display: flex;
`;
export const List = ({ listWriters={items:[]} }) => {
  const writers = listWriters.items;
  return (
    <Wrapper>
      {writers.map(writer=>(
        <Author>
          <Card key={writer.id} {...writer} />
          <EditFab to={getUrl('writers','edit', writer.id)}/>
        </Author>
      ))}
      <FabGroup right><AddFab to={getUrl('writers','new')}/></FabGroup>
    </Wrapper>
  )
}
const ListWithData = props => <Query Component={List} query={listWriters} {...props}/>

export default ListWithData;