const writerFields = `
  id
  name
  avatarUrl
  description
`;
const postFields = `
  id
  createdAt
  cardImgUrl
  bannerImgUrl
  category
  snippet
  tags
  title
  body
  writer {
    ${writerFields}
  }
`;
export const getPostQuery = `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      ${postFields}
    }
  }
`
export const createPostMutation = `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      ${postFields}
    }
  }
`
export const updatePostMutation = `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      ${postFields}
    }
  }
`;
export const deletePostMutation = `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      ${postFields}
    }
  }
`
export const recentPostsQuery = `
  query PostsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        cardImgUrl
        category
        title
      }
      nextToken
    }
  }
`;