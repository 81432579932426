import { PDFDocument, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import getDisplayName from '../../lib/getDisplayName';
// import {formatCents} from '../../lib/cents';
import download from 'downloadjs';

export const generate = async ({ contact, invoice }) => {
  console.log(`{ contact, invoice }`, { contact, invoice });
  const fontUrl = '/fonts/FuturaNowHeadline.ttf'
  const bold = '/fonts/FuturaNowHeadlineBold.ttf'
  const light = '/fonts/FuturaNowHeadlineLight.ttf'
  // const fontUrl = '/fonts/FuturaNowTextLight.ttf'
  // const bold = '/fonts/FuturaNowTextBold.ttf'
  // const light = '/fonts/FuturaNowTextLight.ttf'
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
  const boldBytes = await fetch(bold).then(res => res.arrayBuffer())
  const lightBytes = await fetch(light).then(res => res.arrayBuffer())
  const charcoal = rgb(0.17,0.18,0.22);
  const white = rgb(1,1,1);
  const jpgUrl = '/images/letterhead.jpg'
  const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
  

  const pdfDoc = await PDFDocument.create()

  pdfDoc.registerFontkit(fontkit)
  const FuturaNowHeadlineBold = await pdfDoc.embedFont(boldBytes, { features: { liga: false } })
  const FuturaNowHeadlineLight = await pdfDoc.embedFont(lightBytes, { features: { liga: false } })
  const FuturaNowHeadlineMedium = await pdfDoc.embedFont(fontBytes, { features: { liga: false } })
  const jpgImage = await pdfDoc.embedJpg(jpgImageBytes)
  const jpgDims = jpgImage.scale(.5)

  const page = pdfDoc.addPage()
  const { width, height } = page.getSize();
  let values = {y:height-115-32, x:32+16, size:15, font:FuturaNowHeadlineMedium, color:charcoal};
  const updateDefaults = true;
  const drawText = (text="", options) => {
    console.log(`{text, options}`, {text, options});
    const { newLine=true, updateDefaults, marginBottom=5, justify="left", ...props } = options||{};
    const currentValues = {...values, ...props, justify };
    console.log(`currentValues`, currentValues);
    if(justify==="right") {
      const textWidth = values.font.widthOfTextAtSize(text||"", values.size)
      currentValues.x-=textWidth;
    } else if(justify==='center') {
      const textWidth = values.font.widthOfTextAtSize(text||"", values.size)
      currentValues.x-=textWidth/2;
    }
    if(updateDefaults) values = currentValues;
    else values = {...values, x: currentValues.x, y: currentValues.y };
    const textHeight = values.font.heightAtSize(currentValues.size)
    // console.log(`textHeight`, textHeight);
    // console.log(`values`, values);
    page.drawText((text||"").toString(), currentValues);
    if(newLine) values.y-=textHeight+marginBottom;
  }
  const move = (x,y) => values = {...values, x:values.x-(x||0), y:values.y-(y||0)}
  page.drawImage(jpgImage, {
    x: 0,
    y: 842-jpgDims.height,
    width: jpgDims.width,
    height: jpgDims.height,
  })
  drawText(getDisplayName(contact), { font: FuturaNowHeadlineBold, y:values.y-20 })
  drawText(contact.address.street, { marginBottom: 0});
  if(contact.address.apt) drawText(contact.address.apt,{ marginBottom: 0});
  if(contact.address.city) drawText(`${contact.address.city}, ${contact.address.stateCode} ${contact.address.postalCode||""}`,{ marginBottom: 0});
  drawText(contact.address.country||"",{ marginBottom: 0});
  drawText("Invoice No.", { font: FuturaNowHeadlineBold, marginBottom: 5, y: 540 })
  drawText(invoice.number)
  drawText("Invoice Date", { font: FuturaNowHeadlineBold, marginBottom: 5, y: 540, x: 238+20 })
  drawText(invoice.date)
  drawText("Due By", { font: FuturaNowHeadlineBold, marginBottom: 5, y: 540, x:416+50 })
  drawText(invoice.due)
  // console.log(`values.y`, values.y);
  // console.log(`values.y-440`, values.y-440);
  move(0,45);
  page.drawRectangle({ x: 32, y: values.y-15, width: width-32*2, height: 40, color: charcoal })
  drawText("Item Name", { font: FuturaNowHeadlineBold, newLine:false, color: white, x: 48 })
  drawText("Unit Price", { font: FuturaNowHeadlineBold, newLine:false, color: white, x: 318 })
  drawText("Quantity", { font: FuturaNowHeadlineBold, newLine:false, color: white, x:417 })
  drawText("Total", { font: FuturaNowHeadlineBold, marginBottom: 20, color: white, x:506 })

  for (var i = 0; i < invoice.items.length; i++) {
    const item = invoice.items[i];
    // console.log(`item`, item);
    drawText(item.description, { newLine:false, x: 48 })    
    drawText(item.price, { newLine:false, x: 318 })
    if(item.quantity!=1) drawText(item.quantity, { newLine:false, x:417 })
    else drawText(item.quantity, { newLine:false, x:417 })
    
    drawText(item.total, { marginBottom: 5, x:547, justify: 'right' })
  }
  page.drawRectangle({ x: 32, y: 80+50, width: width-32*2, height: 40, color: charcoal })
  drawText("Total:", { x: 417, color: white, y:95+50, font: FuturaNowHeadlineBold, })
  drawText(invoice.total, { x: 547, color: white, y:95+50, font: FuturaNowHeadlineBold, justify: 'right' })
  // console.log(`values`, values);
  drawText("P O Box 282, Richmond, VA 23218", { x: width/2, y:60+20, justify:'center', size:13, font:FuturaNowHeadlineLight, updateDefaults })
  drawText("804.241.5187", { x: width/2, justify:'center' })
  drawText("arodgers@woodbourneaccounting.com", { x: width/2, justify:'center' })
  console.log(`width`, width);
  // return l
  // page.drawRectangle({ x: 32, y: 80, width: width-32*2, height: 40, color: charcoal })
  // return null;
  const pdfBytes = await pdfDoc.save()
  download(pdfBytes, `${invoice.number} ${getDisplayName(contact)}.pdf`, "application/pdf");
  // const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  // window.location=pdfDataUri;
  // const docUrl = URL.createObjectURL(
  //   new Blob([pdfBytes], { type: "application/pdf" })
  // );
  // console.log(`docUrl`, docUrl);
  // window.location=docUrl;

}
export default generate;