import formatDate from '../../lib/formatdates';
import { isCustom } from './lib';
const encoding = ';CHARSET=UTF-8';
const getDisplayName = contact => {
  if(!contact) return "New Contact";
  let result = "";
  if(contact.kind==="individual"){
    result = `${contact.middleName||""} ${contact.nickname?`(${contact.nickname})`:""}`.trim();
    result = `${contact.firstName||""} ${result} ${contact.lastName||""}`.trim();
    result = `${contact.prefix||""} ${result} ${contact.suffix||""}`.trim();
  } else {
    result = (contact.org||"").trim();
  }
  // console.log(`result`, result);
  return result||"New Contact"
}
const encode = value => {
  if(!value) return ""
  if (typeof(value) !== 'string') {
    value = '' + value;
  }
  return value.replace(/\n/g, '\\n').replace(/,/g, '\\,').replace(/;/g, '\\;');
}
const keysThatDontNeedEncodingPrefix = ['TEL','GENDER','UID','BDAY','ANNIVERSARY']
const encodeLine = (key, value, type)=>{
  if(!value) return ""
    const encodingPrefix = keysThatDontNeedEncodingPrefix.indexOf(key)>-1?"":encoding;
  if(typeof value==="string") return `${key}${type?";"+type+":":":"}${encode(value)}\r\n`;
  return `${key}${encodingPrefix}${type?";"+type+":":":"}${value.map(v=>encode(v)).join(";")}\r\n`;
}
export const toVCard = contact => {
  if(!contact) return false;
  let customFields = 0;
  let vCard="BEGIN:VCARD\r\nVERSION:3\r\n"
  const displayName = contact.displayName||getDisplayName(contact);
  const customItem = (key, value, label) => {
    const valueLine = encodeLine(key,value);
    const item = `item${customFields}.${valueLine}item${customFields}.X-ABLabel:${encode(label)}\r\n`
    customFields+=1;
    return item;
  }
  // vCard+=`FN:${encode(displayName)}\r\n`
  vCard+=encodeLine('FN',displayName);
  vCard+=encodeLine('N',[contact.lastName, contact.firstName, contact.middleName, contact.prefix, contact.suffix]);
  vCard+=encodeLine('NICKNAME',contact.nickname);
  vCard+=encodeLine('GENDER',contact.gender);
  vCard+=`UID:urn:uuid:${contact.id}\r\n`;
  if(contact.birthday) vCard+=encodeLine('BDAY',formatDate(contact.birthday,"YYYYMMDD"));
  if(contact.anniversary) vCard+=encodeLine('ANNIVERSARY',formatDate(contact.anniversary,"YYYYMMDD"));
  contact.emails.map(email=>{
    if(isCustom('emails',email.type)){
      vCard+=customItem("EMAIL", email.address, email.type)
    } else {
      vCard+=encodeLine('EMAIL',email.address,email.type);  
    }
  });
  contact.phones.map(phone=>{
    if(isCustom('phones',phone.type)){
      vCard+=customItem("TEL", phone.number,phone.type);
    } else {
      vCard+=encodeLine('TEL',phone.number,phone.type);
    }
  });
  contact.addresses.map(address=>{
    // 'ADR;TYPE=HOME;LABEL="ATTN: Fred Flintstone":;;123 Apple St.;Brooklyn;NY;12345;US\r\n'

    if(isCustom('addresses',address.type)){
      vCard+=customItem("ADR", ["","",address.street,address.city,address.state,address.postalCode,address.country], address.type)
    } else {
      vCard+=encodeLine('ADR',["","",address.street,address.city,address.state,address.postalCode,address.country],address.type);
    }
    // vCard+=encodeLine('ADR',["","",address.street,address.city,address.state,address.postalCode,address.country],address.type);
  });
  (contact.related||[]).map(relationship=>{
    vCard+=customItem("X-ABRELATEDNAMES", relationship.value,relationship.type);
    // vCard+=encodeLine('RELATED',relationship.value,relationship.type);
  });
  let note = contact.note;
  if(contact.congregation) note+=`\nCongregation: ${contact.congregation}`;
  if(contact.department) note+=`\nDepartment: ${contact.department}`;
  if(contact.hours) note+=`\nHours: ${contact.hours}`;
  vCard+=encodeLine('TITLE',contact.title);
  vCard+=encodeLine('PHOTO',contact.photo);
  vCard+=encodeLine('LANG',contact.lang);
  vCard+=encodeLine('TZ',contact.tz);
  vCard+=encodeLine('LOGO',contact.logo);
  vCard+=encodeLine('TITLE',contact.title);
  vCard+=encodeLine('ORG',contact.org);
  vCard+=encodeLine('URL',contact.web);
  vCard+=encodeLine('SOURCE',contact.source);
  vCard+=encodeLine('KIND',contact.kind);
  vCard+=encodeLine('CATEGORIES', [contact.tags||[]].join(","));
  vCard+=encodeLine('NOTE',note);
  if(contact.kind==="org") vCard+='X-ABShowAs:COMPANY\r\n';
  vCard+=encodeLine('REV',formatDate(contact.updatedAt,"YYYYMMDD"));
  vCard+='END:VCARD\r\n';
  return vCard;
}
export default toVCard;