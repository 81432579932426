import React from 'react'
import styled from 'styled-components';
// import getUrl from '../../lib/getUrl';
// import Button, { ButtonRow } from '../../atoms/Button';
// import _Link from '../../atoms/Link';

const Author = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  margin: 2em 0;
`;
const Avatar = styled.img`
  border-radius: 50%;
`;
const WriterName = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`;
const WriterDescription = styled.div`
  font-style: italic;
`;
const Details = styled.div`
  
`;
export const Card = ({ avatarUrl, name, description }) => {
  return (
    <Author>
      <Avatar src={avatarUrl}/>
      <Details>
        <WriterName>{name}</WriterName>
        <WriterDescription>{description}</WriterDescription>
      </Details>
    </Author>
  )
}


export default Card;