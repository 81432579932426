import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import getUrl from '../../lib/getUrl';
import { useParams, useNavigate } from "react-router-dom";
// import useUser from '../../hooks/useUser';
import { diff } from 'deep-object-diff';
import Button, { ButtonRow } from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import useMutation from '../../hooks/useMutation';
import { getWriterQuery, createWriterMutation, updateWriterMutation, deleteWriterMutation } from './queries'
import { FabGroup, BackFab, SaveFab, CancelFab, DeleteFab } from '../../atoms/Fab';

import Query from '../../atoms/Query';


const defaultValues = { name:"", avatarUrl:"https://i.pravatar.cc/100", description:"" }

const Root = styled.div`
  padding: 4rem;
  max-width: 800px;
  margin: auto;
`;
const Form = styled.form`

`;
// const getInitialValues = writer => {
//   const values = {
//     ...initialValues, 
//     ...writer,
//   };
//   if(values.id==='new') values.id = uuid();
//   return values;
// }
export const EditWriter = ({ getWriter: writer, updateCache }) => {
  // const user = useUser()
  const navigate = useNavigate();
  // const { writer, updatedAt, _deleted, _lastChangedAt, ...writer } = getWriter||{};
  const initialValues = { ...defaultValues, ...writer };
  const [values, setValues] = useState(initialValues);
  const isDirty = !!Object.keys(diff(values, initialValues)).length;
  const createWriter = useMutation(createWriterMutation, {
    successMessage: "Writer created successfully!"
  })
  const updateWriter = useMutation(updateWriterMutation, {
    successMessage: "Writer updated successfully!"
  })
  const trash = useMutation(deleteWriterMutation, {
    successMessage: "Writer deleted successfully!"
  })
  
  useEffect(()=>setValues({...values, ...writer }),[writer])
  const handleChange = event => setValues({ ...values, [event.target.name]: event.target.value });
  const handleSubmit = async event => {
    event.preventDefault();
    if(!values.id) {
      const result = await createWriter({
        ...values,
        id: uuid(),
      })  
      navigate(getUrl('writer','edit',result.data.createWriter.id), { replace: true })
    }
    else {
      const result = await updateWriter(values)  
      updateCache({ getWriter: result.data.updateWriter })
    }
  }
  const reset = event => {
    event.preventDefault()
    setValues({...initialValues, ...writer})
  }
  const handleDelete = async () => {
    await trash({ id: writer.id, _version: writer._version })
    navigate(getUrl('writer','list'))
  }
  return (
    <Root>
      <Form onSubmit={handleSubmit}>
        <TextField label="Name" required name="name" onChange={handleChange} value={values.name} />
        <TextField label="Avatar" required name="avatarUrl" onChange={handleChange} value={values.avatarUrl} />
        <TextField label="Description" required name="description" onChange={handleChange} value={values.description} />
        {/*<ButtonRow><Button type="submit" gold>Save</Button></ButtonRow>*/}
      </Form>
      {/*{JSON.stringify(values)}*/}
      <FabGroup left><BackFab/></FabGroup>
      <FabGroup right>
        {isDirty&&(
          <Fragment>
            <SaveFab onClick={handleSubmit}/>
            <CancelFab onClick={reset}/>
          </Fragment>
        )}
      <DeleteFab onClick={handleDelete}/>
      </FabGroup>
    </Root>
  )
}

const WriterWithData = props => {
  const { id } = useParams();
  return <Query Component={EditWriter} query={getWriterQuery} variables={{ id }} {...props}/>
}


export default WriterWithData;