
const parse = row => {
  var insideQuote = false,                                             
      entries = [],                                                    
      entry = [];
  row.split('').forEach(function (character) {                         
    if(character === '"') {
      insideQuote = !insideQuote;                                      
    } else {
      if(character == "," && !insideQuote) {                           
        entries.push(entry.join(''));                                  
        entry = [];                                                    
      } else {
        entry.push(character);                                         
      }                                                                
    }                                                                  
  });
  entries.push(entry.join(''));                                        
  return entries;                                                      
}

export const csv2json = raw => {
  // const valuesRegExp = /(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^\",]+)/g;
  // const valuesRegExp = /(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^\",]*)/;
  // const valuesRegExp = /(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^\",]+)|(,)/;
  // const valuesRegExp = /(?:\"([^\"]*(?:\"\"[^\"]*)*)\")|([^\"]+,?)/g;
  // const valuesRegExp = /(?:^|,)(?=[^"]|(")?)"?((?(1)(?:[^"]|"")*|[^,"]*))"?(?=,|$)/g;
  
  const lines = raw.split(/(?:\r\n|\n)+/).filter(function(el) {return el.length != 0});
  const headers = lines.splice(0, 1)[0].split(",");
  const elements = [];


  for (let i = 0; i < lines.length; i++) {
    const element = parse(lines[i]).reduce((acc, val, idx)=>{
      acc[headers[idx]] = val;
      return acc;
    },{})
      // const element = {};
      // let j = 0;
      // let matches;
      // let line = lines[i];
      // for (var j = 0; j < headers.length; j++) {
      //   // headers[j]
      //   const match = line.match(valuesRegExp);
      //   console.log(`match`, match);
      //   let value = match[0].replace(/\"\"/g, "\"");
      //   element[headers[j]] = value;
      //   line=line.slice(match.index+value.length+1);
      // }
      // while (matches = valuesRegExp.exec(lines[i])) {
      //     var value = matches[1] || matches[2];
      //     value = value.replace(/\"\"/g, "\"");

      //     element[headers[j]] = value;
      //     j++;
      // }

      elements.push(element);
  }
  return elements;
}




export default csv2json;