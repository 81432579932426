import styled, { css } from 'styled-components/macro';
import { ActionRow } from './FolderActions';
export const Filename = styled.div`
  cursor: pointer;
`;
export const Filesize = styled.div`
`;
export const Creator = styled.div`
`;
export const Updated = styled.div`
`;
export const tableGridStyle = css`
  color: black;
  display: grid;
  min-height: 40px;
  grid-template-columns: 1fr 8fr 2fr 2fr 2fr 2fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  align-items: center;
`;
export const FileRow = styled.div`
  display: block;
  ${tableGridStyle}
  ${p=>p.$selected&&"background-color:#e6e6e6;"}
  &:hover {
    background-color: #f2e6d3;
    // color: white;
  }
  padding: 0 .5em;
  &:hover ${ActionRow} {
    visibility: visible;
  }
`;

