import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro';
import Dialog from '../../../molecules/Dialog';

const Rewrapper = styled.div`
  // margin: auto;
  display: flex;
  justify-content: center;
`;
const GridWrapper = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-template-columns: 150px 150px 150px;
  grid-template-columns: 18vw 18vw 18vw;
  grid-auto-rows: 13vw;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 30vw 30vw;
    grid-auto-rows: 22vw;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 60vw;
    grid-auto-rows: 45vw;
  }
  // @media screen and (max-width: 750px) {
  //   grid-template-columns: 90vw;
  // }
  // grid-template-columns: minmax(150px, 200px) minmax(150px, 200px) minmax(150px, 200px)  minmax(150px, 200px);
  

`;
const defaultFontSize = css`
  font-size: ${p => {
    if (p.length>150) return ".55rem;"
    if (p.length>100) return ".65rem;"
    return ".75rem;"
  }}
`;
const Text = styled.p`
   height: 0px;
   transition: height 1s;
   overflow: hidden;
   text-align: center;
   ${defaultFontSize}
   @media screen and (max-width: 1200px) {
    font-size: ${p => {
      if (p.length>150) return ".8rem;"
      if (p.length>100) return ".9rem;"
      return "1rem;"
     }}
   }
   @media screen and (max-width: 900px) {
    font-size: ${p => {
      if (p.length>150) return "1.3rem;"
      if (p.length>100) return "1.4rem;"
      return "1.5rem;"
     }}
   }
   ${p => p.theme.breakpoints.phone} {
    ${defaultFontSize}
   }
   margin-top: .5em;
   line-height: 1.2;
`;
const Label = styled.h5`
  text-align: center;
  font-size: 1.25rem;
  // @media screen and (max-width: 1100px) {
  //   font-size: 1rem;
  // }
`;

const IconWrapper = styled.div`
  transition: all 1s;
  padding-bottom: .5em;
  display: flex;
  justify-content: center;
  font-size: 4rem;
  // @media screen and (max-width: 1400px) {
  //   font-size: 2.5rem;
  // }
`;
const LabelWrapper = styled.div`
  
  // @media screen and (max-width: 900px) {
  //   display: flex;
  // }
`;

const getTransform = (breakpoint, offset={}) => {
  let value = "";
  const scale = breakpoint === 2000 ? "1.7" : "1.3";
  Object.keys(offset).forEach(key => {
    if (key==breakpoint) {
      value = offset[key];
    };
  });
  if (value) return `transform: scale(${scale}) translate(${value}, 0);`
  return `transform: scale(${scale});`
}
const hoverStyle = css`
  &:hover {
    z-index: 2;
    ${p=>getTransform(2000, p.offset, p.label)}
    @media screen and (max-width: 1200px) {
      display: flex;
      ${p=>getTransform(1200, p.offset, p.label)}
    }
    @media screen and (max-width: 900px) {
      display: flex;
      ${p=>getTransform(900, p.offset, p.label)}
    }
  }
  &:hover ${Text}{
    height: 4em;
    overflow: unset;
  }
  &:hover ${IconWrapper}{
    font-size: 3rem;
    padding: .15em;
    transform: scale(.75);
  }
`;

const ClickMoreText = styled.div`
  margin-top: 1em;
  text-align: center;
  font-weight: normal;
  font-size: 1rem;
  color: ${p=>p.theme.colors.gold};
  opacity: 0;
  transition: opacity 1s;
`;
const ItemWrapper = styled.div`
  padding: 0 1rem;
  border: 1px solid #d5aa6d;
  background-color: white;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  transition: transform 1s;
  overflow: hidden;


  // transition: all 1s;
  // display: block;
  justify-content: center;
  align-items: center;



  &:hover {
    cursor: pointer;
    transform: scale(1.2);
    ${Label} {
      // color: #e8282a;
    }
    ${ClickMoreText} {
      opacity: 1;
    }
  }
`;
const Spacer = styled.div`

`;
export const Item = ({ label, title, Icon, text="", offset, openDialog, idx }) => {
  if(!title&&!label) return <Spacer />
  return (
    <ItemWrapper offset={offset} onClick={()=>openDialog(idx)}>
      <Icon color="#d5aa6d" style={{ fontSize: 'inherit' }}/>
      {/* <IconWrapper><Icon color="#d5aa6d" style={{ fontSize: 'inherit' }}/></IconWrapper> */}
      <Label>{label||title}</Label>
      <ClickMoreText>Click here to see more</ClickMoreText>
    </ItemWrapper>
  );
}
export const Grid = ({ items=[], openConsultationDialog }) => {
  const [selected, setSelected] = useState(0);
  const [dialogIsOpen, setDialogIsOpen] = useState(false); 
  const closeDialog = () => setDialogIsOpen(false);
  const openDialog = idx => {
    setDialogIsOpen(true);
    setSelected(idx);
  };
  const handleOpenConsultationDialog = () => {
    closeDialog()
    openConsultationDialog()
  }


  // const item = {
  //   ...items[selected],
  //   body: ()=><div dangerouslySetInnerHTML={{ __html:items[selected].text}} />
  // }

  return (
    <React.Fragment>
      <GridWrapper>
        {items.map((item, idx)=><Item key={item.label} openDialog={openDialog} idx={idx} {...item} />)}
      </GridWrapper>
      <Dialog {...items[selected]} isOpen={dialogIsOpen} onClose={closeDialog} openConsultationDialog={handleOpenConsultationDialog}/>
    </React.Fragment>
  );
}
export default Grid;