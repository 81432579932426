const writerFields = `
  id
  name
  avatarUrl
  description
`;
export const getWriterQuery = `
  query GetWriter($id: ID!) {
    getWriter(id: $id) {
      ${writerFields}
    }
  }
`
export const createWriterMutation = `
  mutation CreateWriter(
    $input: CreateWriterInput!
    $condition: ModelWriterConditionInput
  ) {
    createWriter(input: $input, condition: $condition) {
      ${writerFields}
    }
  }
`
export const updateWriterMutation = `
  mutation UpdateWriter(
    $input: UpdateWriterInput!
    $condition: ModelWriterConditionInput
  ) {
    updateWriter(input: $input, condition: $condition) {
      ${writerFields}
    }
  }
`;
export const deleteWriterMutation = `
  mutation DeleteWriter(
    $input: DeleteWriterInput!
    $condition: ModelWriterConditionInput
  ) {
    deleteWriter(input: $input, condition: $condition) {
      ${writerFields}
    }
  }
`