import React, { useState, useContext, useEffect } from 'react'
import { Amplify, Auth } from 'aws-amplify';
import useNotifications from './useNotifications';
import useQueryParams from './useQueryParams'
const publicUser = { username: "1f76cbc5-2175-4d48-b8b1-8d38c927739d", password:"password" };
const UserContext = React.createContext();

const getCurrentUser = async () => Auth.currentAuthenticatedUser({ bypassCache: true });
const loginWithParams = async (params) => Auth.signIn(params.username, params.password);
const loginAsAnonymousUser = async () => Auth.signIn(publicUser.username, publicUser.password);

const strategies = [
  loginWithParams,
  getCurrentUser,
  loginAsAnonymousUser,
]

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { params } = useQueryParams();
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState();
  // const showMessage = useNotifications();
  const logout = async () => {
    try {
      await Auth.signOut();
      // showMessage(`Goodbye. Hope to see you again soon!`, 'success');
      setUser(null);
      console.log("switching to API_KEY")
      Amplify.configure({ aws_appsync_authenticationType: "API_KEY" });
    } catch (error) {
      console.log('error signing out: ', error);
      // showMessage(`Error: ${JSON.stringify(error)}`, 'error');
    }
  }
  const getGroups = async (user) => {
    if(!groups) {
      try {
        const groups = await Auth.listGroups(user);
        console.log(groups);
        setGroups(groups);
      } catch (error) {
        console.log("groups error")
        console.error(error);
      }
    }
    console.log(`groups`, groups);
    return groups;
  }
  const runStrats = async () => {
    // console.log(`strategies.length`, strategies.length);
    for (var i = 0; i < strategies.length; i++) {

      // console.log(`trying ${['loginWithParams','getCurrentUser','loginAsAnonymousUser'][i]}`);
      try{
        const user = await strategies[i](params)
        user.anonymous = user.username==='1f76cbc5-2175-4d48-b8b1-8d38c927739d';
        // console.log(`user ${i}: `, user);
        // This line is important so people that are not logged in see login instead of logout
        if(user) return !user.anonymous&&user;
        // But this line can be helpful instead for testing.
        // if(user) return user;
      } catch (e) {
        // console.log(`${i} failed`);
        //ignore errors for now
      }
    }
  }
  const getUser = async () => {
    // console.log("Fetching user")
    const user = await runStrats();
    if(user) {
      user.id = user.username;
      user.email = user.attributes&&user.attributes.email;
      user.groups = user.signInUserSession&&user.signInUserSession.accessToken.payload["cognito:groups"];
    }
    setUser(user);
    setLoading(false);
    // console.log(`user`, user);
    return user;
  }
  useEffect(() => {
    getUser();
    // console.log(`useUser useEffect `, user);
  },[])
  return (
    <UserContext.Provider value={[user, { refetch: getUser, logout, loading }]}>
      {children}
    </UserContext.Provider>
  )
}
export const useUser = () => {
  const context = useContext(UserContext);
  return context;
}
export default useUser;