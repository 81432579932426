import styled, { css } from 'styled-components';
import { useEffect, useRef } from 'react';
import React from 'react';

const Wrapper = styled.div`
    flex: 1 0 200px;
`;
const labelStyle = css`
  margin-top: 1em;
  font-family: Arimo, sans-serif;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  line-height: normal;
`;
export const Label = styled.label`
  ${labelStyle}
`;
const inputStyle = css`
  font-family: Arimo, sans-serif;
  display: block;
  line-height: 1.42857143;
  color: #34495e;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 ${p => p.hasError ? p.theme.colors.red : 'rgb(204, 204, 204)'};
  transition: border-color .5s ease-in-out;
  background-color: transparent;
  background-image: none;
  background-size: auto;
  background-repeat: repeat;
  font-size: 16px;
  &:focus {
    outline: none;
    border-bottom: 2px solid #34495e;
  }
`;
export const TextInput = styled.input`
  ${inputStyle}
  // height: 3.5em;
`;
export const TextArea  = styled.textarea`
  flex: 1 0 200px;
  margin-top: 1em;
  min-height: 1.5em;
  ${inputStyle}
  
`;
export const Error = styled.p`
  color: ${p=>p.theme.colors.red};
  font-size: .8rem;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;
`;
export const TextField = React.forwardRef(({ label, id, error, touched, required, className, textarea, onChange, value, ...props }, ref) => {
  const hasError = error && touched;
  const Input = textarea ? TextArea : TextInput;
  return (
    <Wrapper className={className}>
      {label&&<Label htmlFor={id} >{label}{required && " *"}</Label>}
        <Input id={id} {...props} ref={ref} value={value||""} onChange={onChange} hasError={!!hasError}/>
      {hasError&&<Error>{error}</Error>}
    </Wrapper>
  )
})
export default TextField
