import React, { Fragment, useState, useEffect } from 'react'
import ConsultationDialog from './Sections/ConsultationDialog';
// import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import Signature from './Sections/Signature';
// import Language from './Sections/Language';
import Clients from './Sections/Clients';
// import Blog from './Sections/Blog';
import ContactUs from './Sections/ContactUs';
import Newsletter from './Sections/Newsletter';
import LandingBanner from './Sections/LandingBanner';
// import Mission from './Sections/Mission';
import Services from './Sections/Services';
import ServicesGroups from './Sections/ServicesGroups';
import { orgSchemaMarkup } from '../../lib/schemaMarkup';
import { landingMetaTag } from '../../lib/metaTags';

export const Landing = () => {
  const location = useLocation();
  const [dialogIsOpen, setDialogIsOpen] = useState(false); 
  const closeDialog = () => setDialogIsOpen(false);
  const openConsultationDialog = () => setDialogIsOpen(true);
  useEffect(()=>{
    const el = document.getElementById(location.hash.slice(1));
    if(el) el.scrollIntoView({ behavior: "smooth", block: "start" });
    landingMetaTag();
  }, [location.hash])

  return (
    <Fragment>
      <script type="application/ld+json">
        {JSON.stringify(orgSchemaMarkup)}
      </script>
      <LandingBanner openConsultationDialog={openConsultationDialog}/>
      {/* <Mission /> */}
      <Services />
      <ServicesGroups openConsultationDialog={openConsultationDialog}/>
      <Signature />
      {/* <Language /> */}
      <Clients openConsultationDialog={openConsultationDialog}/>
      {/*<Blog />*/}
      <ContactUs />
      <Newsletter />
      <ConsultationDialog isOpen={dialogIsOpen} onClose={closeDialog}/>
    </Fragment>
  )
}


export default Landing;