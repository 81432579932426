import React from 'react'
import styled from 'styled-components';
import getUrl from '../../lib/getUrl';
// import Button, { ButtonRow } from '../../atoms/Button';
import _Link from '../../atoms/Link';
import getDisplayName from '../../lib/getDisplayName';


const Contact = styled(_Link)`
  // display: flex;
  // flex-direction: column;
  // gap: 1em;
  // align-items: center;
  // margin: 2em 0;
  box-shadow: 4px 4px 9px 0 rgb(0 0 0 / 45%);
  padding: 2em;
  flex: 1 0 300px;
  color: black;
  &:link {
    color: black;
  }
  &:visited {
    color: black;
  }
`;
const Name = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`;
const Data = styled.div`
`;
export const Card = ({ contact }) => {
  return (
    <Contact to={getUrl('contacts','edit', contact.id)}>
      <Name>{getDisplayName(contact)}</Name>
      {contact.emails.length&&<Data>{contact.emails[0].address}</Data>}
      {contact.phones.length&&<Data>{contact.phones[0].number}</Data>}
    </Contact>
  )
}


export default Card;