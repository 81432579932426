import React from 'react'
import styled from 'styled-components/macro';
import { Header2 } from '../../../atoms/Text';
// import { Row as _Row } from '../../../atoms/Row';
import Link from '../../../atoms/Link';
import Button, { ButtonRow } from '../../../atoms/Button';

const Root = styled.div`
  padding: 1rem 0px;
  text-align: center;
  
  @media only screen and (max-width: 55em) {
    padding: 15px;
  }
`;
const Intro = styled.div`
  background-color: #D8D8D8;
`;
const WoodbourneLogo = {
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
    padding-bottom: 1rem;
    @media only screen and (max-width: 55em) {
      display: block;
    }
  `,
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto 2rem;
`;
const Image = styled.img`
  width:400px;
  height:400px;
  transition: transform 1s;
  &:hover {
    transform: scale(1.1);
  }
  @media only screen and (max-width: 55em) {
    width:300px;
    height:300px;
  }
`;
const Header = styled(Header2)`
  text-align: center;
  font-size: 3rem;
  // padding: 0 0 2rem;
  margin-top: 2rem;
  font-weight: 400;
  font-family: Futura Now Text, Futura, sans-serif;
  ${p => p.theme.breakpoints.tablet} {
    padding: 0;
    margin: 1rem 2rem;
    font-size: 2.5rem;
  }
`;
const Description = styled.p`
  font-size: 1.3rem;
  // font-family: Futura Now Text, Futura, sans-serif;
  font-family: Lora;
  ${p => p.theme.breakpoints.tablet} {
    font-size: 1.4rem;
  }
`;
const Instructions = styled.p`
  font-size: 1.3rem;
  font-family: Lora;
  font-style: italic;
  padding: 1rem 0 2rem;
`;
const ServiceContainer = styled.div`
  max-width: 1200px;
`;
const Service = ({ img, label, srcSet, onClick }) => {

  return (
    <ServiceContainer>
      <Image 
        alt="people doing different tasks" 
        loading="lazy" 
        onClick={onClick} 
        src={img}
        sizes="(max-width: 478px) 30vw, (max-width: 767px) 27vw, (max-width: 991px) 25vw, 18vw"
        srcSet={srcSet}
      />
      <ButtonRow>
        <Button gold onClick={onClick}>{label}</Button>
      </ButtonRow>
      
    </ServiceContainer>
  )
}

export const Services = group => {
  const handleClickIndividual = () => {
    document.getElementById("individual").scrollIntoView({ behavior: "smooth", block: "start" });
  }
  const handleClickBusiness = () => {
    document.getElementById("business").scrollIntoView({ behavior: "smooth", block: "start" });
  }
  const handleClickNonProfit = () => {
    document.getElementById("non-profit").scrollIntoView({ behavior: "smooth", block: "start" });
  }
  return (
    <Root id="services">
      <Container>
        <Header>Tax Solutions Customized to Your Needs</Header>
        <Description>Woodbourne Accounting’s services are tailored to our clients. It’s important for us to get to know you personally and then offer solutions to meet your needs. Having a working relationship lets us (you and Woodbourne Accounting) develop sound accounting, cash management, and <Link darkgold href="https://www.nerdwallet.com/article/taxes/tax-planning">tax planning strategies</Link>, accurately evaluate the results, and successfully plan for the future.  Our comprehensive accounting services involve more than just preparing financial reports and tax returns.</Description>
      </Container>
      <Intro>
        <Instructions><br/>Click on the categories below to learn more.</Instructions>
      
        <WoodbourneLogo.Wrapper>
          <Service
            img="/images/working-lady.webp"
            label="Individual"
            onClick={handleClickIndividual}
            srcSet="/images/working-lady-mobile.webp 477w, /images/working-lady-mobile.webp 766w, /images/working-lady.webp 990w" 
          />
          <Service
            img="/images/working-desk.webp"
            label="Business"
            onClick={handleClickBusiness}
            srcSet="/images/working-desk-mobile.webp 477w, /images/working-desk-mobile.webp 766w, /images/working-desk.webp 990w" 
          />
          {/* <Service
            img="/images/together.webp"
            label="Non-Profit"
            onClick={handleClickNonProfit}
          /> */}
        </WoodbourneLogo.Wrapper>
      </Intro>
    </Root>
  )
}

export default Services;