// import React from 'react'
import { Amplify, Auth, API } from 'aws-amplify';

export const listUsersInGroup = async (groupname, limit=30) => {
  const { NextToken, ...rest } =  await API.get('AdminQueries', '/listUsersInGroup', { 
      queryStringParameters: {
        groupname,
        "limit": limit,
        // "token": nextToken
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  });
  // nextToken = NextToken;
  return rest;
}
export const confirmUserSignUp = async (username) => {
  const result =  await API.post('AdminQueries', '/confirmUserSignUp', { 
      body: { username },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  });
  console.log(`confirmUser result`, result);
  return result;
}
export const addToGroup = async (user, groupname) => { 
  const { username } = user;
  return await API.post('AdminQueries', '/addUserToGroup', {
      body: {
        username,
        groupname,
      }, 
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      } 
  });
}
export const cognito = {
  listUsersInGroup,
  confirmUserSignUp,
  addToGroup,
}
export default cognito;