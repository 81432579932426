import { css } from 'styled-components/macro';

export const theme = {
  breakpoints:{
    tablet: "@media screen and (max-width: 991px)",
    phone: "@media screen and (max-width: 478px)"
  },
  colors: {
    white: 'white',
    black: 'black',
    darkgrey: '#dddddd',
    gold: '#d5aa6d',
  },
  scrollbar: css`
    &::-webkit-scrollbar {
      width: 0.65em;
      height: 0.65em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0, 16%);
      border-radius: 10px;
      &:hover {
        background-color: rgb(0 0 0 / 36%);
        cursor: pointer;
      }
    }
  `
}

export default theme;