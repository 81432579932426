import React from 'react'
import styled from 'styled-components';
import {Header4} from '../atoms/Text';

const NoResultsStyle = styled(Header4)`
  text-align: center;
  width: 100%;
  margin: 2em;
`;
export const NoResults = ({ type }) => <NoResultsStyle>There are no {type} to show.</NoResultsStyle>

export default NoResults