/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDraft = /* GraphQL */ `
  query GetDraft($id: ID!) {
    getDraft(id: $id) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listDrafts = /* GraphQL */ `
  query ListDrafts(
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        cardImgUrl
        bannerImgUrl
        category
        tags
        title
        snippet
        body
        writerID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactUs = /* GraphQL */ `
  query GetContactUs($id: ID!) {
    getContactUs(id: $id) {
      id
      name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactuses = /* GraphQL */ `
  query ListContactuses(
    $filter: ModelContactUsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsletterSignup = /* GraphQL */ `
  query GetNewsletterSignup($id: ID!) {
    getNewsletterSignup(id: $id) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNewsletterSignups = /* GraphQL */ `
  query ListNewsletterSignups(
    $filter: ModelNewsletterSignupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterSignups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      name
      key
      type
      children {
        nextToken
        __typename
      }
      parentID
      filePath
      creator
      size
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        type
        parentID
        filePath
        creator
        size
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      kind
      displayName
      org
      firstName
      lastName
      middleName
      prefix
      suffix
      nickname
      phones {
        tags
        type
        number
        pref
        person
        __typename
      }
      addresses {
        type
        pref
        street
        city
        state
        stateCode
        postalCode
        country
        countryCode
        __typename
      }
      emails {
        type
        address
        pref
        person
        __typename
      }
      related {
        type
        value
        __typename
      }
      photo
      birthday
      anniversary
      gender
      department
      lang
      tz
      geo
      title
      role
      logo
      tags
      note
      web
      hours
      source
      congregation
      invoices {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      contactId
      contact {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      clientName
      type
      items {
        id
        name
        quantity
        price
        total
        description
        __typename
      }
      total
      number
      paid
      date
      due
      month
      sentOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contactId
        clientName
        type
        total
        number
        paid
        date
        due
        month
        sentOn
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVendorKey = /* GraphQL */ `
  query GetVendorKey($id: ID!) {
    getVendorKey(id: $id) {
      id
      key
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVendorKeys = /* GraphQL */ `
  query ListVendorKeys(
    $filter: ModelVendorKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        label
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      number
      clientId
      client {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      type
      entries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        number
        clientId
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEntry = /* GraphQL */ `
  query GetEntry($id: ID!) {
    getEntry(id: $id) {
      id
      date
      description
      amount
      number
      accountId
      account {
        id
        name
        number
        clientId
        type
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEntries = /* GraphQL */ `
  query ListEntries(
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        description
        amount
        number
        accountId
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSnippet = /* GraphQL */ `
  query GetSnippet($id: ID!) {
    getSnippet(id: $id) {
      contactId
      field
      bank
      key
      vendor
      category
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSnippets = /* GraphQL */ `
  query ListSnippets(
    $filter: ModelSnippetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSnippets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        contactId
        field
        bank
        key
        vendor
        category
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const draftsByDate = /* GraphQL */ `
  query DraftsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    draftsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        cardImgUrl
        bannerImgUrl
        category
        tags
        title
        snippet
        body
        writerID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoicesByNumber = /* GraphQL */ `
  query InvoicesByNumber(
    $type: String!
    $number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByNumber(
      type: $type
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactId
        clientName
        type
        total
        number
        paid
        date
        due
        month
        sentOn
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      bump
      updatedAt
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        cardImgUrl
        bannerImgUrl
        category
        tags
        title
        snippet
        body
        writerID
        bump
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByDate = /* GraphQL */ `
  query PostsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        cardImgUrl
        bannerImgUrl
        category
        tags
        title
        snippet
        body
        writerID
        bump
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWriter = /* GraphQL */ `
  query GetWriter($id: ID!) {
    getWriter(id: $id) {
      id
      name
      avatarUrl
      description
      posts {
        nextToken
        __typename
      }
      drafts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWriters = /* GraphQL */ `
  query ListWriters(
    $filter: ModelWriterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWriters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
