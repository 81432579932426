import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { NotificationsProvider } from './hooks/useNotifications';
import { UserProvider } from './hooks/useUser';
import theme from "./layout/theme";
import Header from './layout/Header';
import Footer from './layout/Footer';
import Calls from './pages/Calls/Calls';
import Body from './layout/Body';
import FileBrowser from './pages/Storage/FileBrowser';
import Landing from './pages/Landing/Landing';
import BlogList from './pages/Blog/List';
import BlogDetail from './pages/Blog/Detail';
import BlogEdit from './pages/Blog/Edit';

import WritersEdit from './pages/Writers/Edit';
import WritersList from './pages/Writers/List';

import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

import ContactsEdit from './pages/Contacts/Edit';
import ContactsList from './pages/Contacts/List';

import InvoicesNew from './pages/Invoices/New';
import InvoicesEdit from './pages/Invoices/Edit';
import InvoicesList from './pages/Invoices/List';

import Transactions from './pages/Transactions/Convert';

// import Register from './pages/Users/Register';
import Login from './pages/Users/Login';
import AddUser from './pages/Users/Add';
import SetPassword from './pages/Users/SetPassword';
import React from 'react';

export const Router = () => {
  return (
    <BrowserRouter>
      <UserProvider>
          <Header />
          <Body>
            <Routes>
              <Route path="/" element={<Landing/>}/>
              <Route path="/storage/:id" element={<FileBrowser/>} />
              <Route path="/blog/:id/edit" element={<BlogEdit/>} />
              <Route path="/blog/:id" element={<BlogDetail/>} />
              <Route path="/blog" element={<BlogList/>} />

              <Route path="/writers/:id/edit" element={<WritersEdit/>} />
              <Route path="/writers" element={<WritersList/>} />

              <Route path="/contacts/:id/edit" element={<ContactsEdit/>} />
              <Route path="/contacts" element={<ContactsList/>} />

              <Route path="/invoices/new/edit" element={<InvoicesNew/>} />
              <Route path="/invoices/:id/edit" element={<InvoicesEdit/>} />
              <Route path="/invoices" element={<InvoicesList/>} />

              <Route path="/convert" element={<Transactions/>} />

              <Route path="/privacy" element={<Privacy/>} />
              <Route path="/terms" element={<Terms/>} />
              {/*<Route path="/register" element={<Register/>} />*/}
              <Route path="/login" element={<Login/>} />
              <Route path="/users/add" element={<AddUser/>} />
              <Route path="/set-password" element={<SetPassword/>} />

              <Route path="/calls" element={<Calls/>} />

              
            </Routes>
          </Body>
          <Footer/>
      </UserProvider>
    </BrowserRouter>
  )
}

const Root = () => (
  <NotificationsProvider>
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  </NotificationsProvider>
)

export default Root;