import styled from 'styled-components'
import Button from './Button';
import React from 'react';

// export const IconButtonStyle = styled(Button)`
//   flex: 0 0 auto;
//   min-width: 0;
//   color: rgba(0, 0, 0, 0.54);
//   padding: 12px;
//   overflow: visible;
//   font-size: 1.5rem;
//   text-align: center;
//   transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   border-radius: 50%;
//   background-color: transparent;
//   box-shadow: none;
//   &:hover {
//     box-shadow: none;
//     background-color: rgba(0, 0, 0, 0.04);
//   }
// `;

// export const IconButtonStyle = styled.button`
//   // 
//   width: 56px;
//   height: 56px;
//   min-height: 36px;
//   font-weight: 500;
//   line-height: 1.75;
//   border-radius: 50%;
//   text-transform: uppercase;
//   font-size: 0.875rem;
//   border: 0;
//   cursor: pointer;
//   margin: 0px 1px;
//   outline: 0;
//   display: inline-flex;
//   align-items: center;
//   vertical-align: middle;
//   justify-content: center;
//   color: rgba(0, 0, 0, 0.54);
//   background-color: transparent;
//   transition: all .4s ease-in-out;
//   &:hover {
//     color: ${p => p.primary ? p.theme.colors.white : p.theme.colors.black};
//     background-color: ${p => p.primary ? p.theme.colors.gold : p.theme.colors.darkgrey};
//     box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
//     // transform: scale(1.10);
//   }
// `;

export const IconButtonStyle = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer !important;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  // transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    color: ${p => p.primary ? p.theme.colors.white : p.theme.colors.black};
    background-color: ${p => p.primary ? p.theme.colors.gold : p.theme.colors.darkgrey};
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    // transform: scale(1.10);
  }
`;
export const IconButton = ({ Icon, iconProps, type="button", ...props }) => (
  <IconButtonStyle type={type} {...props}><Icon {...iconProps}/></IconButtonStyle>
)
export default IconButton;