import React from 'react'
import styled from 'styled-components/macro';
import { Header1, Header3 } from '../../atoms/Text';
import { StorageProvider } from './useStorage';
import { useParams } from "react-router-dom";
import useStorage from './useStorage';
import { Loading } from '../../atoms/Query';
import ProgressBar from '../../atoms/ProgressBar';
import AuthWall from '../../atoms/AuthWall';
import Folder from './Folder';
import File from './File';
import FolderActions from './FolderActions';
// import { upload } from '@testing-library/user-event/dist/upload';

const Wrapper = styled.div`
  padding: 1em;
  margin: 2em auto;
  max-width: 800px;
  border: solid 1px #ddd;
`;
const Header = styled(Header1)`
  font-size: 2.5rem;
  margin: 0.5em 0;
  text-align: left;
`;
export const FileBrowser = () => {
  // const isAdmin = user&&user.groups.includes('Admin')
  const { id } = useParams();
  const { file, loaded, refetch, uploadProgress, onUpload } = useStorage(id);
  const handleUpload = (files) => onUpload(files, id, file, refetch)
  if(!loaded) return <Loading />
  return (
    <Wrapper>
      {uploadProgress.total&&<ProgressBar progress={uploadProgress.loaded/uploadProgress.total*100}/>}
      <Header>{file.name}</Header>
      <FolderActions item={file} onUpload={handleUpload} refetch={refetch} actions={{ new:1 }} alwaysShow />
      {file.children.length?(
        file.children.map((item, index)=>{
          if(item.type==="Folder") return <Folder key={item.id||index} item={item} refetch={refetch}/>
          return <File key={item.id||index} item={item} refetch={refetch}/>
        })
      ):(
        <Header3>There are no files here.  Drag and drop some files here to get started!</Header3>
      )}
    </Wrapper>
  );
}
export const AuthFileBrowser = props => {

  return (
    <StorageProvider>
      <AuthWall {...props} group="Clients" showError Component={FileBrowser}/>
    </StorageProvider>
  )
}
export default AuthFileBrowser;