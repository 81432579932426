import React from 'react'
import styled from 'styled-components';
import Button, { ButtonRow } from '../../../atoms/Button';

const Root = styled.div`
  background-image: url(images/Landing-Banner.webp);
  height: 600px;
  max-width:1600px;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;

  @media only screen and (max-width: 55em) {
    height: 500px;
    max-width:1200px;
    width:
    background-image: url(images/Landing-Banner-Mobile.webp);
  }
`;
const WoodbourneLogo = {
  Wrapper: styled.div`
    margin: auto;
    width: 70%;
    height: 100%;
    // padding-top: 11%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align:center;
    @media only screen and (max-width: 55em) {
      width: 100%;
      padding-top: 15%;
    }
  `,
  Logo: styled.img`
    width: 550px;
    height: 91px;
    margin: 3rem auto;
    @media only screen and (max-width: 55em) {
       width: 370px;
       height: 62px;
    }
  `
};
const CompanyDescription = styled.p`
  font-size: 2rem;
  font-family: Lora;
  font-style: italic;
  
  @media only screen and (max-width: 55em) {
    font-size: 1.3rem;
    padding:0px 40px;
  }
`;

export const LandingBanner = ({ openConsultationDialog }) => {
  return (
    <Root loading="lazy" id="home">
      <WoodbourneLogo.Wrapper>
        <WoodbourneLogo.Logo 
          alt="woodbourne logo" 
          loading="lazy" 
          src="/images/WoodbourneLogoDark.webp" 
          sizes="(max-width: 478px) 30vw, (max-width: 767px) 27vw, (max-width: 991px) 25vw, 18vw" 
          srcSet="/images/WoodbourneLogoDark-Mobile.webp 477w, /images/WoodbourneLogoDark-Mobile.webp 766w, /images/WoodbourneLogoDark.webp 990w" 
        />
        <CompanyDescription>
          For 31 years, Woodbourne Accounting has provided Central Virginia and businesses with personalized, secure, and reliable accounting services. We support and understand businesses and provide comprehensive financial management.
        </CompanyDescription>
        <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
      </WoodbourneLogo.Wrapper>
    </Root>
  )
}

export default LandingBanner;
