import React, { useState, useEffect, useContext } from 'react'
import useUser from '../../hooks/useUser';
import { API, Storage } from 'aws-amplify'
import { getFile } from '../../graphql/queries';
import { createFile, createContactUs } from '../../graphql/mutations'
import { v4 as uuid } from 'uuid';

export const StorageContext = React.createContext();

export const StorageProvider = ({ children }) => {
  const [storage, setStorage] = useState({});
  const [user] = useUser();
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadToFolderId, setUploadToFolderId] = useState("");
  const getExt = str => {
    if(str.slice(-4,-3)===".") return str.slice(-3)
    if(str.slice(-5,-4)===".") return str.slice(-4)
  }
  const addFileToDb = async (name, key, parentID, creator) => {
    const file = { 
      id: uuid(),
      type: "File",
      name, 
      key,
      creator,
      parentID: parentID||uploadToFolderId,
    }
    await API.graphql({
      query: createFile,
      variables: { input: file },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    const result = await API.graphql({
      query: createContactUs,
      variables: {input:{ name:"Client Portal", email: user.email, message: `${user.email} has uploaded a file to the client portal.` }},
      // authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    console.log('result',result);
    
    return file;
  }
  const onUpload = async (files, parentID, creator, refetch) => {
    await Promise.all(files.map(async file => {
      // console.log("onUpload file: ",file)
      const name = file.name;
      const ext = getExt(name);
      const key = `${uuid()}.${ext}`;
      try {
        await Storage.put(key, file, {
          progressCallback: setUploadProgress
        });
        // console.log("result: ",result)
        const final = await addFileToDb(name, key, parentID||uploadToFolderId, creator)
        await refetch();
        return final;
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }))
    // setFiles([...files, ...newFiles])
  }
  
  return (
    <StorageContext.Provider value={{ storage, setStorage, uploadProgress, onUpload, setUploadToFolderId }}>
      {children}
    </StorageContext.Provider>
  )
}

export const useStorage = id => {
  const { storage, setStorage, uploadProgress, onUpload, setUploadToFolderId } = useContext(StorageContext);
  const addFolder = async (name, parentID) => {
    const file = { 
      id: uuid(),
      name, 
      type: "Folder", 
      key:"",
      parentID,
    }
    // console.log("file: ",file);
    await API.graphql({
      query: createFile,
      variables: { input: file },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    fetch(parentID);
  }
  const fetch = async id => {
    // console.log(`fetching ${id}`)
    const { data } = await API.graphql({
      query: getFile,
      variables: { id },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    // console.log(`data`, data);
    const file = { ...data.getFile, children: data.getFile.children.items };
    setStorage(storage=>({ ...storage, [id]: { file, loaded: true } }))
  }
  const refetch = () => fetch(id)
  useEffect(() => { refetch() },[id])
  // const setFile = value => setStorage({ ...storage, [id]: { ...(storage[id]||{}), file: value } })
  const { file, loaded } = storage[id]||{};
  // console.log(`useStorage id`, id);
  return { file, loaded, refetch, fetch, addFolder, uploadProgress, onUpload, setUploadToFolderId };
}

export default useStorage;