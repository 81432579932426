import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro';

import { API } from 'aws-amplify'

import { Header2 } from './Text';

const LoadingHeader = styled(Header2)`
  margin-top: 1em;
`;
export const Loading = () => <LoadingHeader>Loading...</LoadingHeader>
export const Query = ({ query, variables, Component, showLoading, formatData, ...props }) => {
  const [results, setResults] = useState();
  console.log('results',results);
  const [loading, setLoading] = useState((variables && variables.id)!=='new');
  const updateCache = newValue => setResults({ ...results, ...newValue })
  useEffect(() => { 
    if(!variables || variables.id!=='new') fetch();
  },[variables])
  const fetch = async () => {
    const fileData = await API.graphql({
      query,
      variables,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    setResults(fileData.data)
    setLoading(false);
  }
  if(loading&&showLoading) return <Loading />
  const data = formatData ? formatData(results) : results;
  return <Component {...props} {...data} refetch={fetch} updateCache={updateCache} formatData={formatData}/>
}

export default Query;