// @flow
import React from 'react';
import styled, { css } from 'styled-components/macro';
import Tooltip from './Tooltip';
import BackIcon from '../icons/Back';
import CheckIcon from '../icons/Check';
import CloseIcon from '../icons/Close';
import DeleteIcon from '../icons/Delete';
import EditIcon from '../icons/Edit';
import AddIcon from '../icons/Add';
import { useNavigate, Link } from "react-router-dom";

const FabTooltip = styled(Tooltip)`
  width: 56px;
  height: 56px;
`;
export const FabButton = styled.button`
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  width: 56px;
  height: 56px;
  min-height: 36px;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 0.875rem;
  border: 0;
  cursor: pointer !important;
  margin: 0px 1px;
  outline: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  ${p=>p.disabled && css`
    pointer-events: none;
    >svg {
      fill: #a6a6a6;
      color: #a6a6a6;
    }
  `}
  color: ${p => p.primary ? p.theme.colors.white : p.theme.colors.black};
  background-color: ${p => p.primary ? p.theme.colors.gold : p.theme.colors.darkgrey};
  transition: all .4s ease-in-out;
  &:hover {
    color: ${p => p.primary ? p.theme.colors.white : p.theme.colors.black};
    background-color: ${p => p.primary ? p.theme.colors.gold : p.theme.colors.darkgrey};
    transform: scale(1.10);
  }
`;
export const getButtonTag = (props) => {
  if (typeof props.href === 'string') return 'a';
  if (typeof props.to === 'string') return Link;
  return 'button';
}
export const Fab = ({ title, className, Icon, ...props }) => {
  // The extra props should go to the button.  
  // If you need to pass props to the tooltip, please name them
  const tag = getButtonTag(props);
  if(Icon) return <FabTooltip title={title} className={className}><FabButton as={tag} {...props}><Icon/></FabButton></FabTooltip>
  return <FabTooltip title={title} className={className}><FabButton as={tag} {...props}/></FabTooltip>
}
const top = css`
  top: 10%;
`;
const center = css`
  right: calc(50% - 10px);
  flex-direction: row;
`;
const left = css`
  left: 20px;
`;
const right = css`
  right: 20px; 
  flex-direction: row;
`;
export const FabGroup = styled.div`
  z-index: 1000;
  position: fixed;
  display: flex;
  gap: .5rem;
  transition: right .5s, top .5s, left .5s, bottom .5s ease-in-out;
  z-index: 30;
  ${p => p.left&&left}
  ${p => p.right&&right}
  ${p => p.center && center}
  bottom: 10%;
`;
export const BackFab = props => {
  const navigate = useNavigate();
  return <Fab title="Back" id="back-fab" onClick={() => navigate(-1)} {...props}><BackIcon /></Fab>
}
export const SaveFab = props => (
  <Fab title="Save" id="save-fab" primary type="submit" {...props}><CheckIcon /></Fab>
)
export const CancelFab = props => (
  <Fab title="Cancel" id="cancel-fab" {...props}><CloseIcon /></Fab>
)
export const AddFab = props => (
  <Fab title="Add" id="add-fab" {...props}><AddIcon /></Fab>
)
export const EditFab = props => (
  <Fab title="Edit" id="edit-fab" {...props}><EditIcon /></Fab>
)
export const DeleteFab = props => (
  <Fab title="Delete" id="delete-fab" {...props}><DeleteIcon /></Fab>
)
export default Fab;
