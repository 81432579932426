import React, { useState } from 'react'
import styled from 'styled-components/macro';
import csv2json from '../../lib/csv2json';
import { transforms, destHeader } from './transforms';
import TextField from '../../atoms/TextField';
import Select from '../../atoms/Select';
import Row from '../../atoms/Row';
import Button from '../../atoms/Button';
import { listContacts } from '../../graphql/queries';
import { API, Storage } from 'aws-amplify'

export const StyledLoad = styled.div`
  
`;
const bankOptions = [
  {label:"Select a bank", value:""},
  {label:"Chase", value:"chase"},
  {label:"Chase Credit Card", value:"chaseCreditCard"},
  {label:"Truist", value:"truist"},
  {label:"Navy Federal", value:"navy"},
  {label:"C&F Financial Services", value:"cf"},
]
const clientOptions = [
  {label:"Streamline Landscaping", value:"4de0ed79-3fa0-4176-9d4c-9d90f3c6e751"},
  {label:"Plentura Realty LLC", value:"e115cabc-b6d2-4b99-9077-b9737ac3ac5b"},
];
export const Load = ({ onLoad, setFrom, from, values, setValues, ...props }) => {
  const handleRead = e => {
    const reader = new FileReader()
    reader.onload = () => {
      const json = csv2json(reader.result);
      // console.log(`json`, json);
      const transform = transforms[values.bank]||transforms.chase
      const final = transform(json, values)
      // console.log(`final`, final);
      onLoad(final)

    }
    reader.readAsBinaryString(e.target.files[0])
  }
  const handleChange = e => setValues({ ...values, [e.target.name]: e.target.value });
  return (
    <StyledLoad>
      <Row>
        <TextField label="From" name="from" value={values.from} onChange={handleChange}/>
        <Select label="Bank" name="bank" value={values.bank} onChange={handleChange} options={bankOptions}/>
        <input type="file" accept=".csv" onChange={handleRead} />
      </Row>
      {/*<Select label="Client" name="client" value={values.client} onChange={handleChange} options={clientOptions}/>*/}
      
    </StyledLoad>
  );
}
export default Load;