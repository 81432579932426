import React, { useState, Fragment } from 'react'
import styled from 'styled-components';
import { createContactUs } from '../../../graphql/mutations'
import useMutation from '../../../hooks/useMutation';

import Button, { ButtonRow as _ButtonRow } from '../../../atoms/Button';
import _CloseIcon from '../../../icons/Close';
import { Header3 } from '../../../atoms/Text'
import TextField from '../../../atoms/TextField';

const CloseIcon = styled(_CloseIcon)`
  position: absolute;
  top: .5em;
  right: .5em;
  cursor: pointer;
`;
const ButtonRow = styled(_ButtonRow)`
  margin: 2rem auto;
`;
const Title = styled(Header3)`
  // text-align: center;
  // font-size: 2rem;
  // // padding: 2rem;
  // font-weight: 400;
  // font-family: Futura Now Text, Futura, sans-serif;
  // ${p => p.theme.breakpoints.tablet} {
  //   font-size: 1.6rem;
  // }
  // @media screen and (max-width: 650px) {
  //   font-size: 1.3rem;
  // }
`;
const Subtitle = styled.p`
  font-size: 1.3rem;
  font-family: Lora;
  font-style: italic;
  // padding: 2rem;
  ${p => p.theme.breakpoints.tablet} {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 650px) {
    font-size: .9rem;
    text-align: justify;
  }
`;
const Dialog = styled.div`
  position: fixed;
  z-index: 21;
  padding: 3rem 3rem 0 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 4px 4px 9px 0 rgb(0 0 0 / 45%);
  width: 50vw;
  // height: 50vh;
  @media screen and (max-width: 650px) {
    padding: 2rem 2rem 0 2rem;
    width: 80vw;
  }
  ${p => p.theme.breakpoints.phone} {
    width: 100vw;
  }
`;
const Form = styled.form`
  text-align: left;
  padding: 2rem 2rem 0 2rem;
  @media screen and (max-width: 650px) {
    padding: 1rem 1rem 0 1rem;
  }
  ${p => p.theme.breakpoints.phone} {
    padding: 0;
  }
`;
const Background = styled.div`
  position: fixed;
  z-index: 20;
  background-color: black;
  opacity: 70%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const initialValues = { name: "", email: "", message: "I would like a free consultation." };

export const ConsultationDialog = ({ isOpen, onClose }) => {
  const [values, setValues] = useState(initialValues);
  const submit = useMutation(createContactUs)
  if(!isOpen) return null;
  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }
  const handleSubmit = async event => {
    event.preventDefault();
    await submit(values, {
      successMessage: "Your contact request has been sent successfully! We will contact you as soon as possible."
    })
    setValues(initialValues);
    onClose();
  }
  return (
    <Fragment>
      <Dialog>
        <Title>Request a free consultation</Title>
        <Subtitle>Please fill out the form below to request a free consultation, and we'll contact you as soon as possible.</Subtitle>
        <Form onSubmit={handleSubmit}>
          <TextField label="Name" required name="name" onChange={handleChange} value={values.name} />
          <TextField label="Email Address" required name="email" onChange={handleChange} value={values.email} />
          <ButtonRow><Button type="submit" gold>Send Request</Button></ButtonRow>
        </Form>
        <CloseIcon onClick={onClose}/>
      </Dialog>
      <Background onClick={onClose}/>
    </Fragment>
  )
}

export default ConsultationDialog;