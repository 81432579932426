import styled from 'styled-components/macro';

const defaultSize = 40;
export const Image = styled.img`
  height: ${p=>p.$size||defaultSize}px;
  width: ${p=>p.$size||defaultSize}px;
  border-radius: 50%;
`;

export const Avatar = props => {
  const handleError = e => {
    e.currentTarget.src = null;
    e.currentTarget.src = "/img/avatar-placeholder.jpg";
  };
  return <Image loading="lazy" onError={handleError} {...props}/>
}
export default Avatar;