import React, { useState, Fragment, useEffect } from 'react'
import styled from 'styled-components/macro';
import { Auth } from 'aws-amplify';
import TextField from '../../atoms/TextField';
import cognito from '../../lib/cognito';
import useQueryParams from '../../hooks/useQueryParams';
import useNotifications from '../../hooks/useNotifications';
import _Container from '../../atoms/Container';
import Row from '../../atoms/Row';
import Button, { ButtonRow } from '../../atoms/Button';
import Text, { Header2, Header3 } from '../../atoms/Text';
import { v4 as uuid } from 'uuid';
import { createFile } from '../../graphql/mutations';
import { API } from 'aws-amplify';
import _Checkbox from '../../atoms/Checkbox';
import Pre from '../../atoms/Pre';
import getUrl from '../../lib/getUrl';

const YEAR = "2022"
const CLIENTS_FOLDER_ID='3e29b077-6834-4531-a5d0-597c904a3c26';
const Container = styled(_Container)`
  padding-top: 2em;
`;
const HiddenField = styled(TextField)`
  display: none;
`;
const Header = styled(Header2)`
  text-align: left;
`;
const Checkbox = styled(_Checkbox)`
  flex: 1 0 100px;
`;
const Subheading = styled(Header3)`
  text-align: left;
  margin-top: 1em;
  margin-bottom:0;
`;
const Form = styled.form`
  max-width: 800px;
  margin: auto;
`;
const Results = styled.div`
  max-width: 800px;
  margin: auto;
`;
const ClientFolder = styled.pre`
  max-width: 800px;
  margin: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
const Email = styled.div`
  margin-top: 1em;
`;
const EmailPar = styled(Text)`
  margin: 1em 0;
`;
const getRandomString = () => {
  var MAXLEN=50; /* tweak this */
  var MINLEN=15;
  function genString() {
    var array = new Uint16Array(MAXLEN);
    window.crypto.getRandomValues(array);
    array = Array.apply( [], array ); /* turn into non-typed array */
    array = array.filter(function(x) {
      /* strip non-printables: if we transform into desirable range we have a propability bias, so I suppose we better skip this character */
      // return x > 32 && x < 127;
      return x===45 || (x>47&&x<=57) || (x>=64&&x<=90) || (x>=97&&x<=122)
    });
    return String.fromCharCode.apply(String, array);
  }
  var tmp = genString();
  while (tmp.length < MINLEN) {
    /* unlikely too loop more than once.. */
    tmp += genString(); 
  } 
  return tmp
}
export const AddUser = ({ ...props }) => {
  const { params } = useQueryParams()
  const isDev = window.location.hostname === "localhost";
  const showMessage = useNotifications()
  const [result, setResult] = useState();
  const [values, setValues] = useState({
    password: params.password||getRandomString(),
    // email:"fred@flintstone.net",
    email:params.email||"",
    // name:"Fred Flintstone"
    name:params.name||"",
    contact:params.contact||"",
  })
  useEffect(()=>{
    console.log('params',params);
    
    if(params.name) setResult({user:params})
  },[params])
  const handleChange = e => {
    if(e.target.type==='checkbox') setValues({...values, [e.target.name]: e.target.checked})
    else setValues({...values, [e.target.name]: e.target.value})
  }
  const createFolder = async (name, parentID, creator, id) => {
    const file = { 
      id: id || uuid(),
      name, 
      type:"Folder", 
      creator,
      key:"",
      parentID,
    }
    // console.log("file: ",file);
    await API.graphql({
      query: createFile,
      variables: { input: file },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    return file;
  }
  const createTaxFolders = async (name, parentID, creator) => {
    console.log(`createTaxFolders`, name);
    const folder = await createFolder(name, parentID, creator);
    return await createFolder(YEAR, folder.id, creator);
  }
  const getStoragePath = () => {
    if(!result||!result.user) return "";
    const { host, protocol } = window.location;
    return `${protocol}//${host}${getUrl('users','setPassword', {username:values.email, password:values.password})}&redirect=${getUrl('storage','folder', result.user.id)}`
  }
  const getEmailPath = () => {
    if(!result||!result.user) return "";
    const { host, protocol } = window.location;
    return `${protocol}//${host}/users/add?id=${result.user.id}&password=${values.password}&email=${values.email}&name=${values.name}&contact=${values.contact}`
  }
  const handleSubmit = async e => {
    e.preventDefault();
    const { password, name, email } = values;
    // Create the user
    const { user, userSub } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email: email,
        name
      }
    });
    // Automaticly confirm the user
    await cognito.confirmUserSignUp(email)
    // add the user to the clients group
    await cognito.addToGroup(user, "Clients")
    // create a folder for the clients files
    const mainFolder = await createFolder(name, CLIENTS_FOLDER_ID, userSub, userSub);
    // console.log(`mainFolder`, mainFolder);
    // console.log(`values`, values);
    if(values.personalTax) await createTaxFolders('Personal Tax', mainFolder.id, userSub);
    let business;
    if(values.businessIncomeTax||values.salesTax||values.payrollTax||
      values.businessPersonalPropertyTax||values.bookkeeping
    ) {
      business = await createFolder('Business Tax', mainFolder.id, userSub);
      if(values.businessIncomeTax) await createTaxFolders('Business Income Tax', business.id, userSub);
      if(values.salesTax) await createTaxFolders('Sales Tax', business.id, userSub);
      if(values.payrollTax) await createTaxFolders('Payroll Tax', business.id, userSub);
      if(values.businessPersonalPropertyTax) await createTaxFolders('Business Personal Property Tax', business.id, userSub);
    }
    if(values.bookkeeping) await createTaxFolders('Bookkeeping', mainFolder.id, userSub);
    setResult({ file: mainFolder, user: { id: userSub, email: email, name } })
    showMessage("Client added successfully.", "success")
  }
  // const handleClear = async values => {

  // }
  const autoFill = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    setValues({
      fred: {name:"Flintstone Inc", contact:"Fred Flintstone",email:"fred@flintstone.net", password:"QWthNCXxDGNgYVw"},
      wilma: {name:"Wilma LLC", contact:"Wilma Flintstone",email:"wilma@flintstone.net", password:"0FGMoeMfwdXtB9P"},
      barney: {name:"Rubble Inc", contact:"Barney Rubble",email:"barney@rubble.net", password:"q3x0LOuzGWwyG1H"},
      betty: {name:"Betty LLC", contact:"Betty Rubble",email:"betty@rubble.net", password:"fz5lWlnjWAlYw1X"},
    }[name])
  }
  // console.log(`storageUrl`, storageUrl);
  return (
    <Container>
      {!result?(
        <Form onSubmit={handleSubmit}>
          <Header>Add Client</Header>
          <TextField label="Business Name" name="name" value={values.name} onChange={handleChange}/>
          <TextField label="Person Name" name="contact" value={values.contact} onChange={handleChange}/>
          <TextField label="Email" name="email" value={values.email} onChange={handleChange}/>
          <HiddenField label="Password" name="password" value={values.password} onChange={handleChange}/>
          <Subheading>Folders to create</Subheading>
          <Row>
            <Checkbox label="Sales Tax" name="salesTax" value={values.salesTax} onChange={handleChange}/>
            <Checkbox label="Payroll Tax" name="payrollTax" value={values.payrollTax} onChange={handleChange}/>
            <Checkbox label="Business Income Tax" name="businessIncomeTax" value={values.businessIncomeTax} onChange={handleChange}/>
            <Checkbox label="Personal Tax" name="personalTax" value={values.personalTax} onChange={handleChange}/>
            <Checkbox label="Business Personal Property Tax" name="businessPersonalPropertyTax" value={values.businessPersonalPropertyTax} onChange={handleChange}/>
            <Checkbox label="Bookkeeping" name="bookkeeping" value={values.bookkeeping} onChange={handleChange}/>
          </Row>
          {isDev&&(
            <Fragment>
              <Button onClick={e=>autoFill(e,'fred')}>Fred Flintstone</Button>
              <Button onClick={e=>autoFill(e,'wilma')}>Wilma Flintstone</Button>
              <Button onClick={e=>autoFill(e,'barney')}>Barney Rubble</Button>
              <Button onClick={e=>autoFill(e,'betty')}>Betty Rubble</Button>
            </Fragment>
          )}
          <ButtonRow><Button type="submit">Save</Button></ButtonRow>
        </Form>
      ):(
        <Results>
          <Header>{values.name}</Header>
          <Subheading>Email url:</Subheading>
          <ClientFolder>{getEmailPath()}</ClientFolder>
          <Subheading>Set Password url:</Subheading>
          <ClientFolder>{getStoragePath()}</ClientFolder>
          <Email>
            <EmailPar>To: {values.email}</EmailPar>
            <EmailPar>Subject: Woodbourne Accounting Secure Storage Account Information and Document Upload</EmailPar>
            <EmailPar>Dear {values.contact},</EmailPar>
            <EmailPar>We look forward to working with you this year. To get off to a great start, we have set up a new secure storage account for you on our new website.</EmailPar>
            <EmailPar>Please use this link <a href={getStoragePath()}>Woodbourne Secure Storage</a> to create your password and upload any required documents for your taxes. We recommend that you upload all necessary documents as soon as possible to ensure we start working on your tax filing promptly.</EmailPar>
            <EmailPar>We’ve gone through considerable time and effort to provide this service to you to protect your online security.  We do not recommend sending documents via email since it is not encrypted.</EmailPar>
            <EmailPar>If you prefer you can send your documents via USPS Certified Mail, Express delivery services like UPS or FedEx, by fax, or by delivering your documents in person.</EmailPar>
            <EmailPar>If you have any questions or concerns, please don't hesitate to reach out to us at arodgers@woodbourneaccounting.com.</EmailPar>
            <EmailPar>Thank you for working with Woodbourne Accounting. We appreciate your business and look forward to working with you!</EmailPar>
            <EmailPar>Best regards,</EmailPar>
            <EmailPar>Albert Rodgers<br/>Woodbourne Accounting</EmailPar>
          </Email>
        </Results>
      )}
      <Pre>{JSON.stringify(values, null,2)}</Pre>

      {/*<TextField name="username" value={values.username} onChange={handleChange}/>*/}
    </Container>
  );
}
export default AddUser;