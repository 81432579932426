import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import getUrl from '../../lib/getUrl';
import { Header1, Header3, Text } from '../../atoms/Text';
import { useParams, useNavigate } from "react-router-dom";
// import useUser from '../../hooks/useUser';
// import { diff } from 'deep-object-diff';
import { toCents, formatCents } from '../../lib/cents';
// import Button, { ButtonRow } from '../../atoms/Button';
import TextField from '../../atoms/TextField';
// import IconButton from '../../atoms/IconButton';
import DownloadIcon from '../../icons/Download';
import IconButton from '../../atoms/IconButton';
import AddIcon from '../../icons/Add';
import DeleteIcon from '../../icons/Delete';
// import Select from '../../atoms/Select';
// import CountrySelect from '../../atoms/CountrySelect';
// import StateSelect from '../../atoms/StateSelect';
// import GenderSelect from '../../atoms/GenderSelect';
// import cloneDeep from 'clone-deep';

import Row from '../../atoms/Row';
import useMutation from '../../hooks/useMutation';
import useQueryParams from '../../hooks/useQueryParams';
import useForm from '../../hooks/useForm';
// import { getInvoiceQuery, createInvoiceMutation, updateInvoiceMutation, deleteInvoiceMutation } from './queries'
import { getInvoice } from '../../graphql/queries';
import { createInvoice as createInvoiceMutation, updateInvoice as updateInvoiceMutation, deleteInvoice as deleteInvoiceMutation } from '../../graphql/mutations';
import { FabGroup, BackFab, SaveFab, CancelFab, DeleteFab, Fab } from '../../atoms/Fab';
import Query from '../../atoms/Query';
// import getDisplayName from '../../lib/getDisplayName';
import { formatDate, addDays} from '../../lib/formatdates';
// import { listProducts } from './queries';
import generatePdf from './pdf';
import clone from '../../lib/clone';

const defaultItem = {description:"Bookkeeping", price:"$65.00", total:"$65.00", quantity: 1}
const defaultValues = { 
  number:"",
  total:"$65.00",
  items:[defaultItem]
}
const Code = styled.pre`
  width:100%;
`;
const ColumnHeader = styled.div`
  flex: 1;
  font-weight: bold;
`;
const HeaderRow = styled(Row)`
  margin-top: 2em;
`;
const TotalRow = styled(Row)`
  justify-content: flex-end;
`;
const SpacerCell = styled.div`
  flex: 1 0 200px;
`;
const Root = styled.div`
  padding: 4rem;
  max-width: 1400px;
  margin: auto;
`;
const Form = styled.form`
  // display: flex;
  // flex-wrap: wrap;
  // gap: 1em;
`;
// const getInitialValues = invoice => {
//   const values = {
//     ...initialValues, 
//     ...invoice,
//   };
//   if(values.id==='new') values.id = uuid();
//   return values;
// }
// const products = [
//   {value: "TAX", label:"Tax Preparation", price:"$150.00"}
// ]
const Item = ({ item, values, handleChange, index, setValues }) => {
  const handleChangeItemPrice = event => {
    values.items[index].price = event.target.value;
    const total = toCents(event.target.value||"")*toCents(item.quantity||0)/100;
    if(!isNaN(total)) values.items[index].total = formatCents(total);
    const totalCents = values.items.reduce((acc, item)=>{
      const total = toCents(item.price||"")*toCents(item.quantity)/100;
      if(!isNaN(total)) acc+=total;
      return acc;
    },0)
    setValues({...values, total: formatCents(totalCents)})
  }
  const handleChangeItemQuantity = event => {
    // debugger;
    values.items[index].quantity = event.target.value;
    const total = toCents(item.price||"")*toCents(event.target.value||0)/100;
    if(!isNaN(total)) values.items[index].total = formatCents(total);
    const totalCents = values.items.reduce((acc, item)=>{
      console.log('item.price',item.price);
      console.log('item.quantity',item.quantity);
      
      
      const total = toCents(item.price||"")*toCents(item.quantity||"")/100;
      if(!isNaN(total)) acc+=total;
      return acc;
    },0)
    setValues({...values, total: formatCents(totalCents)})
  }
  const handleRemoveItem = () => {
    const items = values.items.slice(0,index).concat(values.items.slice(index+1))
    setValues({ ...values, items })
  }
  return (
    <Row>
      <TextField name={`items[${index}].description`} value={item.description} onChange={handleChange}/>
      <TextField name={`items[${index}].quantity`} value={item.quantity} onChange={handleChangeItemQuantity}/>
      <TextField name={`items[${index}].price`} value={item.price} onChange={handleChangeItemPrice}/>
      <TextField name={`items[${index}].total`} value={item.total} onChange={handleChange}/>
      <IconButton Icon={DeleteIcon} onClick={handleRemoveItem}/>
    </Row>
  )
}
export const EditInvoice = ({ getInvoice: invoice, listProducts: products, updateCache, formatData, invoicesByNumber }) => {
  const fieldBlacklist = ['createdAt', 'updatedAt', 'contact'];
  const { params } = useQueryParams();
  // console.log(`invoice`, invoice);
  const date = formatDate();
  const getNextInvoiceNumber = () => {
    try{
      return parseInt(invoicesByNumber.items[0].number)+1;
    } catch(e) {
      return "";
    }
  }
  const initialValues = { 
    number: getNextInvoiceNumber(),
    contactId: params.contactId, 
    clientName: params.name,
    ...defaultValues,
    date:formatDate(),
    due: formatDate(addDays(date, 30)),
    ...clone(invoice, fieldBlacklist),
  };
  useEffect(()=>{
    if(!values.number) {
      const number = getNextInvoiceNumber();
      if(number) setValues({ ...values, number })
    }
  },[invoicesByNumber])
  const onSubmit = async values => {
    const items = values.items.map(item=>({
      ...item, 
      price: toCents(item.price),
      id: uuid(),
      total: toCents(item.total),
    }))
    const invoice = {
      type:"Invoice",
      ...values,
      items,
      total: toCents(values.total),
    };
    // console.log(`invoice`, invoice);
    if(!values.id) {
      invoice.id=uuid();
      const result = await createInvoice(invoice)
      // console.log(`result`, result);
      console.log(`result.data.createInvoice`, result.data.createInvoice);
      updateCache(formatData({ getInvoice: result.data.createInvoice }))
      // navigate(getUrl('contacts','edit',params.contactId))
    }
    else {
      const result = await updateInvoice(invoice)  
      updateCache({ getInvoice: result.data.updateInvoice })
    }
  }
  const { values, handleChange, reset, handleSubmit, dirty, setValues } = useForm({ initialValues, onSubmit, enableReinitialize:true })
  const navigate = useNavigate();
  const createInvoice = useMutation(createInvoiceMutation, {
    successMessage: "Invoice created successfully!"
  })
  const updateInvoice = useMutation(updateInvoiceMutation, {
    successMessage: "Invoice updated successfully!"
  })
  const trash = useMutation(deleteInvoiceMutation, {
    successMessage: "Invoice deleted successfully!",
    confirmMessage: "Are you sure you want to delete this invoice?",
    refetch: () => navigate(getUrl('invoices','list'))
  })
  const handleDelete = async () => await trash({ id: invoice.id, _version: invoice._version })
  const handlePdf = () => {
    const address = invoice.contact.addresses.reduce((acc,add)=>add.type==="Billing"?add:acc,{});
    const contact = {
      ...invoice.contact,
      address,
    }
    generatePdf({ invoice, contact })
  }
  const handleAddItem = () => {
    const items = [...values.items, defaultItem];
    setValues({ ...values, items})
  }
  return (
    <Root>
      <Form onSubmit={handleSubmit}>
        <Header1>Invoice #{values.number}</Header1>
        <Row>
          <TextField label="Number" name="number" onChange={handleChange} value={values.number} />
          <TextField label="Client" name="name" value={values.clientName} readOnly />
          <TextField label="Paid" name="paid" onChange={handleChange} value={values.paid} />
        </Row>
        <Row>
          <TextField label="Invoice Created" name="date" onChange={handleChange} value={values.date} />
          <TextField label="Month Billed" name="month" onChange={handleChange} value={values.month} />
          <TextField label="Due" name="due" onChange={handleChange} value={values.due} />
        </Row>
        <HeaderRow>
          <ColumnHeader>Description</ColumnHeader>
          <ColumnHeader>Quantity</ColumnHeader>
          <ColumnHeader>Price</ColumnHeader>
          <ColumnHeader>Total</ColumnHeader>
        </HeaderRow>
        {values.items.map((item, index)=><Item item={item} key={index} setValues={setValues} index={index} values={values} handleChange={handleChange}/>)}
        <Row>
          <SpacerCell/>
          <SpacerCell/>
          <SpacerCell/>
          <TextField name="total" onChange={handleChange} value={values.total} />
          <IconButton Icon={AddIcon} onClick={handleAddItem}/>
        </Row>
      </Form>
      <Code>values: {JSON.stringify(values)}</Code>
      <Code>invoice: {JSON.stringify(invoice)}</Code>
      <Code>dirty: {JSON.stringify(dirty)}</Code>

      <FabGroup left><BackFab/></FabGroup>
      <FabGroup right>
        {dirty&&(
          <Fragment>
            <SaveFab onClick={handleSubmit}/>
            <CancelFab onClick={reset}/>
          </Fragment>
        )}
        <Fab Icon={DownloadIcon} onClick={handlePdf}/>
        <DeleteFab onClick={handleDelete}/>
      </FabGroup>
    </Root>
  )
}

// const InvoiceWithProducts = props => {
//   return <Query Component={EditInvoice} query={listProducts} showLoading {...props}/>
// }
const InvoiceWithData = props => {
  const { id } = useParams();
  const formatData = ({ getInvoice }) => {
    const items = getInvoice.items.map(item=>({
      ...item,
      price: formatCents(item.price),
      total: formatCents(item.total),
    }))
    return {
      getInvoice: {
        ...getInvoice,
        items,
        total: formatCents(getInvoice.total),
        date: formatDate(getInvoice.date),
        due: formatDate(getInvoice.due),
      }
    }
  }
  return <Query Component={EditInvoice} query={getInvoice} showLoading variables={{ id }} formatData={formatData} {...props}/>
}


export default InvoiceWithData;