import React from 'react'
import styled from 'styled-components';
import getUrl from '../../lib/getUrl';
import Link from '../../atoms/Link';
import Query from '../../atoms/Query';
import { recentPostsQuery } from './queries';

const Container = styled.div`
  // width: 20%;
  width: 300px;
  // max-width: 250px;
  padding: 2em;
`;
const Heading = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;
const Item = styled(Link)`
  display: block;
  position: relative;
  width: 300px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Title = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;
const Dateline = styled.div`
  margin-bottom: 2em;
  color: #6f6f6f;
`;
const Category = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  background-color: ${p=>p.theme.colors.gold};
  color: white;
  padding: 1em;
`;
const Post = ({ id, createdAt, cardImgUrl, title, category }) => {
  const date = new Date(createdAt||"").toLocaleDateString('en-US', {
    month: 'long',
    day:'numeric',year: 'numeric',
  })
  return (
    <Item to={getUrl('post','detail',id)}>
      <Image loading="lazy" src={cardImgUrl} />
      <Category>{category}</Category>
      <Title>{title}</Title>
      <Dateline>Published: {date}</Dateline>
    </Item>
  )
}
export const Recent = ({ postsByDate={items:[]} }) => {
  const posts = postsByDate.items;
  return (
    <Container>
      <Heading>Recent Articles</Heading>
      {posts.map(post=><Post key={post.id} {...post}/>)}
    </Container>
  )
}

const ListWithData = props => {
  const variables = { type:"Post", limit: 3 }
  return <Query Component={Recent} query={recentPostsQuery} variables={variables} {...props}/>
}
export default ListWithData;