import React, { Fragment } from 'react'
import styled, { css } from 'styled-components/macro';
import Grid from './ServicesGrid';
import Text, { Header1 } from '../../../atoms/Text';
import _File from '../../../icons/File';
import _Bank from '../../../icons/Bank';
import _Wallet from '../../../icons/Wallet';
import _Scale from '../../../icons/Scale';
import _Ballot from '../../../icons/Ballot';
import Button, { ButtonRow } from '../../../atoms/Button';

const iconStyle = css`
  width: 35%;
  height: 35%;
  margin-bottom: 10%;
`;
const File = styled(_File)(iconStyle);
const Bank = styled(_Bank)(iconStyle);
const Wallet = styled(_Wallet)(iconStyle);
const Scale = styled(_Scale)(iconStyle);
const Ballot = styled(_Ballot)(iconStyle);

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 4em 0;
`;
const Detail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Image = styled.img`
  width: 100%;
  height: 50%;
  @media only screen and (max-width: 55em) {
   width: 0px;
    height: 0px;
  }
`;
// const ImageCell = styled.div`
//   flex: 0 0 35%;
//   transition: width 1s;
//   @media only screen and (max-width: 900px) {
//     flex: 0 0 0%;
//   }
// `;
const Header = styled(Header1)`
  margin-top: 0;
  padding: 0 4rem;
`;
const Body = styled(Text)`
  margin-top: 1em;
  font-size: 1.1rem;
`;
const ImageWrapper = styled.div`
  flex: 0 1 450px;
  @media only screen and (max-width: 55em) {
    display: none;
  }
`;
export const Ulist = styled.ul`
  margin-left: 2em;
`;
const ServicesCategory = ({ id, position, img, title, srcSet, ...props }) => {
  return (
    <Group id={id}>
      {position === 'left' && 
        <ImageWrapper>
          <Image 
            alt="people working" 
            loading="lazy" 
            src={img} 
            sizes="(max-width: 478px) 30vw, (max-width: 767px) 27vw, (max-width: 991px) 25vw, 18vw"
            srcSet={srcSet}
          />
        </ImageWrapper>
      }
      <Detail>
        <Header>{title}</Header>
        <Grid {...props} />
      </Detail>
      {position === 'right' && 
        <ImageWrapper>
          <Image 
            alt="people working" 
            loading="lazy" 
            src={img}
            sizes="(max-width: 478px) 30vw, (max-width: 767px) 27vw, (max-width: 991px) 25vw, 18vw"
            srcSet={srcSet}
          />
        </ImageWrapper>
      }
    </Group>
  );
}
export const ServicesGroups = ({ openConsultationDialog }) => {
  return (
    <Fragment>
      <ServicesCategory
        id="individual"
        loading="lazy"
        img="/images/Individual.webp"
        srcSet="/images/Individual-mobile.webp 477w, /images/Individual-mobile.webp 766w, /images/Individual.webp 990w" 
        title="If you’re an individual, we’ve got you covered"
        openConsultationDialog={openConsultationDialog}
        items={[
          { title: "General Bookkeeping", 
            Icon: Wallet, 
            body: ({ openConsultationDialog }) => (
              <Body>
                When you work with Woodbourne Accounting you'll get insightful counsel on how to strengthen your financial situation and achieve your objectives. They can assist you in locating potential areas of overspending and offer suggestions for ways to lower costs and raise money. They can also assist you in setting up a budget, making plans for retirement or other financial objectives, and maintaining your financial plan. Working with Woodbourne Accounting can help you reach your goals and have a greater understanding of and control over your finances.
                <br /><br />
                Professional bookkeepers also possess the expertise and knowledge to guarantee that your financial records are truthful and compliant with tax rules, assisting you in avoiding any potential fines or penalties. Hiring a professional bookkeeping firm like Woodbourne Accounting to handle your bookkeeping can save you time and give you peace of mind.
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          },
          { title: "Tax Preparation & Planning", 
            Icon: File, 
            body: ({ openConsultationDialog }) => (
              <Body>
                A one-size-fits-all solution to your individual tax needs does not exist. Whether you are filing form 1040, 1040ES, or 1099-MISC, let us create innovative tax planning solutions to help you protect your resources, capitalize on tax law changes, and save on taxes.
                <br />
                <br />
                We will not just help you to prepare your tax form, we want to help you take control of your taxes. That involves taking into consideration your unique situation, such as planning for a child's college education expenses, debt problems and credit standing, a change in marital status, being unable to get the credit you need, or having difficulty living on the income you have and paying your bills.  Tax planning is the process of organizing your financial affairs in a way that minimizes your tax liability. It involves taking advantage of deductions, credits, and other tax-saving opportunities to legally reduce the amount of taxes you owe. By planning ahead, you can take advantage of these opportunities before the end of the tax year, rather than waiting until you file your tax return. This kind of planning can planning can help you avoid unexpected tax bills, penalties, and interest charges, which can be costly and time-consuming to resolve.
                <br /><br />
                Woodbourne Accounting offers individuals the following services:
                <br />
                <br />
                <Ulist>
                  <li>Federal and multistate tax prep</li>
                  <li>Gift tax planning and preparation</li>
                  <li>Comprehensive tax planning</li>
                  <li>We will review your past tax returns <b>free</b></li>
                  <li>If there were problems in the last few year's tax returns, we will submit amended tax returns to the IRS and your state if the change would benefit you.</li>
                  <li>We <b>guarantee</b> the highest possible refund due you</li>
                  <li>The security of our clients is the most important thing to us. We do everything to keep your sensitive data protected. We use encrypted software to keep your information safe.</li>
                </Ulist>
                <br />
                We are professionals with more than 70 years of experience in taxes and accounting, and <b>we will get it right</b> and make sure that you benefit from all applicable tax provisions.  Our team of accountants at Woodbourne Accounting can represent you in any tax-related matter, from appeals to audits to payment to collection issues. Our specialty is helping taxpayers with complex matters, including multistate returns, tax-deferred exchanges, foreign taxes, and audits.
                <br />
                <br />
                Let our team of tax experts help you protect your your resources and save on your taxes
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          },
        ]}
        position="left"
      />
      <ServicesCategory
        id="business"
        loading="lazy"
        srcSet="/images/Business-mobile.webp 477w, /images/Business-mobile.webp 766w, /images/Business.webp 990w" 
        img="/images/Business.webp"
        title="Got a small business? We can help you manage things"
        openConsultationDialog={openConsultationDialog}
        items={[
          // { offset: {
          //   2000: "20%",
          //   1200: "10%",
          //   900: "0",
          // }, label: "Tax Preparation", Icon: File, text: "As small business tax specialists, we prepare returns in every state (except Oregon) and have experience in LLCs and LLPs, S corporations, C corporations, partnerships, and sole proprietorships. Let us handle your complex business taxes with professionalism and skill." },
          // { label: "Banking & Credit Management", Icon: Bank, text: "Learn how our accountants and bookkeepers support you when it comes to improving cash flow and mitigating late payments." },
          // { offset: {
          //   1200: "10%",
          //   900: "0",
          // }, label: "General Bookkeeping", Icon: Wallet, text: "Need help with your business accounts? Our bookkeepers love organizing accounts to support our partners in growing their businesses." },
          // { offset: {
          //   2000: "20%",
          //   900: "0",
          // }, label: "1099-MISC Preparation", Icon: File, text: "Don’t let the tediousness of annual 1099-MISC compliance distract you from your core business. Let us use our expertise and resources to manage your company’s annual 1099 reporting." },
          // { offset: {
          //   1200: "10%",
          //   900: "0",
          // }, label: "IRS Inquiries", Icon: Scale, text: "Did you receive a notice from the IRS? Don’t panic. The qualified tax professionals at Woodbourne Accounting will help you respond to the notice and figure out your next step." },
          // { label: "DBA Filings", Icon: Ballot, text: "See how we can help you file a DBA to operate your business under a new or additional name." },
          { label: "Tax Planning", 
            title: "Helping Small Businesses in Chesterfield, VA, With Tax Planning and Prep Services", 
            Icon: File, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Woodbourne Accounting’s small business tax prep covers taxes of all kinds. Trust us to help you get the job done right, regardless of your business structure!
                <br />
                <br />
                <h2>Small Business Tax Planning</h2>
                <br />
                Tax planning is necessary to run a business. However, most business owners need more time to understand shifting tax rules. We offer tax prep and planning services for:
                <br />
                <br />
                <h3>Sole Proprietors (self-employed)</h3>
                <br />
                Sole proprietors and their businesses are legally connected. You’re responsible for your business's liabilities, returns, expenses, and assets. Our small business tax prep and planning services minimize the stress of tax season, helping with your schedule C, 1040 ES, 1096, and 1099-MISC while maximizing your deductions and minimizing your tax liabilities.
                <br />
                <br />
                <h3> Limited Liability Companies (LLC)</h3>
                <br />
                You and your business are considered separate. Your LLC is a “pass-through” entity, and taxes are paid based on the individual tax code instead of the corporate tax code. Let our team simplify the return process, helping you get the most out of your return.
                <br />
                <br />
                <h3> General Partnerships (1065)</h3> 
                <br />
                Partnerships are not taxable entities. Like sole proprietorships, the business and the partners are treated as equals. Partnerships divide assets and liability based on the percentage of the business owned. Our tax prep service can help general partnerships increase their gains while simplifying the tax process considerably.
                <br />
                <br />
                <h3>C Corporations (1120)</h3>
                <br />
                Corporations are individual tax-paying legal entities required to pay corporate income tax minus any losses, credits, or deductions that could lead to double taxation. Woodbourne Accounting can clarify the process, maximize the amount of money you get back, and help you make the most of your tax return. 
                <br />
                <br />
                <h3>S Corporations (1120S)</h3>
                <br />
                Similar to LLCs, S corporations are separate “pass-thru” entities. S corporations have increased flexibility when it comes to taxation. Our small business prep team can help you make the most of the monetary possibilities S corporations offer.
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          },
          { title: "Bookkeeping", 
            Icon: Bank, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Whether you’re looking for self-employed or small business bookkeeping, Woodbourne Accounting has the services to meet your needs. We will help you to do the following:
                <br />
                <br />
                <Ulist>
                  <li>Stop wasting time categorizing your transactions.</li>
                  <li>Know that your books are up-to-date, giving you the peace of mind to make sound financial decisions.</li>
                  <li>Get more time to focus on growing your business while we handle your bookkeeping.</li>
                  <li>Organize cash or accrual accounting.</li>
                </Ulist>
                <br />
                We will work with you using the software you know and trust, including QuickBooks and other accounting software you choose. Get the back office workflow off your shoulders, and let us make the complicated bookkeeping process more accessible and less time-consuming.
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          },
          { title: "Payroll", 
            Icon: File, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Woodbourne Accounting proudly provides payroll services to clients in Chesterfield, Virginia, and the United States. Our team helps you reduce the time spent managing your business's payroll. We can help you with record-keeping, payroll tax returns, and 1099 and W-2 filings.
                <br />
                <br />
                If you have an in-house bookkeeper, we can assist them in performing payroll and bookkeeping functions, and we are proficient with all popular computer accounting software.
                <br />
                <br />
                Our services include:
                <br />
                <br />
                <Ulist>
                  <li>Direct deposit</li>
                  <li>Payroll processing</li>
                  <li>Quarterly tax returns</li>
                  <li>Electronic payment of your tax liability</li>
                  <li>Form 940 annual federal unemployment</li>
                  <li>W-2 processing</li>
                </Ulist>
                <br />
                Contact Woodbourne Accounting to Talk about Your Payroll Needs
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          },
          { title: "Accounts Payable", 
            Icon: Scale, 
            body: ({ openConsultationDialog }) => (
              <Body>
                At Woodbourne Accounting, we help our clients optimize operating costs and working capital while creating value for their business through our accounts payable (AP) services. We can help you meet regulatory policies, modernize payment activities, and benefit from customized services. Our comprehensive range of AP services includes:
                <br />
                <br />
                <Ulist>
                  <li>Processing purchasing orders</li>
                  <li>Matching invoices to purchase orders</li>
                  <li>Process standard pricing </li>
                </Ulist>
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          },
          { title: "Cash Management", 
            Icon: Ballot, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Woodbourne Accounting offers simple financial solutions for your cash management. Freeing you from routine cash management lets you focus on acquiring new customers and solidifying relationships with your stakeholders. Our cash management services include:
                <br />
                <br />
                <Ulist>
                  <li>Daily cash flow reports</li>
                  <li>Credit card report reconciliation</li>
                  <li>Bank statements reconciliation</li>
                  <li>Cash flow forecasting/planning</li>
                </Ulist>
                <br />
                Let our team budget your cash flows, predicting the inflow and outflow of cash with a high level of accuracy. This allows you to mitigate future risks and gives you the tools to manage future financial requirements.
                <br />
                <br />
                Planning cash flows, like budgeting, allows you to estimate future cash flows accurately. Our accounting team will produce your monthly statements, allowing you to effectively plan future budgeting, investments, and fundraising.
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          },
          { title: "Incorporation & Filing", 
            Icon: File, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Woodbourne Accounting has more than two decades of experience managing accounts and providing incorporation services. Our CPA team can help you start your own business and provide before and after incorporation services. Our services include:
                <br />
                <br />
                <Ulist>
                  <li>Registered Agent Services</li>
                  <li>Express Filing Services</li>
                  <li>Corporate Supplies</li>
                  <li>Business Name Search</li>
                  <li>Trademark Search</li>
                  <li>Doing Business As (DBAs)</li>
                  <li>Licenses, Permits, and Filings</li>
                  <li>Business Filings</li>
                  <li>Compliance Services</li>
                </Ulist>
                <br />
                Trust Woodbourne Accounting to provide the services needed to start your business and keep it running successfully, in compliance, and without mistakes.
                <ButtonRow><Button gold onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
              </Body>
            )
          }
        ]}
        position="right"
      />
      {/* <ServicesCategory
        id="non-profit"
        loading="lazy"
        img="/images/NonProfit.webp"
        title="Running a non-profit? We want to help out"
        items={[
          // { label: "Tax Preparation", Icon: File, text: "As small business tax specialists, we prepare returns in every state (except Oregon) and have experience in LLCs and LLPs, S corporations, C corporations, partnerships, and sole proprietorships. Let us handle your complex business taxes with professionalism and skill." },
          // { offset: {
          //   1200: "-10%",
          //   900: "0",
          // }, label: "Banking & Credit Management", Icon: Bank, text: "Learn how our accountants and bookkeepers support you when it comes to improving cash flow and mitigating late payments." },
          // { offset: {
          //   2000: "-20%",
          //   900: "0",
          // }, label: "General Bookkeeping", Icon: Wallet, text: "Need help with your business accounts? Our bookkeepers love organizing accounts to support our partners in growing their businesses." },
          // { offset: {
          //   1200: "-10%",
          //   900: "0",
          // }, label: "1099-MISC Preparation", Icon: File, text: "Don’t let the tediousness of annual 1099-MISC compliance distract you from your core business. Let us use our expertise and resources to manage your company’s annual 1099 reporting." },
          // { label: "IRS Inquiries", Icon: Scale, text: "Did you receive a notice from the IRS? Don’t panic. The qualified tax professionals at Woodbourne Accounting will help you respond to the notice and figure out your next step." },
          // { offset: {
          //   2000: "-20%",
          //   1200: "-10%",
          //   900: "0",
          // }, label: "DBA Filings", Icon: Ballot, text: "See how we can help you file a DBA to operate your business under a new or additional name." },
          { title: "Federal & State 990 Filing", 
            Icon: File, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Although nonprofits don’t usually file taxes, to maintain their 501(c)(3) status, they need to file form 990. Form 990 allows nonprofits to record the required financial information for the IRS. The IRS uses this information to ensure that a nonprofit complies with the requirements to maintain its nonprofit status.
                <br />
                <br />
                Woodbourne Accounting helps your nonprofit decides if it needs to file the following:
                <br />
                <br />
                <Ulist>
                  <li>990N</li>
                  <li>990EZ</li>
                  <li>990 Postcard</li>
                  <li>Standard 990 Form</li>
                </Ulist>
                <br />
                Let our team of CPA experts help your nonprofit conduct a financial audit before completing your form 990 filing.
              </Body>
            )
          },
          { title: "Donations and Response Tracking", 
            Icon: Bank, 
            body: ({ openConsultationDialog }) => (
              <Body>
                At Woodbourne Accounting, we understand that nonprofits use fund accounting to focus on accountability instead of profitability. Every penny needs to be accounted for, whether your funding comes from a donation, grant, or another source. Donors demand transparency to help them know that their money is invested wisely.
                <br />
                <br />
                Trust us to help you keep donated funds in appropriate categories, restricted and those without restrictions. Keep the trust of your donors by using restricted funds for their specific purpose while using unrestricted funds discreetly within your organization.
                <br />
                <br />
                Our team will prepare the following:
                <br />
                <br />
                <Ulist>
                  <li>Statement of financial position showing your liabilities and assets, separating them into those with restrictions and without donor restrictions</li>
                  <li>Statements of activities show changes made to restricted and unrestricted net reciepts</li>
                  <li>Statements of cash flow that identify the outward and inward flow of cash</li>
                </Ulist>
                <br />
                With Woodbourne Accounting, you can enjoy the peace of mind of knowing that your accounting complies with FASB and GAAP standards.
              </Body>
            )
          },
          { title: "Fundraising Cost Evaluation", 
            Icon: Wallet, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Your nonprofit may use internal resources or professional fundraisers to attract potential donors. Woodbourne Accounting can help you ensure your fundraising efforts get results while helping you keep your tax-exempt status.
                <br />
                <br />
                The IRS has increased its scrutiny for all not-for-profits. This makes filling all Form 990s adequate and in harmony with Generally Accepted Accounting Principles (GAAP).
                <br />
                <br />
                Let our team of accountants help your fundraising cost evaluation by adequately disclosing fundraising expenses while evaluating your organization's fundraising efforts.
              </Body>
            )
          },
          { title: "Internal Accounting Controls", 
            Icon: File, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Woodbourne Accounting has decades of experience helping nonprofits like yours create and maintain dynamic internal accounting controls to help you safeguard assets, mitigate risk, and improve financial reporting.
                <br />
                <br />
                Your nonprofit's internal controls ensure the integrity of your nonprofit's financial information and prevent fraud. Preventative controls stop errors and fraud from happening. Detective controls include reconciliations and daily cash counts to ensure that the cash matches the expected amount.
                <br />
                <br />
                Let Woodbourne Accounting help your nonprofit reduce the risk of fraud and keep financial reporting accurate by using internal audit controls to ensure compliance with laws and regulations.
              </Body>
            )
          },
          { title: "General Bookkeeping", 
            Icon: Scale, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Woodbourne Accounting can help your nonprofit maintain accurate financial records that comply with Generally Accepted Accounting Principles (GAAP) and help you keep your tax-exempt status with our general bookkeeping services.
                <br />
                <br />
                Trust us to:
                <br />
                <br />
                <Ulist>
                  <li>Record bank transfers and payments</li>
                  <li>Prepare bank reconciliations</li>
                  <li>Track income and expenses</li>
                  <li>Manage payroll</li>
                  <li>Keep the general ledger current</li>
                  <li>Maintain and organize receipts</li>
                </Ulist>
                <br />
                You can trust Woodbourne Accounting to stay up-to-date on income tax changes and filing requirements to keep your nonprofit in compliance. We will help you create and track invoices for donations, monitor money coming into your organization, and provide donors with proof of their gifts.
              </Body>
            )
          },
          { title: "Quarterly/Monthly Board Reports", 
            Icon: Ballot, 
            body: ({ openConsultationDialog }) => (
              <Body>
                Woodbourne Accounting can help your nonprofit fulfill its obligations and maintain public trust by keeping your organization financially healthy and possessing the resources needed to complete its mission and the organizational structure to be operationally sound.
                <br />
                <br />
                Let us prepare the following reports:
                <br />
                <br />
                <Ulist>
                  <li>Annual budget statements</li>
                  <li>Audited financial statements</li>
                  <li>Balance sheets</li>
                  <li>Cash flow forecasts</li>
                  <li>Cash flow statements</li>
                  <li>Income statements</li>
                  <li>Monthly financial statements</li>
                </Ulist>
                <br />
                We can help you identify critical nonprofit KPIs to improve donor retention and program efficiency while preventing cash runaway and addressing budget variances. With our help, you will identify the right metrics to make it easier for your nonprofit to track and monitor business objectives.
              </Body>
            )
          },
          // { title: "Donor/Stakeholder Inquiries", 
          //   Icon: File, 
          //   body: ({ openConsultationDialog }) => (
          //     <Body>
          //       At Woodbourne Accounting, we know how vital your stakeholders are to the success of your nonprofit. Stakeholders can include government entities, communities, individuals, and others that affect or are affected by your nonprofit's activities.
          //       <br />
          //       <br />
          //       Stakeholders' interests reflect a variety of perspectives. Most stakeholders are concerned about your nonprofit organization's mission and inquire about key factors influencing its success. At Woodbourne Accounting, we handle stakeholder inquiries using a strategic management approach.
          //     </Body>
          //   )
          // }
        ]}
        position="left"
      /> */}
    </Fragment>
  )
}

export default ServicesGroups;