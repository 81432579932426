
const formats = {
  'D/M/YYYY': date=>new Intl.DateTimeFormat('en-US').format(new Date(date)),
  'YYYYMMDD': str=>{
    const date = new Date(str)
    return date.getFullYear() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2)
  },
}
export const formatDate = (date, format='D/M/YYYY') => {
  return formats[format](date||new Date());
}
export const addDays = (date, days) => {
  const copy = new Date(date)
  copy.setDate(copy.getDate() + days)
  return copy
}
export default formatDate;