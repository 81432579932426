import { useLocation } from "react-router-dom";
import queryString from 'query-string';

export const useQueryParams = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const params = {}
  for (const [key, value] of searchParams) { 
    params[key] = value;
  }

  const setQueryParams = (change, prefix = "") => {
    const state = { ...queryString.parse(window.location.search), ...change };

    const dedupedState = Object.keys(state).reduce(function (previous, current) {
      if (state[current]) previous[current] = state[current];
      return previous;
    }, {});

    window.history.replaceState({}, document.title, `${window.location.pathname}?${queryString.stringify(dedupedState)}`)
  };
  return { params, setQueryParams };
}
export default useQueryParams;