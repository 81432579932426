import React, { useState } from 'react'
import styled, { css } from 'styled-components';
// import { API } from 'aws-amplify'
import { createContactUs } from '../../../graphql/mutations'
import useMutation from '../../../hooks/useMutation';

import Button from '../../../atoms/Button';
import _PhoneIcon from '../../../icons/Phone';
import _PrinterIcon from '../../../icons/Printer';
import _AtIcon from '../../../icons/At';
import _EmailIcon from '../../../icons/Email';

const iconStyle = css`
  font-size: 3em;
  color: #d5aa6d;
`;
const PhoneIcon = styled(_PhoneIcon)(iconStyle);
const PrinterIcon = styled(_PrinterIcon)(iconStyle);
const AtIcon = styled(_AtIcon)(iconStyle);
const EmailIcon = styled(_EmailIcon)(iconStyle);
// const PrinterIcon = () => (
//   <SvgIcon stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
//     <path d="M732 120c0-4.4-3.6-8-8-8H300c-4.4 0-8 3.6-8 8v148h440V120zm120 212H172c-44.2 0-80 35.8-80 80v328c0 17.7 14.3 32 32 32h168v132c0 4.4 3.6 8 8 8h424c4.4 0 8-3.6 8-8V772h168c17.7 0 32-14.3 32-32V412c0-44.2-35.8-80-80-80zM664 844H360V568h304v276zm164-360c0 4.4-3.6 8-8 8h-40c-4.4 0-8-3.6-8-8v-40c0-4.4 3.6-8 8-8h40c4.4 0 8 3.6 8 8v40z"></path>
//   </SvgIcon>
// )
// const AtIcon = () => (
//   <SvgIcon focusable="false" viewBox="0 0 24 24" aria-hidden="true">
//     <path fill="none" d="M0 0h24v24H0z"></path>
//     <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path>
//   </SvgIcon>
// )
// const EmailIcon = () => (
//   <SvgIcon focusable="false" viewBox="0 0 24 24" aria-hidden="true">
//     <path fill="none" d="M0 0h24v24H0z"></path>
//     <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
//   </SvgIcon>
// )

const Root = styled.div`
  width: 90%;
  margin: auto;
  padding: 6rem 0;
  ${p => p.theme.breakpoints.tablet} {
    padding: 4rem 0 2rem;
  }
`;
const Title = styled.h1`
  font-family: Futura, sans-serif;
  font-size: 2.5rem;
  margin: 0;
  text-align: center;
  color: #2C3038;
  font-weight: 500;
`;
const SubTitle = styled.h3`
  font-family: Lora;
  font-style: italic;
  margin: 0;
  font-weight: 400;
  text-align: center;
  color: #2C3038;
`;
const Content = styled.div`
  display: flex;
  margin: 1rem 0 0;
  ${p => p.theme.breakpoints.tablet} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
const ContactInfoRoot = styled.div`
  width: 40%;
  // margin: 1.5rem 0; 
  ${p => p.theme.breakpoints.tablet} {
    width: 75%;
  }
  ${p => p.theme.breakpoints.phone} {
    width: 90%;
  }
`;
const InfoRoot = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  word-break: break-word;
`;
const InfoText = styled.p`
  font-size: 1.5rem;
  margin: 0 0.75rem;
`;
const FormRoot = styled.form`
  width: 60%;
  text-align: end;
  ${p => p.theme.breakpoints.tablet} {
    margin: 1rem 0;
    width: 75%;
  }
  ${p => p.theme.breakpoints.phone} {
    width: 90%;
  }
`;
const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
`;
export const Label = styled.p`
  font-family: Futura, sans-serif;
  font-size: 1.2rem;
  margin: 0 2rem ${p=>p.marginBottom||0} 0;
  text-align-last: left;
`;
export const Input = styled.input`
  flex: 1;
  border: none;
  border-bottom: 1px solid #676767;
  width: 100%;
  font-family: Futura, sans-serif;
  font-size: 1rem;
  &:focus {
    outline-width: 0;
  }
`;
const SendButton = styled(Button)`
  margin: 2rem 0;
`;
const Info = ({ text, children }) => (
  <InfoRoot>
    {children}
    <InfoText>{text}</InfoText>
  </InfoRoot>
)
const ContactInfo = () => (
  <ContactInfoRoot>
    <Info text="Phone: 804-241-5187"><PhoneIcon/></Info> 
    <Info text="Fax: 888-631-0235"><PrinterIcon/></Info>
    <Info text="Email: info@WoodbourneAccounting.com"><AtIcon/></Info>
    <Info><EmailIcon/> <InfoText>PO Box 282<br/>Richmond, VA 23218</InfoText></Info>
  </ContactInfoRoot>
)
const initialValues = { name: "", email: "", message: "" };
const Form = () => {
  const [values, setValues] = useState(initialValues);
  const submit = useMutation(createContactUs)
  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }
  const handleSubmit = async event => {
    event.preventDefault();
    await submit(values, {
      successMessage: "Your contact request has been sent successfully! We will contact you as soon as possible."
    })
    setValues(initialValues);
  }

  return (
    <FormRoot onSubmit={handleSubmit}>
      <FormRow>
        <Label>Name:</Label>
        <Input aria-label="Name" name="name" onChange={handleChange} value={values.name}/>
      </FormRow>
      <FormRow>
        <Label>Email:</Label>
        <Input aria-label="Email" name="email" onChange={handleChange} value={values.email}/>
      </FormRow>
      <Label marginBottom="1rem">Message:</Label>
      <Input aria-label="Message" name="message" onChange={handleChange} value={values.message}/>
      <SendButton type="submit" gold>Send</SendButton>
    </FormRoot>
  )
}
export const ContactUs = () => {
  return (
    <Root>
      <Title>Contact Us</Title>
      <SubTitle>We’d love to hear from you!</SubTitle>
      <Content>
        <ContactInfo />
        <Form />
      </Content>
    </Root>
  )
}


export default ContactUs;