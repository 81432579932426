import React, { useState } from 'react'
import styled from 'styled-components/macro';
import ChevronDownIcon from '../../icons/ChevronDown';
import ChevronRightIcon from '../../icons/ChevronRight';
import FileDropper from '../../atoms/FileDropper';
import useStorage from './useStorage';

import { Filename, Filesize, tableGridStyle } from './atoms';
import File from './File';
import FolderActions, { ActionRow } from './FolderActions';


const FolderWrapper = styled.div`

`;
const FolderHeaderRow = styled.div`
  padding-left: .5em;
  ${tableGridStyle}
  &:hover {
    background-color: #f2e6d3;
  }
  &:hover ${ActionRow} {
    visibility: visible;
  }
`;
const FolderName = styled(Filename)`
  grid-column: span 2;
`;
const NoFiles = styled(Filesize)`
  // grid-column: span 3;
  color: grey;
`;
const Indented = styled.div`
  margin-left: 1em;
`;
export const Folder = ({ item, refetch: refetchParent, ...props  }) => {
  const { file, loaded, refetch, onUpload } = useStorage(item.id)
  const [expanded, setExpanded] = useState();
  const toggleExpanded = () => setExpanded(expanded=>!expanded);
  const handleUpload = (files) => {
    setExpanded(true);
    onUpload(files, item.id, item.creator, refetch)
  }
  // const handleMove = async (obj, dest) => {
  //   // console.log(`obj`, obj);
  //   // console.log(`dest`, dest);
  //   const variables = { input: { id: obj, parentID: dest.id } };
  //   console.log(`variables`, variables);
  //   const result = await API.graphql({
  //     query: updateFile,
  //     variables,
  //     authMode: 'AMAZON_COGNITO_USER_POOLS'
  //   })
  //   // const result = await updateFile({ parentID: dest.id }, {id:obj.id})
  //   console.log(`result`, result);
  //   // showMessage("File moved successfully.")
  // }
  return (
    <FolderWrapper data-id={item.id}>
      <FileDropper item={item} onDrop={handleUpload} /*onMove={handleMove}*/>
        <FolderHeaderRow onClick={()=>toggleExpanded()}>
          {expanded?<ChevronRightIcon/>:<ChevronDownIcon/>}
          <FolderName>{item.name}</FolderName>
          <NoFiles> {loaded&&file.children.length===0&&"empty"}</NoFiles>
          <FolderActions item={item} onUpload={handleUpload} refetch={refetch} refetchParent={refetchParent} {...props} actions={{ delete:1, new:1, upload:1 }} />
        </FolderHeaderRow>
        {expanded && (
          <Indented>
            {file.children.map((item, index)=>{
              if(item.type==="Folder") return <Folder key={item.id||index} item={item} refetch={refetch}/>
              return <File key={item.id||index} item={item} refetch={refetch}/>
            })}
          </Indented>
        )}
      </FileDropper>
    </FolderWrapper>
  )
}
export default Folder