import React, { useState, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify';
import styled from 'styled-components';
import getUrl from '../../lib/getUrl';
import useNotifications from '../../hooks/useNotifications';
import useUser from '../../hooks/useUser';
import { v4 as uuid } from 'uuid';
import Link from '../../atoms/Link';
import Button, { ButtonRow } from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import { Header3 } from '../../atoms/Text';
import { useNavigate } from "react-router-dom";
import useQueryParams from '../../hooks/useQueryParams'
const Wrapper = styled.div`
  padding: 4em;
`;
const Form = styled.form`
  max-width: 800px;
      margin: auto;
    max-width: 800px;
    padding: 3em;
`;
const initialValues = { 
  username: "", 
  password: "", 
};

export const SetPassword = () => {
  const [user, { refetch }] = useUser();
  const navigate = useNavigate();
  const showMessage = useNotifications()
  // const [user, setUser] = useState();
  const { params } = useQueryParams();
  // console.log(`params.redirect`, params.redirect);
  // const initialSignIn = async () => {
  //   const { username, password } = params;
  //   // console.log(`signing in with: `, { username, password });
  //   try {
  //     const user = await Auth.signIn(username, password);
  //     console.log(`user`, user);
  //     setUser(user)
  //   } catch (error) {
  //     console.log('error signing in', error);
  //     // showMessage(`Error: ${JSON.stringify(error)}`, 'error')
  //   }
  // }
  const handleChange = event => setValues({ ...values, [event.target.name]: event.target.value });
  // useEffect(()=>{ initialSignIn() }, [])
  const [values, setValues] = useState({
    username: params.username,
    password:"",
    passwordConfirmation:"",
  });
  const submit = async event => {
    event.preventDefault();
    console.log(`user`, user);
    if(!values.password) return showMessage("Please enter a password", 'error');
    // if(values.password.length<8) return showMessage("Please enter a longer password. (>8 characters)", 'error');

    try{
      const result = await Auth.changePassword(user, params.password, values.password)
      navigate(params.redirect||'/');
    } catch(err){
      console.log(`err:`, err.message);
      showMessage(err.message,'error')
    }
    // console.log(`result`, result);
    

  }
  return (
    <Wrapper>
      <Form onSubmit={submit}>
        <Header3>Before we start, let's secure your account with a strong password.</Header3>
        <TextField label="Email" disabled value={params.username} autocomplete="username" />
        <TextField label="Password" type="password" required name="password" onChange={handleChange} value={values.password} autocomplete="password"/>
        <TextField label="Password confirmation" type="password" required name="passwordConfirmation" onChange={handleChange} value={values.passwordConfirmation} autocomplete="password"/>
        <ButtonRow><Button type="submit" gold>Set Password</Button></ButtonRow>
        {/*<ButtonRow><Link gold to={getUrl('users','register')}>Or click here to sign up</Link></ButtonRow>*/}
      </Form>
    </Wrapper>
  )
}

export default SetPassword;