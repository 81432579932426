import styled from 'styled-components/macro'
import React from 'react';

const Wrapper = styled.div`
  min-height: 800px;
  max-width: 1600px;
  margin: auto;
  // margin: 90px auto 0;
  padding: 90px 0 0;
  font-family: Futura Now Text;
  @media only screen and (max-width: 55em) {
    // margin-top:80px;
  }
`;

export const Body = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default Body