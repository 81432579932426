import React from 'react';
import useClickAway from "../hooks/useClickAway";
import styled from 'styled-components/macro';

const Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background: rgba(0, 0, 0, .5);
`;
const DefaultContent = styled.div`
  position: fixed;
  z-index: 21;
  padding: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 4px 4px 9px 0 rgb(0 0 0 / 45%);
  width: 50vw;
  @media screen and (max-width: 650px) {
    padding: 2rem 2rem 0 2rem;
  }
  max-height: 80vh;
  overflow-y: scroll;
  ${p=>p.theme.scrollbar}
  ${p => p.theme.breakpoints.tablet} {
    width: 80vw;
  }
`;
/*
  *** Props ***
  opacity (Int 1-100): background opacity
  autoClose (Bool): close on click away
*/
export const Modal = ({ open, onClose, opacity=50, autoClose=true, children, Content=DefaultContent, contentProps, ...props }) => {
  const ref = useClickAway(onClose);
  if (!open) return null;
  return (
    <Root>
      <Content {...autoClose && { ref }} {...contentProps}>
        {children}
      </Content>
    </Root>
  )
};
export const ModalPaper = styled.div`
  width: 100%;
  max-width: 660px;
  padding: 1.5em;
  box-sizing: border-box;
  border-radius: 0.25em;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
`;

export default Modal;