import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

export const AccordionTray = styled.div`
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  transition: ${p=>p.transition||"max-height 1s"};
  max-height: ${p => p.isOpen ? p.maxHeight : "0"};
`;
export const SimpleAccordion = props => {
  const content = useRef(null);
  const scrollHeight = ((content.current && content.current.scrollHeight)||200) + "px";
  const [maxHeight, setMaxHeight] = useState(scrollHeight);
  useEffect(()=>setMaxHeight(scrollHeight), [scrollHeight, setMaxHeight]);
  return <AccordionTray {...props} maxHeight={maxHeight} ref={content} />
}
const AccordionWrapper = styled.div``;
export const AccordionButton = styled.button`
  background-color: inherit;
  border: none;
`;
export const Accordion = ({ Button, buttonContent, children, initialValue=false, className, parentIsOpen, parentSetIsOpen, ...props }) => {
  const [isOpen, setIsOpen] = useState(initialValue);
  const content = useRef(null);
  const handleToggle = (...args) => {
    if (typeof parentSetIsOpen !== "undefined") return parentSetIsOpen(!parentIsOpen)
    setIsOpen(!isOpen)
  }
  const maxHeight = ((content.current && content.current.scrollHeight)||200) + "px";
  if (!Button) {
    let content = typeof buttonContent === 'function' ? buttonContent({ isOpen, maxHeight }) : buttonContent;
    Button = () => <AccordionButton type="button" onClick={handleToggle}>{content}</AccordionButton>
  }
  const open = typeof parentIsOpen !== "undefined" ? parentIsOpen : isOpen;
  return (
    <AccordionWrapper className={className} >
      <Button onClick={handleToggle} isOpen={open} />
      <AccordionTray {...props} maxHeight={maxHeight} isOpen={open} ref={content}>
        {children}
      </AccordionTray>
    </AccordionWrapper>
  );
}
export default Accordion;