
export const toCSV = ({ transactions, vendors, vendorKeys }) => {
  let result = transactions.map(([date, type, ,expense, income, category, description, detail, , receipt, check, negative],i)=>{
    return [date, type, vendors]
  })
}
export const transforms = {
  default: (data, { from }) => {
    return data.map(data=>{
      const isExpense = data.Amount[0]==="-";
      // console.log(`isExpense`, isExpense);
      let expense, income;
      if(data.Amount[0]==="-"){
        expense = data.Amount;
        income = "";
      } else {
        expense = "";
        income = data.Amount;
      }
      return [
        data.Date,
        data['Transaction Type'],
        "",
        expense,
        income,
        "",
        data.Description,
        "",
        from,
        "",
        data['Check/Serial #'],
        "",
      ]
    })
  },
  chase: (data, { from }) => {
    return data.map(data=>{
      const isExpense = data.Amount[0]==="-";
      // console.log(`isExpense`, isExpense);
      let expense, income;
      const amount = data.Amount.replaceAll(/[$\(\),-]/g,"");
      if(isExpense){
        expense = amount;
        income = "";
      } else {
        expense = "";
        income = amount;
      }
      return [
        data['Posting Date'],
        data['Type'],
        "",
        expense,
        income,
        "",
        data.Description,
        "",
        from,
        "",
        data['Check or Slip #'],
        "",
      ]
    })
  },
  chaseCreditCard: (data, { from }) => {
    return data.map(data=>{
      // const isExpense = data.Amount[0]==="-";
      const isExpense = (data.Amount[0]!=="(")
      // console.log(`isExpense`, isExpense);
      const amount = data.Amount.replaceAll(/[$\(\),-]/g,"");
      let expense, income;
      if(isExpense){
        expense = amount
        income = "";
      } else {
        expense = "";
        income = amount
      }
      return [
        data['Date'],
        data['Transaction Type'],
        "",
        expense,
        income,
        "",
        data.Description,
        "",
        from,
        "",
        data['Check/Serial #'],
        "",
      ]
    })
  },
  truist: (data, { from }) => {
    const types={
      ATM: true,
      Check: true,
      Credit: true,
      Debit: true,
      Deposit: true,
      Fee: true,
      POS: true,
    }
    return data.map(data=>{
      // const isExpense = data.Amount[0]==="-";
      const isExpense = (data.Amount[0]==="(")===types[data['Transaction Type']]
      // console.log(`isExpense`, isExpense);
      const amount = data.Amount.replaceAll(/[$\(\),-]/g,"");
      let expense, income;
      if(isExpense){
        expense = amount
        income = "";
      } else {
        expense = "";
        income = amount
      }
      return [
        data['Date'],
        data['Transaction Type'],
        "",
        expense,
        income,
        "",
        data.Description,
        "",
        from,
        "",
        data['Check/Serial #'],
        "",
      ]
    })
  },
  navy: (data, { from }) => {
    return data.map(data=>{
      return [
        data['Date'],
        data['Type'],
        "",
        data.Debit,
        data.Credit,
        "",
        data.Description,
        "",
        from,
        "",
        data['No.'],
        "",
      ]
    })
  },
  cf:(data, { from }) => {
    return data.map(data=>{
      return [
        data['Date'],
        data['Type'],
        "",
        data.Debit,
        data.Credit,
        "",
        data.Description,
        "",
        from,
        "",
        data['ChkRef'],
        "",
      ]
    })
  },
}
export default transforms;