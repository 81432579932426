import React, { useState, useRef, Fragment } from 'react'
import styled from 'styled-components/macro';
import TextField from '../../atoms/TextField';
import Text from '../../atoms/Text';
import UploadButton from './UploadButton';
import DeleteIcon from '../../icons/Delete';
import IconButton from '../../atoms/IconButton';
import NewFolderIcon from '../../icons/NewFolder';
import Modal from '../../atoms/Modal';
import Button, { ButtonRow } from '../../atoms/Button';
import { API } from 'aws-amplify'
import useNotifications from '../../hooks/useNotifications';
import useUser from '../../hooks/useUser';
import { v4 as uuid } from 'uuid';
import { createFile, deleteFile } from '../../graphql/mutations'
import { listFiles } from '../../graphql/queries';

import _CheckIcon from '../../icons/Check';
const CheckIcon =styled(_CheckIcon)`
    cursor: pointer;
`;
// const IconButton = styled(_IconButton)`
//   // color: white;
// `;
export const ActionRow = styled.div`
  display: flex; 
  align-items: center;
  visibility: ${p=>p.$forceShow?'visible':'hidden'};
`;

export const FolderActions = ({ item, refetch, refetchParent, onUpload, onAddFolder, actions, alwaysShow }) => {
  const [open, setOpen] = useState();
  const [name, setName] = useState("");
  const ref = useRef();
  const [user] = useUser();
  // console.log(`user`, user);
  const userIsAdmin = ((user&&user.groups)||[]).includes("Admin");
  // console.log(`userIsAdmin`, userIsAdmin);
  const handleChange = e => setName(e.target.value);
  const handleAddFolder = async e => {
    e.preventDefault();
    setOpen();
    const file = { 
      id: uuid(),
      name, 
      type:"Folder", 
      key:"",
      parentID:item.id,
    }
    console.log("file: ",file);
    await API.graphql({
      query: createFile,
      variables: { input: file },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    showMessage("Folder created successfully",'success');
    // const file = await onAddFolder(name, item.id)
    setName("");
    refetch()
  }
  const showMessage = useNotifications();
  const deleteFolderRecursively = async folder => {
    console.log(`deleteFolderRecursively folder`, folder);
    const response = await API.graphql({
      query: listFiles,
      variables: { filter: { parentID: { eq: folder.id } } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    for (var i = response.data.listFiles.items.length - 1; i >= 0; i--) {
      await deleteFolderRecursively(response.data.listFiles.items[i])
    }
    // console.log(`response.data.listFiles.items`, response.data.listFiles.items);
    // item.children.map()
    await API.graphql({
      query: deleteFile,
      variables: { input: { id: folder.id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
  }
  const handleConfirmDelete = async (closeWarning, refetch) => {
    await deleteFolderRecursively(item)
    closeWarning()
    showMessage("Folder deleted successfully.",'success')
    refetch()
  }
  const handleDelete = e => {
    // console.log("DSDSD")
    e.preventDefault();
    e.stopPropagation();
    showMessage("Are you sure you want to delete this folder?", 'warning', { Actions: ({ onClose }) => (
      <CheckIcon
        key="confirm"
        id="confirm-button"
        onClick={() => handleConfirmDelete(onClose, refetchParent)}
      />
    )});  
  }
  if(!userIsAdmin) return null;
  return (
    <ActionRow $forceShow={alwaysShow}>
      {actions.upload&&<UploadButton onUpload={onUpload} />}
      {actions.new&&<IconButton Icon={NewFolderIcon} onClick={()=>setOpen(true)} />}
      {actions.delete&&<IconButton Icon={DeleteIcon} onClick={handleDelete}/>}
      {/*<IconButton Icon={LinkIcon} onClick={handleShare}/>*/}
      <Modal open={open} onClose={()=>setOpen()}>
        <form onSubmit={handleAddFolder}>
          <Text>Please enter a name for this new folder</Text>
          <TextField ref={ref} value={name} onChange={handleChange}/>
          <ButtonRow>
            <Button label="Create" type="submit"/>
            <Button label="Cancel" onClick={()=>setOpen()}/>
          </ButtonRow>
        </form>
      </Modal>
    </ActionRow>
  )
}
export default FolderActions