// import styled from 'styled-components';
import styled, { css } from 'styled-components'
import React from 'react';
import { Link } from 'react-router-dom';

export const buttonStyle = css`
    color: ${p=>p.$color ? "white" : 'black'};
    text-align: center;
    background-color: ${p=>p.$color ? p.$color : 'white'};
    &:hover {
      box-shadow: 4px 4px 9px 0 rgb(0 0 0 / 45%)
    }
    &:visited {
      ${p=>p.$color ? "white" : 'black'}
    }
    cursor: pointer;
    outline: 0;
    user-select: none;

    padding: 1.5em 2.5em;
    // min-width: 200px;
    // box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Futura Now Text, Futura, sans-serif;
    // line-height: 1.75;
    border-radius: 4px;
    // letter-spacing: 0.02857em;
    text-transform: uppercase;

    border: ${p=>p.$color ? "none" : '1px solid #b9b9b9'};
    text-decoration: none;
    font-weight: 400;
    font-size: 1rem;
    // margin: 4rem auto 0;
`;

export const StyledButton = styled.button`
  ${buttonStyle}
`;
export const getButtonTag = (props) => {
  if (typeof props.href === 'string') return 'a';
  if (typeof props.to === 'string') return Link;
  return 'button';
}
export const Button = ({ children, label, $color, gold, ...props }) => {
  const tag = getButtonTag(props);
  if(gold) $color="#d5aa6d";
  return <StyledButton as={tag} $color={$color} {...props}>{label}{children}</StyledButton>
}
export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 3rem auto;
`;
export default Button
