import Query from '../../atoms/Query';
import { EditInvoice } from './Edit';


const lastInvoiceNumberQuery = `
  query LastInvoiceNumber{
    invoicesByNumber(
      type: "Invoice"
      sortDirection: DESC
      limit: 1
    ) {
      items {
        id
        number
      }
      nextToken
    }
  }
`

export const New = props => <Query Component={EditInvoice} query={lastInvoiceNumberQuery} {...props}/>

export default New