import React from 'react'
import styled from 'styled-components/macro';
import { Header1 } from '../../../atoms/Text';
import Link from '../../../atoms/Link';
import Text from '../../../atoms/Text';
import { Ulist } from './ServicesGroups';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6rem 0;
  ${p => p.theme.breakpoints.tablet} {
    flex-direction: column;
  }
`;
const Header = styled(Header1)`
  margin: 0 0 .5rem;
  ${p => p.theme.breakpoints.tablet} {
    margin: 2rem 0 0;
    text-align: center;
  }
  ${p => p.theme.breakpoints.phone} {
    font-size: 1.5rem;
  }
`;
const MainImageWrapper = styled.div`
  width: 35%;
  ${p => p.theme.breakpoints.tablet} {
    width: 60%;
    margin: auto;
  }
  ${p => p.theme.breakpoints.phone} {
    width: 100%;
  }
`;
const MainImage = styled.img`
  width: 100%;
  height: 68%;
`;
const Signature = styled.img`
  margin: 0.4rem 0;
  width: 60%;
  height:30%;
`;
const Description = styled.div`
  width: 55%;
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${p => p.theme.breakpoints.tablet} {
    width: 60%;
    margin: auto;
  }
  ${p => p.theme.breakpoints.phone} {
    width: 80%;
  }
`;
const DescriptionText = styled.p`
  font-size: 1.1rem;
  // font-family: Lora;
  text-align: justify;
`;
export const Paragraph = styled(Text)`
  font-size: 1.1rem;
  margin: .5rem 0;
  text-align: justify;
`;
const SignatureText = styled.p`
  font-size: 1.3rem;  
  margin-top: 1em;
`;
const Subheading = styled.h2`
  font-size: 1.5rem;
  margin: 1rem 0;
  font-family: Lora, sans-serif;
  font-weight: 700;
  color: #2C3038;
`;
export const SignatureSection = () => {
  return (
    <Root id="about-us">
      <MainImageWrapper>
        <MainImage 
          src="/images/team.webp" 
          srcSet="/images/team-mobile.webp 477w, /images/team-mobile.webp 766w, /images/team.webp 990w" 
          sizes="(max-width: 478px) 30vw, (max-width: 767px) 27vw, (max-width: 991px) 25vw, 18vw"
          alt="people working together" 
          loading="lazy" 
        />
      </MainImageWrapper>
      <Description>
        <Header>We’re invested in helping you succeed</Header>
        <Paragraph>Since 1992, Woodbourne Accounting has been helping individuals, nonprofits, and small- and medium-sized businesses in Central Virginia and across the USA. Our experience has given us tools to share with you how to make realistic and tough business decisions about your company’s—and your personal—finances.</Paragraph>
        <Subheading>You Have Access to Us All Year</Subheading>
        <Paragraph>While tax season is our busiest time of year, we are available year-round to help you with life changes, like marriage, new investments, credit problems, the purchase of a home, or a medical emergency, that can <Link darkgold href="https://thecapitalist.com/5-major-life-changes-impact-taxes/">impact your end-of-the-year tax filing</Link>. Since our goal is to build long-term relationships, we focus on helping our clients plan for the future, not just address their immediate tax liabilities.</Paragraph>
        <Subheading>We Offer Business and Individual Tax Planning Services</Subheading>
        <Paragraph>Although our focus is preparing business tax returns, we are happy to work on individual taxes and will always review our client's previous year’s tax returns for free. We have a special knack for helping clients who do securities trading and investing minimize their tax liability and keep more of their earnings. We take care of the numbers and let you keep doing the things to make your life and business successful.</Paragraph>
        <Paragraph>Are you stressed because you could not file returns for the previous years and are worried about <Link darkgold href="https://www.cbsnews.com/news/tax-late-filing-penalty-interest-irs-extension/">fees and penalties</Link>? We understand how you feel and are committed to helping you catch up with your tax obligations with as little pain as possible.</Paragraph>
        <Paragraph>Whether you are an individual looking to save on taxes, a small business hoping to maximize deductions and minimize tax liabilities, or a landlord with several rental properties wanting to stay in compliance with the tax laws governing rental and real estate investment properties, we’ve got you covered.</Paragraph>
        <Paragraph>Our team will always do what is really in your best interests. Even with time-sensitive and complex situations, we do exhaustive research to ensure that you get every deduction, every refund, and every tax credit you are legally entitled to. And, since we file all returns electronically at no extra charge, you will get your refund faster.</Paragraph>
        <Paragraph>We love our clients, and our clients love what we do for them. Schedule a consultation with Woodbourne Accounting to see what you get when you work with accountants that help you plan for the future and make wise day-to-day financial decisions.!</Paragraph>
        <Signature 
          alt="signature" 
          loading="lazy" 
          src="/images/signature.webp" 
          srcSet="/images/signature-mobile.webp 477w, /images/signature-mobile.webp 766w, /images/signature.webp 990w"
          sizes="(max-width: 478px) 30vw, (max-width: 767px) 27vw, (max-width: 991px) 25vw, 18vw"
        />
        <SignatureText>Albert Rodgers - CEO</SignatureText>
      </Description>
    </Root>
  )
}

export default SignatureSection;