export const officialTypes = {
  emails:["","HOME","WORK"],
  addresses:["","HOME","WORK"],
  phones:["","HOME","WORK","FAX","CELL","PAGER"],
  related:["FRIEND","CO-WORKER","EMPLOYEE","EMPLOYER","COLLEAGUE","CO-RESIDENT","NEIGHBOR","CHILD","PARENT","SIBLING","SPOUSE","FAMILY","PARTNER","AGENT"],
}
export const typeOptions = [
    {label:"Individual", value:"individual"},
    {label:"Organization", value:"org"},
  ]
export const phoneTypeOptions = [
  // We can add any other types the client may want and they will work ok.
  // The uppercase values are all the "usefull" official phone types
  // Other values are custom
  {label:"Not Specified", value:""},
  {label:"Cell", value:"CELL"},
  {label:"Home", value:"HOME"},
  {label:"Work", value:"WORK"},
  {label:"Main", value:"Main"},
  {label:"Fax", value:"FAX"},
  {label:"Pager", value:"PAGER"},
  {label:"Custom", value:"Custom"},
];
export const emailTypeOptions = [
  {label:"Not Specified", value:""},
  {label:"Personal", value:"HOME"},
  {label:"Work", value:"WORK"},
  {label:"Custom", value:"Custom"},
]
export const addressTypeOptions = [
  {label:"Not Specified", value:""},
  {label:"Home", value:"HOME"},
  {label:"Work", value:"WORK"},
  {label:"Billing", value:"Billing"},
  {label:"PO Box", value:"PO Box"},
  {label:"Custom", value:"Custom"},
]
export const relatedTypeOptions = [
  {label:"Not Specified", value:""},
  {label:"Friend", value:"Friend"},
  {label:"Co-worker", value:"Co-worker"},
  {label:"Employer", value:"Employee"},
  {label:"Employee", value:"Employer"},
  {label:"Colleague", value:"Colleague"},
  {label:"Co-resident", value:"Co-resident"},
  {label:"Neighbor", value:"Neighbor"},
  {label:"Child", value:"Child"},
  {label:"Parent", value:"Parent"},
  {label:"Sibling", value:"Sibling"},
  {label:"Spouse", value:"Spouse"},
  {label:"Family", value:"Family"},
  {label:"Partner", value:"Partner"},
  {label:"Agent", value:"Agent"},
  {label:"Custom", value:"Custom"},
]
export const isCustom = (type, value) => officialTypes[type].indexOf((value||"").toUpperCase())===-1;