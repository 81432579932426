import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import styled from 'styled-components/macro';
import { Header2 } from './Text';
import useUser from '../hooks/useUser';

export const StyledAuthWall = styled.div`
  
`;

export const AuthWall = ({ group, showError, Component, ...props }) => {
  const [user, { loading }] = useUser();
  let navigate = useNavigate();
  const [path] = useState(window.location.pathname)
  useEffect(()=>{
    // debugger;
    if(!user&&!loading) {
      // console.log(`Navigating to ${`/login?redirect=${path}`}`)
      navigate(`/login?redirect=${path}`)
    }
  },[user, loading])
  // console.log(`AuthWall user`, user);
  
  const auth = user&&(user.groups.includes(group)||user.groups.includes('Admin'));
  if(auth) return <Component {...props}/>;
  if(user&&showError) return <Header2>You are not authorized to access this content</Header2>
}
export default AuthWall;