import React from 'react'
import styled from 'styled-components/macro';
import { API } from 'aws-amplify'
import { deleteFile } from '../../graphql/mutations'
import { Storage } from 'aws-amplify'

import { Filename, Filesize, Updated, FileRow } from './atoms';
import DeleteIcon from '../../icons/Delete';
import DownloadIcon from '../../icons/Download';
import IconButton from '../../atoms/IconButton';
import _FileIcon from '../../icons/File';
import useNotifications from '../../hooks/useNotifications';
import _CheckIcon from '../../icons/Check';
import { ActionRow } from './FolderActions'
const CheckIcon =styled(_CheckIcon)`
    cursor: pointer;
`;

const FileIcon = styled(_FileIcon)`
  color: #4B505D;
`;
export const File = ({ item, refetch, selection, deselect, select }) => {
  const showMessage = useNotifications();
  const handleConfirmDelete = async (closeWarning, refetch) => {
    await Storage.remove(item.key);
    await API.graphql({
      query: deleteFile,
      variables: { input: { id: item.id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    closeWarning()
    showMessage("File deleted successfully.",'success')
    refetch()
  }
  const handleDelete = e => {
    e.preventDefault();
    e.stopPropagation();
    showMessage("Are you sure you want to delete this file?", 'warning', { Actions: ({ onClose }) => (
      <CheckIcon
        key="confirm"
        id="confirm-button"
        onClick={() => handleConfirmDelete(onClose, refetch)}
      />
    )});  
  }

  const handleDownload = async () => {
    const signedURL = await Storage.get(item.key, { contentDisposition: 'attachment; filename ="' + item.name + '"' }); // get key from Storage.list
    const link = document.createElement('a');
    link.setAttribute('href', signedURL);
    link.setAttribute('rel', 'noreferrer');
    link.click();
  }
  // const handleDragStart = e => e.dataTransfer.setData("text/plain", item.id);
  return (
    // <FileRow $selected={selected} onClick={handleSelect} draggable onDragStart={handleDragStart}>
    <FileRow>
      <FileIcon/>
      <Filename>{item.name}</Filename>
      <Filesize>{item.size}</Filesize>
      <Updated>{item.updatedOn}</Updated>
      <ActionRow>
        <IconButton Icon={DownloadIcon} onClick={handleDownload}/>
        <IconButton Icon={DeleteIcon} onClick={handleDelete}/>
        </ActionRow>
    </FileRow>
  )
// if(item.type === "Folder") return <Folder onClick={()=>setFolderId(item.id)}>{item.name}</Folder>
// return <File>{item.name}</File>
}

export default File