import React from 'react'
import styled from 'styled-components';
import Query from '../../atoms/Query';
import Card from './Card';
import getUrl from '../../lib/getUrl';
import { listPosts } from '../../graphql/queries';
import useUser from '../../hooks/useUser';
import { FabGroup, AddFab } from '../../atoms/Fab';

const Wrapper = styled.div`
  padding: 4em;
`;
export const List = ({ listPosts={items:[]} }) => {
  const [user] = useUser()
  const posts = listPosts.items;
  return (
    <Wrapper>
      {posts.map(post=><Card key={post.id}{...post} />)}
      {user&&<FabGroup right><AddFab to={getUrl('post','new')}/></FabGroup>}
    </Wrapper>
  )
}
const BlogWithData = props => <Query Component={List} query={listPosts} {...props}/>

export default BlogWithData;