export const toCents = value => {
  // console.log('value',value);
  
  let result = (value||"").toString().replace(/[$,]/g, "")
  result= parseFloat(result)*100
  return result;
}


const formatting_options = {
   style: 'currency',
   currency: 'USD',
   minimumFractionDigits: 2,
}
const dollarString = new Intl.NumberFormat( 'EN-us', formatting_options );  
export const formatCents = cents => dollarString.format(cents/100)
export const formatDollars = dollars => dollarString.format(dollars)