import styled from 'styled-components/macro';
// import {useField} from '../hooks/useForm';

export const Wrapper = styled.div`
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
  flex: 1 0 200px;
`;
export const SelectWrapper = styled.div`
  align-items: center;
  position: relative;
  display: flex;
`;

export const Arrow = styled.svg`
  // position: absolute;
  // top: 50%;
  // right:0;
  pointer-events: none;
  position: absolute;
  right: 0.25em;
  bottom: 0.5em;
`;
export const Input = styled.select`
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  border-radius: 0;
  line-height: 1.42857143;
  color: #333333;
  width: 100%;
  // height: 3.5em;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-style: none none solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 ${p => p.hasError ? p.theme.colors.red : 'rgb(204, 204, 204)'};
  transition: border-color .5s ease-in-out;
  background-color: transparent;
  background-image: none;
  background-size: auto;
  background-repeat: repeat;
  font-size: 16px;
  grid-area: select;
  &:focus {
    outline: none;
    border-bottom-color: ${p=>p.theme.colors.red};
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Option = styled.option`

`;
export const Label = styled.label`
  margin-top: 1em;
  font-family: Arimo, sans-serif;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
`;
export const Error = styled.p`
  color: ${p=>p.theme.colors.red};
  font-size: .8rem;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;

`;
export const Select = ({ label, id, required, className, options, name, error, touched, onChange, value, ...props }) => {

  const hasError = error && touched;
  // console.log(`typeof options: ${JSON.stringify(typeof options, null, 2)}`);

  if(typeof options!=="object") throw `No options passed to Select ${label}: ${name}`
  return (
    <Wrapper className={className}>
      {label&&<Label htmlFor={id} >{label}{required && " *"}</Label>}
      <SelectWrapper>
        <Input id={id} value={value||""} hasError={hasError} name={name} onChange={onChange} {...props}>
        {!value&&required&&<option hidden disabled value=""></option>}
        {options.map(opt=>{
          const value = opt.value===undefined? opt.label:opt.value
          return (
            <Option key={opt.key || opt.value || opt.label} value={value}>{opt.label}</Option>
          );
        })}
      </Input>
      <Arrow height="12px" width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 4">
        <polygon id="Path" points="0 1 4 1 2 3"></polygon>
      </Arrow>
      </SelectWrapper>
      {hasError && <Error>{error}</Error>}
    </Wrapper>
  )
}
export default Select
