import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom';

export const getLinkTag = (props) => {
  if (props.href) return 'a';
  if (props.to) return ReactRouterLink;
  return 'button';
}

export const StyledLink = styled(ReactRouterLink)`
  text-decoration: none;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  // font-size: 1rem;
  color: ${p=>p.$color||'#9c6416'};
  &:visited {
    color: ${p=>p.$color||'#9c6416'};
    text-decoration: none;
  }
  &:link {
    text-decoration: none;
  }
`;

export const Link = ({ gold, color, darkgold, ...props}) => {
  if(gold) color='#d5aa6d';
  if(darkgold) color='#9c6416'
  const tag = getLinkTag(props);
  return <StyledLink {...props} as={tag} $color={color}/>
}
export default Link