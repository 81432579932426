import React from 'react'
import { API } from 'aws-amplify'
import useNotifications from '../hooks/useNotifications';
import CheckIcon from '../icons/Check';

export const useMutation = (mutation, options) => {
  let { confirmMessage } = options||{};
  // console.log(`options`, options);
  const showMessage = useNotifications();
  const confirmation = (variables, options) => {
    showMessage(confirmMessage, 'warning', { Actions: ({ close }) => (
      <CheckIcon
        key="confirm"
        id="confirm-button"
        onClick={() => execute(variables, { closeNotification: close, ...options })}
      />
    )});  
  }
  const execute = async (variables, optionsOverride) => {
    const { closeNotification, after, refetch, successMessage } = { ...options, ...optionsOverride };
    if(closeNotification) closeNotification();
    // console.log(`variables`, variables);
    // console.log(`successMessage`, successMessage);
    try {
      const result = await API.graphql({
        query: mutation,
        variables: { input: variables },
        // authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      // console.log(`result: ${JSON.stringify(result)}`);
      // console.log(`result`, result);
      if (refetch) refetch()
      // console.log(`result: ${JSON.stringify(result)}`);
      if(successMessage) showMessage(successMessage, "success");
      if (after) after()
      return result;
    } catch (error) {
      console.log(`error`, error);
      showMessage(error.errors[0].message, 'error');
    }
  }
  return confirmMessage ? confirmation : execute;
}
export default useMutation;
