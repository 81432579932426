import React, { useState } from 'react'
import { Auth, Hub } from 'aws-amplify';
import styled from 'styled-components';
import getUrl from '../../lib/getUrl';
import useNotifications from '../../hooks/useNotifications';
import useUser from '../../hooks/useUser';
import { v4 as uuid } from 'uuid';
import Link from '../../atoms/Link';
import Button, { ButtonRow } from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import { useNavigate } from "react-router-dom";
import useQueryParams from '../../hooks/useQueryParams'
const Wrapper = styled.div`
  padding: 4em;
`;
const Form = styled.form`
  max-width: 800px;
      margin: auto;
    max-width: 800px;
    padding: 3em;
`;
const initialValues = { 
  username: "", 
  password: "", 
};

export const Login = () => {
  const [user, { refetch }] = useUser();
  const { params } = useQueryParams();
  // console.log(`params.redirect`, params.redirect);
  const navigate = useNavigate();
  const [values, setValues] = useState(params);

  // const saveValues = newValues => setValues({ ...values, ...newValues });
  const showMessage = useNotifications();
  const handleChange = event => setValues({ ...values, [event.target.name]: event.target.value });
  const login = async event => {
    event.preventDefault();
    const { username, password } = values;
    // console.log(`signing in with: `, { username, password });
    try {
      await Auth.signIn(username, password);
      // console.log(`login user`, user);
      showMessage(`Welcome, ${username}`, 'success')
      await refetch()
      navigate(params.redirect||'/');
    } catch (error) {
      console.log('error signing in', error);
      // showMessage(`Error: ${JSON.stringify(error)}`, 'error')
      showMessage(error.message,'error')
    }
}
  return (
    <Wrapper>
      <Form onSubmit={login}>
        <TextField label="Email" required name="username" onChange={handleChange} value={values.username} autocomplete="username" />
        <TextField label="Password" type="password" required name="password" onChange={handleChange} value={values.password} autocomplete="password"/>
        <ButtonRow><Button type="submit" gold>Login</Button></ButtonRow>
        {/*<ButtonRow><Link gold to={getUrl('users','register')}>Or click here to sign up</Link></ButtonRow>*/}
      </Form>
    </Wrapper>
  )
}

export default Login;