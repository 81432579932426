import React from "react";
// import PropTypes from "prop-types";
import FormSelect from "./Select";
import TextField from "./TextField";

export const StateSelect = ({ countryCode, label = "State or Province:", ...props }) => {
  if (!["US", "CA", "GB"].includes(countryCode)) return <TextField {...props} label={label} />;
  return <FormSelect options={stateOptions[countryCode]} label={label} {...props} />;
}

export const stateOptions = {
  CA: [
    { label: '', value: '' },
    { label: "Alberta", value: "AB" },
    { label: "British Columbia", value: "BC" },
    { label: "Manitoba", value: "MB" },
    { label: "New Brunswick", value: "NB" },
    { label: "Newfoundland and Labrador", value: "NL" },
    { label: "Northwest Territories", value: "NT" },
    { label: "Nova Scotia", value: "NS" },
    { label: "Nunavut", value: "NU" },
    { label: "Ontario", value: "ON" },
    { label: "Prince Edward Island", value: "PE" },
    { label: "Quebec", value: "QC" },
    { label: "Saskatchewan", value: "SK" },
    { label: "Yukon", value: "YT" }
  ],
  US: [
    { label: '', value: '' },
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" }
  ],
  GB: [
    { label: '', value: '' },
    { label: "Aberdeenshire", value: "ABD" },
    { label: "Alderney", value: "ALD" },
    { label: "Anglesey (Isle of)", value: "AGY" },
    { label: "Angus", value: "ANS" },
    { label: "Argyll and Bute", value: "AGB" },
    { label: "Ayrshire", value: "AYR" },
    { label: "Banffshire", value: "BAN" },
    { label: "Bedfordshire", value: "BDF" },
    { label: "Berkshire", value: "BRK" },
    { label: "Berwickshire", value: "BEW" },
    { label: "Blaenau Gwent", value: "BGW" },
    { label: "Borders", value: "BOR" },
    { label: "Bridgend", value: "BGE" },
    { label: "Bristol", value: "BST" },
    { label: "Buckinghamshire", value: "BKM" },
    { label: "Caerphilly", value: "CAY" },
    { label: "Caithness", value: "CAI" },
    { label: "Cambridgeshire", value: "CAM" },
    { label: "Cardiff", value: "CRF" },
    { label: "Carmarthenshire", value: "CMN" },
    { label: "Central Scotland", value: "CEN" },
    { label: "Ceredigion", value: "CGN" },
    { label: "Channel Islands", value: "CHI" },
    { label: "Cheshire", value: "CHS" },
    { label: "Clackmannanshire", value: "CLK" },
    { label: "Clwyd", value: "CWD" },
    { label: "Conwy", value: "CWY" },
    { label: "Cornwall", value: "CON" },
    { label: "County Antrim", value: "ANT" },
    { label: "County Armagh", value: "ARM" },
    { label: "County Down", value: "DOW" },
    { label: "County Durham", value: "DUR" },
    { label: "County Tyrone", value: "TYR" },
    { label: "Cumbria", value: "CMA" },
    { label: "Denbighshire", value: "DEN" },
    { label: "Derbyshire", value: "DER" },
    { label: "Devon", value: "DEV" },
    { label: "Dorset", value: "DOR" },
    { label: "Dumfries & Galloway", value: "DGY" },
    { label: "Dunbartonshire", value: "DNB" },
    { label: "Dyfed", value: "DYD" },
    { label: "East Lothian", value: "ELN" },
    { label: "East Riding of Yorkshire", value: "ERY" },
    { label: "East Sussex", value: "ESX" },
    { label: "Essex", value: "ESS" },
    { label: "Fermanagh", value: "FER" },
    { label: "Fife", value: "FIF" },
    { label: "Flintshire", value: "FLN" },
    { label: "Glamorgan", value: "GLA" },
    { label: "Gloucestershire", value: "GLS" },
    { label: "Grampian", value: "GMP" },
    { label: "Guernsey", value: "GSY" },
    { label: "Gwent", value: "GNT" },
    { label: "Gwynedd", value: "GWN" },
    { label: "Hampshire", value: "HAM" },
    { label: "Herefordshire", value: "HEF" },
    { label: "Hertfordshire", value: "HRT" },
    { label: "Highlands", value: "HLD" },
    { label: "Inverness-shire", value: "INV" },
    { label: "Isle of Man", value: "IOM" },
    { label: "Isle of Wight", value: "IOW" },
    { label: "Jersey", value: "JSY" },
    { label: "Kent", value: "KEN" },
    { label: "Kincardineshire", value: "KCD" },
    { label: "Kinross-shire", value: "KRS" },
    { label: "Kirkcudbrightshire", value: "KKD" },
    { label: "Lanarkshire", value: "LKS" },
    { label: "Lancashire", value: "LAN" },
    { label: "Leicestershire", value: "LEI" },
    { label: "Lincolnshire", value: "LIN" },
    { label: "London", value: "LND" },
    { label: "Londonderry", value: "LDY" },
    { label: "Manchester (Greater)", value: "GTM" },
    { label: "Merseyside", value: "MSY" },
    { label: "Merthyr Tydfil", value: "MTY" },
    { label: "Middlesex", value: "MDX" },
    { label: "Midlothian", value: "MLN" },
    { label: "Monmouthshire", value: "MON" },
    { label: "Moray", value: "MOR" },
    { label: "Nairnshire", value: "NAI" },
    { label: "Neath Port Talbot", value: "NTL" },
    { label: "Newport", value: "NWP" },
    { label: "Norfolk", value: "NFK" },
    { label: "North Yorkshire", value: "NYK" },
    { label: "Northamptonshire", value: "NTH" },
    { label: "Northumberland", value: "NBL" },
    { label: "Nottinghamshire", value: "NTT" },
    { label: "Orkney", value: "OKI" },
    { label: "Oxfordshire", value: "OXF" },
    { label: "Peeblesshire", value: "PEE" },
    { label: "Pembrokeshire", value: "PEM" },
    { label: "Perthshire", value: "PER" },
    { label: "Powys", value: "POW" },
    { label: "Renfrewshire", value: "RFW" },
    { label: "Rhondda Cynon Taff", value: "RCT" },
    { label: "Ross and Cromarty", value: "ROC" },
    { label: "Roxburghshire", value: "ROX" },
    { label: "Selkirkshire", value: "SEL" },
    { label: "Shetland", value: "SHI" },
    { label: "Shropshire", value: "SHR" },
    { label: "Somerset", value: "SOM" },
    { label: "South Yorkshire", value: "SYK" },
    { label: "Staffordshire", value: "STS" },
    { label: "Stirlingshire", value: "STI" },
    { label: "Strathclyde", value: "STD" },
    { label: "Suffolk", value: "SFK" },
    { label: "Surrey", value: "SRY" },
    { label: "Sutherland", value: "SUT" },
    { label: "Swansea", value: "SWA" },
    { label: "Tayside", value: "TAY" },
    { label: "Torfaen", value: "TOF" },
    { label: "Tyne & Wear", value: "TWR" },
    { label: "Warwickshire", value: "WAR" },
    { label: "West Lothian", value: "WLN" },
    { label: "West Midlands", value: "WMD" },
    { label: "West Sussex", value: "SXW" },
    { label: "West Yorkshire", value: "WYK" },
    { label: "Western Isles", value: "WIS" },
    { label: "Wigtownshire", value: "WIG" },
    { label: "Wiltshire", value: "WIL" },
    { label: "Worcestershire", value: "WOR" },
    { label: "Wrexham", value: "WRX" }
  ]
};
export default StateSelect;
