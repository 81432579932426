import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components/macro';
import { v4 as uuid } from 'uuid';
import getUrl from '../../lib/getUrl';
import { Header1, Header3 } from '../../atoms/Text';
import { useParams, useNavigate } from "react-router-dom";
// import useUser from '../../hooks/useUser';
// import { diff } from 'deep-object-diff';
// import vCardsJS from 'vcards-js'

// import Button, { ButtonRow } from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import IconButton from '../../atoms/IconButton';
import AddIcon from '../../icons/Add';
import CloseIcon from '../../icons/Close';
import DollarSignIcon from '../../icons/DollarSign';
import DownloadIcon from '../../icons/Download';
import Select from '../../atoms/Select';
import CountrySelect, {countryOptions} from '../../atoms/CountrySelect';
import StateSelect, {stateOptions} from '../../atoms/StateSelect';
import GenderSelect from '../../atoms/GenderSelect';

import Row from '../../atoms/Row';
import useMutation from '../../hooks/useMutation';
import useForm from '../../hooks/useForm';
// import { getContactQuery, createContactMutation, updateContactMutation, deleteContactMutation } from './queries'
import { getContact } from '../../graphql/queries';
import { createContact as createContactMutation, updateContact as updateContactMutation, deleteContact as deleteContactMutation } from '../../graphql/mutations';
import { FabGroup, BackFab, SaveFab, CancelFab, DeleteFab, Fab } from '../../atoms/Fab';
import Query from '../../atoms/Query';
import getDisplayName from '../../lib/getDisplayName';
import { List as InvoiceList } from '../Invoices/List';
import { toVCard } from './vCard'
import download from 'downloadjs'
import { QRCodeSVG } from 'qrcode.react';
import slugify from '../../lib/slugify'
import Avatar from '../../atoms/Avatar';
import { typeOptions, phoneTypeOptions, emailTypeOptions, addressTypeOptions, relatedTypeOptions, isCustom } from './lib';
const defaultValues = { 
  firstName:"", 
  lastName:"", 
  kind:"individual", 
  lang:"EN",
  phones:[
    {type:"HOME", number:"", pref:1}
  ], 
  addresses:[
    { type:"Billing", street:"", city:"", stateCode:"", postalCode:"", countryCode:"US", pref:1 }
  ],
  emails:[
    {type:"HOME", address:"", pref:1}
  ],
  related:[],
}
// const Code = styled.pre`
//   width:100%;
// `;
const HeadingRow = styled(Row)`
  // align-items: center;
  position: fixed;
  top: 26px;
  padding-top: 100px;
  padding-bottom: 2em;
  left: 0;
  right: 0;
  z-index:1;
  background-color: white;
`;
const HeaderContainer = styled.div`
  width: 1400px;
  padding: 0 4rem;
  margin: auto;
`;
const Root = styled.div`
  margin-top: 4em;
  padding: 4rem;
  max-width: 1400px;
  margin: auto;
`;
const SectionHeader = styled(Header3)`
  margin-top: 1em;
  text-align: left;
`;
const DisplayName = styled(Header1)`
  flex: 1;
  text-align: left;
  margin-bottom: 0;
`;
const Form = styled.form`
  margin-top: 100px;
`;

const blacklistedShallowClone = (obj={}, blacklist) => {
  return Object.keys(obj).reduce((acc, key)=>{
    if(!blacklist.includes(key)) acc[key] = obj[key];
    return acc;
  },{})
}

const Data = styled.div`
`;
export const EditContact = ({ getContact: contact, updateCache, refetch }) => {
  const fieldBlacklist = ['invoices', 'createdAt','updatedAt','accounts']
  const initialValues = { 
    ...defaultValues, 
    ...blacklistedShallowClone(contact, fieldBlacklist),
  };
  const getOptionLabel = (options, value) => {
    for (var i = options.length - 1; i >= 0; i--) {
      if(options[i].value===value) return options[i].label;
    }
  }
  const onSubmit = async values => {
    const addresses = values.addresses.map(addr=>({
      ...addr,
      state: getOptionLabel(stateOptions[addr.countryCode], addr.stateCode),
      country: getOptionLabel(countryOptions, addr.countryCode),
    }))
    if(!values.id) {
      const result = await createContact({
        ...values,
        addresses,
        id: uuid(),
      })
      // console.log(`result`, result);
      updateCache({ getContact: result.data.createContact })
      navigate(getUrl('contacts','edit',result.data.createContact.id), { replace: true })
    }
    else {
      const result = await updateContact({

        ...values,
        addresses,
      })  
      updateCache({ getContact: result.data.updateContact })
    }
  }
  const { values, handleChange, reset, handleSubmit, dirty, setValues } = useForm({ initialValues, onSubmit, enableReinitialize:true })
  const displayName = getDisplayName(values) 
  const navigate = useNavigate();
  const createContact = useMutation(createContactMutation, {
    successMessage: "Contact created successfully!"
  })
  const updateContact = useMutation(updateContactMutation, {
    successMessage: "Contact updated successfully!"
  })
  const trash = useMutation(deleteContactMutation, {
    successMessage: "Contact deleted successfully!",
    confirmMessage: "Are you sure you want to delete this client?"
  })
  const handleDelete = async () => {
    await trash({ id: contact.id, _version: contact._version })
    navigate(getUrl('contacts','list'))
  }
  const handleDownload = () =>{
    download(vCard, slugify(displayName)+".vcf", "text/plain");
  }
  
  const vCard = toVCard(contact);
  const handleAddEmail = event => {
    event.preventDefault();
    handleChange({ target:{name:`emails[${values.emails.length}].type`, value:"HOME"}})
  }
  const handleRemoveEmail = (e,idx) => {
    e.preventDefault()
    const emails = [...values.emails.slice(0,idx), ...values.emails.slice(idx+1)]
    setValues({ ...values, emails });
  }
  const handleAddPhone = event => {
    event.preventDefault();
    handleChange({ target:{name:`phones[${values.phones.length}].type`, value:"HOME"}})
  }
  const handleRemovePhone = (e,idx) => {
    e.preventDefault()
    const phones = [...values.phones.slice(0,idx), ...values.phones.slice(idx+1)]
    setValues({ ...values, phones });
  }
  const handleAddAddress = event => {
    event.preventDefault();
    handleChange({ target:{name:`addresses[${values.addresses.length}].type`, value:"HOME"}})
  }
  const handleRemoveAddress = (e,idx) => {
    e.preventDefault()
    const addresses = [...values.addresses.slice(0,idx), ...values.addresses.slice(idx+1)]
    setValues({ ...values, addresses });
  }
  const handleAddRelationship = event => {
    event.preventDefault()
    handleChange({ target:{name:`related[${(values.related||[]).length}].type`, value:"FRIEND"}})
  }
  const handleRemoveRelationship = (e,idx) => {
    e.preventDefault()
    const related = [...values.related.slice(0,idx), ...values.related.slice(idx+1)]
    setValues({ ...values, related });
  }
  const handleChangeType = (event, type) => {
    if(!isCustom(type,event.target.value)) {
      event.target.value=(event.target.value).toUpperCase();
    }
    handleChange(event)
  }
  const name = values.kind==="individual" ? `${values.lastName}, ${values.firstName}`:values.org;
  // const avatar = contact.photo||contact.logo;
  
  return (
    <Root>
      <HeadingRow>
        <HeaderContainer>
          {contact&&contact.photo&&<Avatar $size={150} src={contact.photo}/>}
          <div>
            <DisplayName>{displayName}</DisplayName>
            {contact&&contact.emails&&contact.emails.length&&<Data>{contact.emails[0].address}</Data>}
            {contact&&contact.phones&&contact.phones.length&&<Data>{contact.phones[0].number}</Data>}
          </div>
          {contact&&contact.logo&&<Avatar $size={150} src={contact.logo}/>}
        </HeaderContainer>
      </HeadingRow>
      <Form onSubmit={handleSubmit}>
        <Row>
          {values.kind==="individual" ? (
            <Fragment>
              <TextField label="First" required name="firstName" onChange={handleChange} value={values.firstName} />
              <TextField label="Middle" name="middleName" onChange={handleChange} value={values.middleName} />
              <TextField label="Last" required name="lastName" onChange={handleChange} value={values.lastName} />
            </Fragment>
          ):(
            <Fragment>
              <TextField label="Company" required name="org" onChange={handleChange} value={values.org} />
              <TextField label="Logo" name="logo" onChange={handleChange} value={values.logo} />
            </Fragment>
          )}
          <Select label="Type" name="kind" onChange={handleChange} value={values.kind} options={typeOptions}/>
        </Row>
        <Row>
          <TextField label="Prefix" name="prefix" onChange={handleChange} value={values.prefix} />
          <TextField label="Suffix" name="suffix" onChange={handleChange} value={values.suffix} />
          <TextField label="Nicknames" name="nickname" onChange={handleChange} value={values.nickname} />
          <TextField label="Title" name="title" onChange={handleChange} value={values.title} />
        </Row>
        <SectionHeader>Phone numbers <IconButton Icon={AddIcon} onClick={handleAddPhone}/></SectionHeader>
        {(values.phones||[]).map((phone, index)=>(
          <Row>
            {isCustom('phones',values.phones[index].type)?(
              <TextField label="Type" name={`phones.${index}.type`} onChange={e=>handleChangeType(e,'phones')} value={values.phones[index].type}/>
            ):(
              <Select label="Type" name={`phones.${index}.type`} onChange={handleChange} value={values.phones[index].type} options={phoneTypeOptions}/>
            )}
            <TextField label="Number" name={`phones.${index}.number`} onChange={handleChange} value={values.phones[index].number} />
            <IconButton Icon={CloseIcon} onClick={e=>handleRemovePhone(e,index)}/>
          </Row>
        ))}
        <SectionHeader>Email <IconButton Icon={AddIcon} onClick={handleAddEmail}/></SectionHeader>
        {(values.emails||[]).map((email, index)=>(
          <Row key={index}>
            {isCustom('emails',values.emails[index].type)?(
              <TextField label="Type" name={`emails.${index}.type`} onChange={e=>handleChangeType(e,'emails')} value={values.emails[index].type}/>
            ):(
              <Select label="Type" name={`emails.${index}.type`} onChange={handleChange} value={values.emails[index].type} options={emailTypeOptions}/>
            )}
            <TextField label="Address" name={`emails.${index}.address`} onChange={handleChange} value={values.emails[index].address} />
            <IconButton Icon={CloseIcon} onClick={e=>handleRemoveEmail(e,index)}/>
          </Row>
        ))}
        <SectionHeader>Related <IconButton Icon={AddIcon} onClick={handleAddRelationship}/></SectionHeader>
        {(values.related||[]).map((phone, index)=>(
          <Row key={index}>
            {isCustom('related',values.related[index].type)?(
              <TextField label="Type" name={`related.${index}.type`} onChange={e=>handleChangeType(e,'related')} value={values.related[index].type}/>
            ):(
              <Select label="Type" name={`related.${index}.type`} onChange={handleChange} value={values.related[index].type} options={relatedTypeOptions}/>
            )}
            {/*<Select label="Type" name={`related.${index}.type`} onChange={handleChange} value={values.related[index].type} options={relatedTypeOptions}/>*/}
            <TextField label="Contact" name={`related.${index}.value`} onChange={handleChange} value={values.related[index].value} />
            <IconButton Icon={CloseIcon} onClick={e=>handleRemoveRelationship(e,index)}/>
          </Row>
        ))}
        <SectionHeader>Addresses <IconButton Icon={AddIcon} onClick={handleAddAddress}/></SectionHeader>
        {(values.addresses||[]).map((phone, index)=>(
          <Row key={index}>
            {isCustom('addresses',values.addresses[index].type)?(
              <TextField label="Type" name={`addresses.${index}.type`} onChange={e=>handleChangeType(e,'addresses')} value={values.addresses[index].type}/>
            ):(
              <Select label="Type" name={`addresses.${index}.type`} onChange={handleChange} value={values.addresses[index].type} options={addressTypeOptions}/>
            )}
            <TextField label="Street" name={`addresses.${index}.street`} onChange={handleChange} value={values.addresses[index].street} />
            <TextField label="City" name={`addresses.${index}.city`} onChange={handleChange} value={values.addresses[index].city} />
            <StateSelect label="State" name={`addresses.${index}.stateCode`} onChange={handleChange} value={values.addresses[index].stateCode} countryCode={values.addresses[index].countryCode}/>
            <TextField label="Postal Code" name={`addresses.${index}.postalCode`} onChange={handleChange} value={values.addresses[index].postalCode} />
            <CountrySelect label="Country" name={`addresses.${index}.countryCode`} onChange={handleChange} value={values.addresses[index].countryCode} />
            <IconButton Icon={CloseIcon} onClick={e=>handleRemoveAddress(e,index)}/>
          </Row>
        ))}
        <Row>
          <TextField label="Photo Url" name="photo" onChange={handleChange} value={values.photo} />
          <TextField label="Logo Url" name="logo" onChange={handleChange} value={values.logo} />
          <TextField label="Birthday" name="birthday" onChange={handleChange} value={values.birthday} />
          <TextField label="Anniversary" name="anniversary" onChange={handleChange} value={values.anniversary} />
          <GenderSelect label="Gender" name="gender" onChange={handleChange} value={values.gender} />
          <TextField label="Language" name="lang" onChange={handleChange} value={values.lang} />
          {/*<TextField label="Time zone" name="tz" onChange={handleChange} value={values.tz} />*/}
          <TextField label="Note" name="note" onChange={handleChange} value={values.note} />
          <TextField label="Web" name="web" onChange={handleChange} value={values.web} />
          <TextField label="Congregation" name="congregation" onChange={handleChange} value={values.congregation} />
          <TextField label="Hours" name="hours" onChange={handleChange} value={values.hours} />
          <TextField label="Department" name="department" onChange={handleChange} value={values.department} />
        </Row>
        <InvoiceList invoicesByNumber={(contact||{invoices:{}}).invoices} skipNames refetch={refetch}/>
        {/*<ButtonRow><Button type="submit" gold>Save</Button></ButtonRow>*/}
      </Form>
      {/*<Code>{vCard}</Code>*/}
      {/* {vCard&&<QRCodeSVG id="qrcode" version="1.1" xmlns="http://www.w3.org/2000/svg" value={vCard} level="M" size={400} />} */}
      {/*<Code>values: {JSON.stringify(values)}</Code>*/}
      {/*<Code>contact: {JSON.stringify(contact)}</Code>*/}
      <FabGroup left><BackFab/></FabGroup>
      <FabGroup right>
        {dirty?(
          <Fragment>
            <SaveFab onClick={handleSubmit}/>
            <CancelFab onClick={reset}/>
          </Fragment>
        ):(
          <Fragment>
            <Fab to={getUrl('invoices','new',{id:(contact||{}).id, name })} Icon={DollarSignIcon}/>
            <Fab Icon={DownloadIcon} onClick={handleDownload}/>
            <DeleteFab onClick={handleDelete}/>
          </Fragment>
        )}
      </FabGroup>
    </Root>
  )
}

const ContactWithData = props => {
  const { id } = useParams();
  return <Query Component={EditContact} query={getContact} showLoading variables={{ id }} {...props}/>
}


export default ContactWithData;