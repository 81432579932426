import React from 'react'
import styled from 'styled-components';
import { Header as _Header } from '../atoms';
import Button, { ButtonRow } from '../../../atoms/Button';

const Root = styled.div`
  max-width: 1200px;
  background: #d8d8d8;
  padding: 2rem 0 1rem;
  margin: 2em auto 0;
`;
const Header = styled(_Header)`
  text-align: center;
  font-size: 2.5rem;
  padding: 0 0 2rem;
  ${p => p.theme.breakpoints.tablet} {
    padding: 0;
    margin: 0 2rem;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  // width: 95%;
  // margin: auto;
  // ${p => p.theme.breakpoints.tablet} {
  //   flex-direction: column;
  //   align-items: center;
  // }
`;
const CardRoot = styled.div`
  background: white;
  position: relative;
  flex: 1 0 250px;
  // width: 30%;
  // max-width: 350px;
  margin: 2rem;
  padding: 1rem;
  transition: transform 1s;
  &:hover {
    transform: scale(1.1);
  }
  // ${p => p.theme.breakpoints.tablet} {
  //   margin: 4rem 0 0;
  //   width: 40%;
  // }
  // @media screen and (max-width: 600px) {
  //   width: 60%;
  // }
  ${p => p.theme.breakpoints.phone} {
    flex: unset;
    width: 100%;
  }
`;
const CardText = styled.p`
  font-size: 1rem;
  font-family: Lora;
  text-align: center;
  margin: 2rem 0;
`;
const CardName = styled.p`
  font-size: .85rem;
  text-align: end;
  font-family: Lora;
  margin: 0;
`;
const CardTitle = styled.p`
  font-size: .75rem;
  font-family: Lora;
  text-align: end;
  margin: 0.25rem .5rem;
  color: #9B9B9B;
`;
const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d5aa6d;
  position: absolute;
  top: -15px;
  left: 15px;
  width: 42px;
  height: 30px;
`;
const Icon = styled.img`
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
`;
const Spacer = styled.div`
  flex: 1 0 250px;
  margin: 2rem;
  ${p => p.theme.breakpoints.phone} {
    flex: 0;
  }
`;
const Card = ({ text, name, title }) => (
  <CardRoot>
    <CardIcon>
      <Icon loading="lazy" alt="quote icon" src="/images/quote-icon.png" />
    </CardIcon>
    <CardText>{text}</CardText>
    <CardName>{name}</CardName>
    <CardTitle>{title}</CardTitle>
  </CardRoot>
)

export const Clients = ({ openConsultationDialog }) => {
  return (
    <Root id="clients">
      <Header>
        We love our clients and our clients love us
      </Header>
      <CardsWrapper>
        <Card 
          text="“Before working with Woodbourne, we had experienced accountants prepare our taxes. However, Woodbourne asked detailed questions and delved deeper into our recordkeeping than anyone ever did before. They helped us structure our officer salary payments and set up our payroll for our officer (previous accountants had not informed us of the need to do this). As a result, they saved us 35% on our business taxes the first year, and these savings continued during the following years.”"
          name="— Ted"
          title="Rental property owner, Maryland"
        />
        <Card 
          text="“We did not have time to manage our restaurant and at the same time handle payroll, bookkeeping, sales taxes, and annual income taxes. Woodbourne helped us every step of the way. They also applied for local business licenses and state registrations.”"
          name="— Aracely"
          title="Restaurant owner, New Jersey"
        />
        <Card 
          text="“I fell behind on filing my taxes. Woodbourne immediately started reconstructing my financial records and preparing the back taxes for the missing years. They followed up with me regularly until we had all the information to catch up. Now that my taxes are current, I feel that a big weight has been taken off my shoulders.”"
          name="— Dan and Laurie"
          title="North Carolina"
        />
        <Card 
          text="“Before working with them, we were in need of an experienced CFO, and Woodbourne backed us up with their business experience. For example, they helped us control expenses. They also helped us plan for expansion without getting in over our heads. When we wanted to hire more employees, they helped calculate the cost. They also helped us apply for business credit and check the credit of possible customers. We even called them for advice during evenings and weekends, and they always made themselves available.”"
          name="— James"
          title="Painting contractor, Virginia"
        />
        {/* <Card 
        text="“Albert and his team did a great job in helping us with our payroll and our HR needs.”"
        name="— Beverly"
        title="Medical practice, Idaho"
        /> */}
        <Card 
          text="“We needed an online bookkeeping service, and they provided exactly what we needed. At very reasonable cost, they gave us constantly updated reports of our business finances. Part of their service was to give us a better handle on our payments to suppliers. Their reports kept us constantly updated on our profitability and year to year comparisons.”"
          name="— Richard"
          title="Cleaning service, New York"
        />
        <Card 
          text="“Woodbourne helped us plan for our future expenses. They created tools to help us with cash management.”"
          name="— Curt"
          title="Small business owner, Texas"
        />
        {/* <Spacer/> */}
        {/* <Spacer/> */}
      </CardsWrapper>
      <ButtonRow><Button onClick={openConsultationDialog}>Request a free consultation</Button></ButtonRow>
    </Root>
  )
}


export default Clients;