import React, { useState } from 'react'
import styled, { css } from 'styled-components';
// import { API } from 'aws-amplify'
import { createNewsletterSignup } from '../../../graphql/mutations'
import Button from '../../../atoms/Button';
import _Accordion from '../../../atoms/Accordion';
import { Label as _Label, Input as _Input } from './ContactUs';
import _FacebookIcon from '../../../icons/Facebook';
import _TwitterIcon from '../../../icons/Twitter';
import _InstagramIcon from '../../../icons/Instagram';
// import useNotifications from '../../../hooks/useNotifications';
import useMutation from '../../../hooks/useMutation';

const iconStyle = css`
  font-size: 3em;
  color: #d5aa6d;
`;
const FacebookIcon = styled(_FacebookIcon)(iconStyle);
const TwitterIcon = styled(_TwitterIcon)(iconStyle);
const InstagramIcon = styled(_InstagramIcon)(iconStyle);
const Root = styled.div`
  width: 90%;
  margin: auto;
  margin-bottom: 6rem;
  display: flex;
  ${p => p.theme.breakpoints.tablet} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
`;
const NewsletterRoot = styled.div`
  width: 50%;
  background: #d8d8d8;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${p => p.theme.breakpoints.tablet} {
    width: 80%;
  }
`;
const SocialMediaRoot = styled.div`
  width: 50%;
  padding: 3rem 0;
`;
const Title = styled.h1`
  font-family: Futura, sans-serif;
  font-size: 1.8rem;
  margin: 0;
  text-align: center;
  font-weight: 500;
`;
const Text = styled.p`
  font-family: Futura, sans-serif;
  font-size: 1.2rem;
  text-align: center;
  margin: 1rem 0;
`;
const SocialMediaRow = styled.a`
  display: flex;
  align-items: center;
  margin: .75rem;
  gap: 1em;
  text-decoration: none;
  color: black;
`;
const Link = styled.p`
`;
const SocialMediaContent = styled.div`
  width: 35%;
  margin: auto;
  @media screen and (max-width: 900px) {
    width: 50%;
  }
  @media screen and (max-width: 800px) {
    width: 60%;
  }
  @media screen and (max-width: 700px) {
    width: 70%;
  }
  @media screen and (max-width: 600px) {
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;
const Accordion = styled(_Accordion)`
  text-align:center;
  position: relative;
  z-index: 0;
  width: 80%;
  ${p => p.theme.breakpoints.tablet} {
    width: 100%
  }
`;
const Label = styled(_Label)`
  margin: 2rem 0 0;
`;
const Input = styled(_Input)`
  background: transparent;
`;
const SubscribeForm = styled.form`
  margin: 1rem;
`;
const SignMeUpButton = styled(Button)`
  margin-top: 1.2rem;
`;
// const SubscribeButton = styled.button`
//   ${yellowButtonStyle}
//   border: none;
//   cursor: pointer;
//   margin: 3rem 0;
// `;
const initialValues = { firstName: "", lastName: "", email: "" };
const NewsletterBox = () => {
  // const showMessage = useNotifications();
  const submit = useMutation(createNewsletterSignup)
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(initialValues);
  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }
  const onSubmit = async event => {
    event.preventDefault()
    await submit(values, {
      successMessage: "You have signed up for the newsletter successfully!"
    })
    setValues(initialValues);
    setIsOpen(false);
  }
  return (
    <NewsletterRoot>
      <Title>Subscribe to our newsletter</Title>
      <Text>Our newsletter helps you get regular updates on the constantly changing world, how it affects your wallet, and what you can do to limit your exposure to risks and maximize your profits.</Text>
      <Accordion parentIsOpen={isOpen} parentSetIsOpen={setIsOpen} maxHeight={750} Button={({ onClick }) => <Button onClick={onClick}>SUBSCRIBE</Button>}>
        <SubscribeForm>
          <Label>First Name</Label>
          <Input aria-label="first-name" name="firstName" value={values.firstName} onChange={handleChange} />
          <Label>Last Name</Label>
          <Input aria-label="last-name" name="lastName" value={values.lastName} onChange={handleChange} />
          <Label>Email</Label>
          <Input aria-label="email" name="email" value={values.email} onChange={handleChange} />
          <SignMeUpButton type="submit" onClick={onSubmit}>SIGN ME UP!</SignMeUpButton>
        </SubscribeForm>  
      </Accordion> 
    </NewsletterRoot>
  )
}
const SocialMedia = () => (
  <SocialMediaRoot>
    <Title>Follow us on</Title>
    <SocialMediaContent>
      <SocialMediaRow href="https://www.facebook.com/woodbourneaccounting" target="_blank">
        <FacebookIcon />
        <Link>Facebook</Link>
      </SocialMediaRow>
      {/* <SocialMediaRow>  
        <TwitterIcon />
        <Link>Twitter</Link>
      </SocialMediaRow>
      <SocialMediaRow>
        <InstagramIcon />
        <Link>Instagram</Link>
      </SocialMediaRow> */}
    </SocialMediaContent>
  </SocialMediaRoot>
)
export const Newsletter = () => {
  return (
    <Root>
      <NewsletterBox />
      <SocialMedia />
    </Root>
  )
}


export default Newsletter;