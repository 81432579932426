import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components';
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import getUrl from '../../lib/getUrl';
import TextField from '../../atoms/TextField';
import useMutation from '../../hooks/useMutation';
import useUser from '../../hooks/useUser';
import { getPostQuery, createPostMutation, updatePostMutation, deletePostMutation } from './queries'
import { FabGroup, BackFab, SaveFab, CancelFab, DeleteFab } from '../../atoms/Fab';
import { diff } from 'deep-object-diff';
import Query from '../../atoms/Query';

const defaultValues = {
  type: "Post",
  cardImgUrl: "https://picsum.photos/250/250",
  bannerImgUrl: "https://picsum.photos/1200/600",
  title:"",
  snippet:"",
  category:"Tax Preparation",
  tags: [],
  writerID: "NOBODY",
  body:"",
}

const ArticleRoot = styled.div`
  padding: 4rem;
`;
const Form = styled.form`

`;
export const EditPost = ({ getPost, updateCache }) => {
  const [user] = useUser()
  const navigate = useNavigate();
  const { writer, updatedAt, _deleted, _lastChangedAt, ...post } = getPost||{};
  const initialValues = {...defaultValues, ...post};
  const [values, setValues] = useState(initialValues);
  const isDirty = !!Object.keys(diff(values, initialValues)).length;
  const createPost = useMutation(createPostMutation, {
    successMessage: "Post created successfully!"
  })
  const updatePost = useMutation(updatePostMutation, {
    successMessage: "Post updated successfully!"
  })
  const trash = useMutation(deletePostMutation, {
    successMessage: "Post deleted successfully!"
  })
  
  useEffect(()=>setValues({...values, ...post }),[getPost])
  const handleChange = event => setValues({ ...values, [event.target.name]: event.target.value });
  const handleSubmit = async event => {
    event.preventDefault();
    if(!values.id) {
      const result = await createPost({
        ...values,
        id: uuid(),
        writerID: user.username,
      })  
      navigate(getUrl('post','edit',result.data.createPost.id), { replace: true })
    }
    else {
      const result = await updatePost(values)  
      updateCache({ getPost: result.data.updatePost })
    }
  }
  const reset = event => {
    event.preventDefault()
    setValues({...initialValues, ...post})
  }
  const handleDelete = async () => {
    await trash({ id: post.id, _version: post._version })
    navigate(getUrl('post','list'))
  }
  return (
    <ArticleRoot>
      <Form onSubmit={handleSubmit}>
        <TextField label="Title" required name="title" onChange={handleChange} value={values.title} />
      
        <TextField label="Card Image" required name="cardImgUrl" onChange={handleChange} value={values.cardImgUrl} />
        <TextField label="Banner Image" required name="bannerImgUrl" onChange={handleChange} value={values.bannerImgUrl} />
        <TextField label="Category" required name="category" onChange={handleChange} value={values.category} />
        <TextField label="Snippet" required name="snippet" onChange={handleChange} value={values.snippet} />
        <TextField label="Body" required name="body" onChange={handleChange} value={values.body} />
        {/*{JSON.stringify(values)}*/}
      </Form>
      <FabGroup left><BackFab/></FabGroup>
      <FabGroup right>
        {isDirty&&(
          <Fragment>
            <SaveFab onClick={handleSubmit}/>
            <CancelFab onClick={reset}/>
          </Fragment>
        )}
      <DeleteFab onClick={handleDelete}/>
      </FabGroup>
    </ArticleRoot>
  )
}

const PostWithData = props => {
  const { id } = useParams();
  return <Query Component={EditPost} query={getPostQuery} variables={{ id }} {...props}/>
}


export default PostWithData;