const urlRenderers = {
  post: {
    list: () =>`/blog`,
    detail: id =>`/blog/${id}`,
    edit: id =>`/blog/${id}/edit`,
    new: () =>`/blog/new/edit`,
  },
  writers: {
    list: () =>`/writers`,
    detail: id =>`/writers/${id}`,
    edit: id =>`/writers/${id}/edit`,
    new: () =>`/writers/new/edit`,
  },
  contacts: {
    list: () =>`/contacts`,
    detail: id =>`/contacts/${id}`,
    edit: id =>`/contacts/${id}/edit`,
    new: () =>`/contacts/new/edit`,
  },
  invoices: {
    list: () =>`/invoices`,
    detail: id =>`/invoices/${id}`,
    edit: id =>`/invoices/${id}/edit`,
    new: ({ id, name }) =>`/invoices/new/edit?contactId=${id}&name=${name}`,
  },
  users: {
    login: () =>`/login`,
    register: id =>`/register`,
    forgot: id =>`/forgot-password`,
    add: id =>`/users/add`,
    setPassword: ({ username, password }) =>`/set-password?username=${username}&password=${password}`,
  },
  storage: {
    folder: id => `/storage/${id}`
  }
}
export const getUrl = (object, type, args) => {
  return urlRenderers[object][type||"detail"](args);
}

export default getUrl;