import React from "react";
import styled from 'styled-components/macro';
// import {useField} from '../hooks/useForm';

export const Label = styled.label`
  flex: 1 0 200px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  user-select: none;
`;
export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-color: ${p => p.theme.colors.black};
  border-width: 2px;
  border-style: solid;
  border-radius: 0.25em;
  // border-radius: 50%;
  ${Label}:hover & {
    background-color: ${p=>p.theme.colors.darkgrey};
  }
  ${Input}:checked ~ & {
    background-color: ${p => p.theme.colors.black};
  }
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  ${Input}:checked ~ &:after {
    display: block;
  }

`;
export const Error = styled.p`
  color: ${p => p.theme.colors.red};
  font-size: .8rem;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;

`;
export const Checkbox = ({ label, id, className, checked, error, touched, value, ...props }) => {
  const hasError = error && touched;
  return (
    <Label htmlFor={id} data-checkbox-id={id} className={className}>
      {label}
      <Input type="checkbox" id={id} {...props} value={value} checked={!!value} hasError={!!hasError}  />
      <Checkmark />
      <Error>{error}</Error>
    </Label>
  )
}

export default Checkbox
