import styled, { css } from 'styled-components/macro';
export const baseStyle = css`
  // font-family: ${p => p.Futura &&"'Futura Now Text', "} sans-serif;
  font-family: Lora;
  font-size: 1rem;
  line-height: 1.4;
  color: black;
  ${p=>p.center&&"text-align: center;"}
  ${p=>p.left&&"text-align: left;"}
  ${p=>p.right&&"text-align: right;"}
  ${p => p.theme.breakpoints.tablet} {
    font-size: .8rem;
  }
  ${p => p.theme.breakpoints.phone} {
    font-size: .6rem;
  }
`;
export const UnorderedList = styled.ul`
  ${baseStyle}
  padding-top: 1em;
  padding-bottom: 1em;
`;
export const ListItem = styled.li`
  ${baseStyle}
  margin-left: 2em;
`;
const headerStyle = css`
  font-family: 'Futura Now Text', Futura, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 1em 0;
  text-align: center;
`;
export const variantStyles = {
  h1: css`
    ${baseStyle}
    ${headerStyle}
    font-size: 3rem;
    ${p => p.theme.breakpoints.tablet} {
      font-size: 2.7rem;
    }
    ${p => p.theme.breakpoints.phone} {
      font-size: 2.4rem;
    }
  `,
  h2: css`
    ${baseStyle}
    ${headerStyle}
    font-size: 2.6rem;
    ${p => p.theme.breakpoints.tablet} {
      font-size: 2.25rem;
    }
    ${p => p.theme.breakpoints.phone} {
      display: block;
      font-size: 2.25rem;
    }
  `,
  h3: css`
    ${baseStyle}
    ${headerStyle}
    font-size: 1.75rem;
    ${p => p.theme.breakpoints.tablet} {    
    font-size: 1.4rem;
    }
    ${p => p.theme.breakpoints.phone} {
    font-size: 1.25rem;
    }
  `,  
  h4:css`
    ${baseStyle}
    ${headerStyle}
    font-size: 1.25rem;
    text-align: left;
    ${p => p.theme.breakpoints.tablet} {    
      font-size: 1.2rem;
    }
    ${p => p.theme.breakpoints.phone} {
      font-size: 1.1rem;
    }
  `,
  h5:css`
    ${baseStyle}
    ${headerStyle}
    font-size: 0.8rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    ${p => p.theme.breakpoints.tablet} {
      font-size: 0.75rem;
    }
    ${p => p.theme.breakpoints.phone} {
      display: block;
      font-size: 2.25rem;
    }
  `,
  h6:css`
    ${baseStyle}
    ${headerStyle}
    font-size: 0.65rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  `,
  error:css`
    ${baseStyle}
    color: ${p=>p.theme.colors.red};
  `,
};
const StyledText = styled.p`
  ${p=>variantStyles[p.variant]||baseStyle}
`;
export const Text = p => {
  return <StyledText {...p} as={p.variant} />
}
export const Header1 = styled.h1`
  ${variantStyles.h1}
`;
export const Header2 = styled.h2`
  ${variantStyles.h2}
`;
export const Header3 = styled.h3`
  ${variantStyles.h3}
`;
export const Header4 = styled.h4`
  ${variantStyles.h4}
`;
export const Header5 = styled.h5`
  ${variantStyles.h5}
`;
export const Header6 = styled.h6`
  ${variantStyles.h6}
`;
export const underlineStyle = css`
  border-bottom: 4px solid #e8282a;
  `;
export const Underlined = styled.span`
  ${underlineStyle}
`;
export const Error = styled.p`
  ${variantStyles.error}
`;
export default Text
