import styled from 'styled-components/macro';


export const Container = styled.div`
  width: 100%;
  max-width: ${p=>p.maxWidth||1440}px;
  // padding-right: 2vw;
  // padding-left: 2vw;
  box-sizing: border-box;
  margin: auto;
`;

export default Container;
