import React, { useState, Fragment } from 'react'
import styled from 'styled-components';
import AddIcon from '../../icons/Add';
import CloseIcon from '../../icons/Close';
import EditIcon from '../../icons/Edit';
import ChevronRightIcon from '../../icons/ChevronRight';
import ChevronLeftIcon from '../../icons/ChevronLeft';
import TextField from '../../atoms/TextField';
import _Row from '../../atoms/Row';
import Text, { Header3 as _Header3 } from '../../atoms/Text';
import useMutation from '../../hooks/useMutation';
import { createSnippet as createSnippetMutation, deleteSnippet as deleteSnippetMutation } from '../../graphql/mutations';
import IconButton from '../../atoms/IconButton';
import { v4 as uuid } from 'uuid';

const Header3 = styled(_Header3) `
  margin-bottom: 1em;
  margin-top: 0.5em;
`
const Paper = styled.form`
  position: fixed;
  width: 600px;
  top: 100px;
  bottom: 0;
  left: calc(100vw - ${p=>p.$open?600:72}px);
  display: flex;
  background-color: white;
  box-shadow: 4px 4px 9px 0 rgb(0 0 0 / 45%);
  overflow-y: scroll;
  padding-left: ${p=>p.$open?"1em":"3em"};
`;
const CollapseButton = styled(IconButton)`
  position: absolute;
  left: .5em;
  top: 1em;
`;
const Row = styled(_Row)`
  gap: 0;
`;
const Content = styled.div`
  flex: 1;
  padding: 1em;
`;
const Key = styled(Text)`
  // max-width: 350px;
  flex:1;
`;
const SnippetAction = styled(IconButton)`
  flex: 0 0 40px;
`;

export const Tray = ({ snippets, modalOpen, setModalOpen, refetch, values, setValues }) => {
  // const showMessage = useNotifications()
  const { client, bank } = values;
  const handleAddSnippet = () => setModalOpen(true)
  const createSnippet = useMutation(createSnippetMutation);
  // const updateSnippet = useMutation(updateSnippet);
  const deleteSnippet = useMutation(deleteSnippetMutation);
  const clearForm = () => setValues({ ...values, vendor:"", key:"", category:""})
  const handleSubmitSnippetForm = async e => {
    const { key, vendor, category } = values;
    e.preventDefault()
    clearForm()
    await createSnippet({ id: uuid(), key, vendor, category, contactId:client, bank })
    refetch()
  }
  const handleRemove = async (snippet) => {
    console.log(`handleRemove`, snippet);
    await deleteSnippet({ id: snippet.id })
    refetch()
  }
  const handleEdit = async (snippet) => {
    const { key, vendor, category } = snippet;
    setValues({ ...values, key, vendor, category })
    await deleteSnippet({ id:snippet.id })
    refetch()
  }
  const handleChange = e => setValues({ ...values, [e.target.name]: e.target.value });
  return (
    <Paper $open={modalOpen} onSubmit={handleSubmitSnippetForm}>
      {modalOpen?(
        <CollapseButton Icon={ChevronRightIcon} onClick={()=>setModalOpen(false)}/>
      ):(
        <CollapseButton Icon={ChevronLeftIcon} onClick={()=>setModalOpen(true)}/>
      )}
      <Content>
        <Header3>Shorcuts</Header3>
        {values.key&&(
          <Fragment>
            <TextField label="Key" name="key" value={values.key} onChange={handleChange}/>
            <TextField label="Vendor" name="vendor" value={values.vendor} onChange={handleChange}/>
            <TextField label="Category" name="category" value={values.category} onChange={handleChange}/>
            <IconButton Icon={AddIcon} onClick={handleAddSnippet} type="submit"/>
          </Fragment>
        )}
        {snippets.map((snippet)=>(
          <Row key={snippet.id}>
            <Key>"{snippet.key}": {snippet.vendor} - {snippet.category}</Key>
            <SnippetAction Icon={EditIcon} onClick={()=>handleEdit(snippet)}/>
            <SnippetAction Icon={CloseIcon} onClick={()=>handleRemove(snippet)}/>
          </Row>
        ))}
      </Content>
    </Paper>
  )
}


export default Tray;