import React from 'react'
import styled from 'styled-components';
import Query from '../../atoms/Query';
import getUrl from '../../lib/getUrl';
import { invoicesByNumber } from '../../graphql/queries';
// import useUser from '../../hooks/useUser';
import { toCents, formatCents } from '../../lib/cents';
import { FabGroup, AddFab, EditFab } from '../../atoms/Fab';
import IconButton from '../../atoms/IconButton';
import DeleteIcon from '../../icons/Delete';
import { useNavigate } from "react-router-dom";
import { deleteInvoice } from '../../graphql/mutations';
import useMutation from '../../hooks/useMutation';
import NoResults from '../../atoms/NoResults';
import getDisplayName from '../../lib/getDisplayName';
import { Header1 } from '../../atoms/Text';
import _Container from '../../atoms/Container';
const Container = styled(_Container)`
  padding-top: 2em;
  // width: 100%;
`;
const Table = styled.table`
  margin-top: 2em;
  width: 100%;
`;
const Row = styled.tr`
  
`;
const HeaderCell = styled.th`
  text-align: left;
`;
const Cell = styled.td`
  cursor: pointer;
`;
const Body = styled.tbody``;
const Head = styled.thead``;

export const Invoice = ({ invoice={}, skipNames, refetch }) => {
  const navigate = useNavigate();
  const trash = useMutation(deleteInvoice, {
    successMessage: "Invoice deleted successfully!",
    refetch
  })
  const handleDelete = async event => {
    event.preventDefault();
    event.stopPropagation()
    await trash({ id: invoice.id, _version: invoice._version })
  }
  const handleClick = () => navigate(getUrl('invoices','edit', invoice.id))
  return (
    <Row>
      <Cell onClick={handleClick}>{invoice.number}</Cell>
      {/* <Cell onClick={handleClick}>{invoice.date}</Cell> */}
      <Cell onClick={handleClick}>{invoice.month}</Cell>
      {!skipNames&&<Cell onClick={handleClick}>{getDisplayName(invoice.contact)}</Cell>}
      <Cell>{formatCents(invoice.total)}</Cell>
      <Cell>{invoice.paid}</Cell>
      <Cell><IconButton Icon={DeleteIcon} onClick={handleDelete}/></Cell>
    </Row>
  )
}
export const List = ({ invoicesByNumber={items:[]}, skipNames, refetch }) => {
  const invoices = invoicesByNumber.items;
  if(!invoices||!invoices.length) return<NoResults type="invoices"/>
  return (
    <Table>
      <Head>
        <Row>
          <HeaderCell>Number</HeaderCell>
          {/* <HeaderCell>Created</HeaderCell> */}
          <HeaderCell>Month</HeaderCell>
          {!skipNames&&<HeaderCell>Name</HeaderCell>}
          <HeaderCell>Total</HeaderCell>
          <HeaderCell>Paid</HeaderCell>
        </Row>
      </Head>
      <Body>
        {invoices.map(invoice=><Invoice key={invoice.id} invoice={invoice} skipNames={skipNames} refetch={refetch}/>)}
      </Body>
      {/* <FabGroup right><AddFab to={getUrl('invoices','new')}/></FabGroup> */}
    </Table>
  )
}
const ListWithData = props => {
  const variables = {type:"Invoice"}
  return (
    <Container>
      <Header1>Invoices</Header1>
      <Query Component={List} query={invoicesByNumber} variables={variables} showLoading {...props}/>
    </Container>
  )
}

export default ListWithData;