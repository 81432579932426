import React from 'react';
// import styled from 'styled-components/macro';
import { FormikContext, setNestedObjectValues, useFormik } from 'formik';

const scrollToFirstElementWithError = form => {
  // console.log("Scrolling to first element with error")
  const keys = Object.keys(form.errors);
  for (let index = 0; index < keys.length; index++) {
    const element = document.getElementById(`${keys[index]}-field`);
    console.log(`${keys[index]}-field: ${!!element}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      console.log("scrolled to:" + keys[index])
      return keys[index];
    }
  }
}
export const Form = ({ children, form, ...props }) => (
  <FormikContext.Provider value={form}>
    <form onReset={form.handleReset} {...props} >
      {children}
    </form>
  </FormikContext.Provider>
)
export const useForm = props => {
  const form = useFormik(props);
  const handleSubmit = async (e, options) => {
    e&&e.preventDefault();
    const errors = await form.validateForm()
    // console.log(`errors: ${JSON.stringify(errors, null, 2)}`);
    if (!Object.keys(errors).length) {
      // console.log("Submitting")
      props.onSubmit(form.values, options);
    } else {
      // console.log("Showing Errors")
      form.setTouched(setNestedObjectValues(errors, true));
      scrollToFirstElementWithError(form);
    }
  }
  return {
    ...form,
    submitForm: handleSubmit,
  }
}
export default useForm
