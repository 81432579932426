import React from 'react';
import Select from './Select';

export const GenderSelect = props => {
  return <Select label="Gender" prompt="Choose a gender" {...props} options={options}/>
}
export default GenderSelect;
export const options = [
  { label: "", value: "" },
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];