import React, { useState } from 'react'
import styled from 'styled-components/macro';

export const Dropper = styled.div`
  ${p=>p.$highlight?`
    border: 2px dashed black;
  `:''}
`;

export const FileDropper = ({ item, onDrop, className, children, onMove, ...props }) => {
  const [draggingOver, setDraggingOver] = useState(false)
  // const handleDragEnter = e => {}
  const handleDragLeave = e => setDraggingOver()
  const handleDragEnd = e => setDraggingOver()
  const handleDragOver = e => {
    e.stopPropagation()
    e.preventDefault();
    setDraggingOver(true)
  }
  const handleDrop = async e => {
    e.preventDefault()
    e.stopPropagation()
    setDraggingOver()
    console.log(`handleDrop`, e);
    const data = e.dataTransfer.getData("text");
    if(data) return onMove(data, item);
    // console.log(`data`, data);
    // const files = (e.dataTransfer ? e.dataTransfer.files : e.target.files);
    // console.log(`files`, files);
    // if(!files || !files.length) return false;



    const files = await Promise.all(
      [...e.dataTransfer.items]
        .filter((item) => item.kind === 'file')
        .map((item) => item.getAsFile())
    );
    onDrop(files)
  }
  return (
    <Dropper
      className={className}
      $highlight={draggingOver}
      // onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
    >{children}</Dropper>
  );
}
export default FileDropper;