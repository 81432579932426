import React from 'react';
import styled, { css } from 'styled-components'
import { Link as _Link } from 'react-router-dom';
import _FacebookIcon from '../icons/Facebook';
import _TwitterIcon from '../icons/Twitter';
import _InstagramIcon from '../icons/Instagram';

const iconStyle = css`
  font-size: 3em;
  color: white;
  margin: 0 1rem;
`;
const FacebookIcon = styled(_FacebookIcon)(iconStyle);
const TwitterIcon = styled(_TwitterIcon)(iconStyle);
const InstagramIcon = styled(_InstagramIcon)(iconStyle);


const Root = styled.div`
  display: flex;
  background: #4B505D;
  padding: 2rem 3rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  ${p => p.theme.breakpoints.tablet} {
    justify-content: center;
  }
`;

const Logo = styled.img`
  width: 250px;
  height: 56px;
  margin: 1rem 0;
`;
const Links = styled.div`
  margin: 1rem 0;
`;
const Link = styled(_Link)`
  color: white;
  text-decoration: none;
  margin: 0 1rem;
`;
const SocialMedia = styled.div`
  margin: 1rem 0;
`;
const IconButton = styled.a`
`;
// const FacebookIcon = styled(_FacebookIcon)`
//   fill: white;
//   margin: 0 1rem;
// `;
// const InstagramIcon = styled(_InstagramIcon)`
//   fill: white;
//   width: 32px;
//   height: 32px;
//   margin: 0 1rem;
//   position: relative;
//   top: 9px;
// `;
// const TwitterIcon = styled(_TwitterIcon)`
//   fill: white;
//   margin: 0 1rem;
// `;
export const Footer = () => {
  return (
    <Root>
      <Logo alt="Woodbourne Logo" loading="lazy" src="/images/logo.webp" />
      <Links>
        <Link to="/terms">Terms & Conditions</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </Links>
      <SocialMedia>
        <IconButton aria-label="Read more about me on FB" href="https://www.facebook.com/woodbourneaccounting" target="_blank">
          <FacebookIcon loading="lazy"/>
        </IconButton>
        {/* <TwitterIcon />
        <InstagramIcon /> */}
      </SocialMedia>
    </Root>
  )
}

export default Footer
