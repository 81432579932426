import SvgIcon from '../atoms/SvgIcon';
import React from 'react';

export const Icon = props => (
  <SvgIcon focusable="false" width="188px" height="158px" viewBox="0 0 188 158" aria-hidden="true"  {...props}>
     <g transform="translate(-26.000000, -40.000000)">
         <path d="M120,40 C125.185314,40 129.448844,43.9466248 129.950615,48.9998991 L174.5,49 C179.19442,49 183,52.8055796 183,57.5 C183,57.6713863 182.994928,57.8415879 182.984925,58.0104628 L212.492451,106.881916 C213.671549,108.834798 213.784749,111.133517 212.999449,113.100508 L213,113 C213,124.59798 195.762963,134 174.5,134 C153.237037,134 136,124.59798 136,113 C136,111.728033 136.207324,110.482478 136.604545,109.272841 C136.733323,108.559579 136.97386,107.854865 137.334741,107.184766 L137.457687,106.965817 L161.418,66 L130,66 L130,178 L164,178 L164,198 L77,198 L77,178 L110,178 L110,66 L78.355,66 L101.591798,107.051812 C101.993409,107.761326 102.257687,108.511168 102.39314,109.270635 L102.30246,109.000087 C102.760263,110.29497 103,111.632214 103,113 C103,124.59798 85.7629629,134 64.5,134 C43.2370371,134 26,124.59798 26,113 C26,111.725225 26.2082405,110.476979 26.6071789,109.264831 C26.740611,108.52327 26.9961229,107.79153 27.3815625,107.09913 L27.5075489,106.881916 L57.0150751,58.0104628 C57.0050723,57.8415879 57,57.6713863 57,57.5 C57,52.8909327 60.668453,49.1386785 65.2448397,49.0037566 L65.5,49 L110.049385,48.9998991 C110.551156,43.9466248 114.814686,40 120,40 Z M65.305,71.365 L42.582,109 L86.607,109 L65.305,71.365 Z M174.595,71.202 L152.486,109 L197.417,109 L174.595,71.202 Z" id="Shape"></path>
     </g>
  </SvgIcon>
  )
export default Icon;