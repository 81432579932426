import React, { useRef, Fragment } from 'react'
import styled from 'styled-components/macro';
import IconButton from '../../atoms/IconButton';
import UploadIcon from '../../icons/Upload';

const FileInput = styled.input`
  display: none;
`;

export const UploadButton = ({ onUpload }) => {
  const fileInput = useRef(null);
  const handleFileSelection = e => onUpload(Object.values(e.target.files));
  return (
    <Fragment>
      <FileInput type="file" ref={fileInput} multiple onChange={handleFileSelection} />
      <IconButton Icon={UploadIcon} onClick={()=>fileInput.current.click()} />
    </Fragment>
  );
}
export default UploadButton;