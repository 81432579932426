import React, { Fragment } from 'react'
import styled from 'styled-components';
import _CloseIcon from '../icons/Close';
import { Header3 } from '../atoms/Text'
const CloseIcon = styled(_CloseIcon)`
  position: absolute;
  top: .5em;
  right: .5em;
  cursor: pointer;
`;
const Title = styled(Header3)`
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  font-family: Futura Now Text, Futura, sans-serif;
  ${p => p.theme.breakpoints.tablet} {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 1.3rem;
  }
`;
const Subtitle = styled.p`
  font-size: 1.3rem;
  font-family: Lora;
  font-style: italic;
  ${p => p.theme.breakpoints.tablet} {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 650px) {
    font-size: .9rem;
    text-align: justify;
  }
`;
const Content = styled.div`
  position: fixed;
  z-index: 21;
  padding: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 4px 4px 9px 0 rgb(0 0 0 / 45%);
  width: 50vw;
  @media screen and (max-width: 650px) {
    padding: 2rem 2rem 0 2rem;
  }
  max-height: 80vh;
  overflow-y: scroll;
  ${p=>p.theme.scrollbar}
  ${p => p.theme.breakpoints.tablet} {
    width: 80vw;
  }
`;
const Background = styled.div`
  position: fixed;
  z-index: 20;
  background-color: black;
  opacity: 70%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
export const Dialog = ({ isOpen, onClose, title, subtitle, body, openConsultationDialog }) => {
  if(!isOpen) return null;
  const Body = body;
  return (
    <Fragment>
      <Content>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Body openConsultationDialog={openConsultationDialog}/>
        <CloseIcon onClick={onClose}/>
        
      </Content>
      <Background onClick={onClose}/>
    </Fragment>
  )
}

export default Dialog;