export const getDisplayName = (contact, type="lastFirst") => {
  if(!contact) return "New Contact";
  console.log(`getDisplayName contact`, contact);
  let result = "";
  if(contact.kind==="individual"){
    const firstName = (contact.firstName||"").trim().replace("  "," ");
    const lastName = (contact.lastName||"").trim().replace("  "," ");
    if(type==="lastFirst") result = `${lastName}, ${firstName}`;
    else {
      result = `${contact.middleName||""} ${contact.nickname?`(${contact.nickname})`:""}`.trim();
      result = `${firstName} ${result} ${lastName}`.trim();
      result = `${contact.prefix||""} ${result} ${contact.suffix||""}`.trim();
    }
  } else {
    result = (contact.org||"").trim();
  }
  // console.log(`result`, result);
  return result||"New Contact"
}
export default getDisplayName;