import React from 'react'
import styled from 'styled-components';
import Query from '../../atoms/Query';
import Card from './Card';
import getUrl from '../../lib/getUrl';
import { listContacts } from '../../graphql/queries';
// import useUser from '../../hooks/useUser';
import { FabGroup, AddFab, EditFab } from '../../atoms/Fab';

const Wrapper = styled.div`
  padding: 4em;
  display: flex;
  flex-wrap:wrap;
  gap: 2em;
`;

export const List = ({ listContacts={items:[]} }) => {
  const contacts = listContacts.items;
  return (
    <Wrapper>
      {contacts.map(contact=><Card key={contact.id} contact={contact} />)}
      <FabGroup right><AddFab to={getUrl('contacts','new')}/></FabGroup>
    </Wrapper>
  )
}
const ListWithData = props => <Query Component={List} query={listContacts} {...props}/>

export default ListWithData;