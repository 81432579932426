// import React from 'react';
import styled from 'styled-components';

export const SvgIcon = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: ${p=>p.$size||"1.5rem"};
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  ${p=>p.$color?`color: ${p.$color};`:""}
  ${p=>p.$style?p.$style:""}
`;
export default SvgIcon;