import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import _Text from '../atoms/Text'


const Bar = styled.div`
  position: relative;
   width: 100%;
   border: solid 1px ${p=>p.theme.colors.black};
   height: 1.5em;
   margin: 1em 0;
`;
const Progress = styled.div`
  background-color: ${p=>p.theme.colors.black};
  width: ${p=>p.$progress}%;
  height: 100%;
`;
const Text = styled(_Text)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom:0;
  text-align: center;
  line-height: 1.5em;
  color: ${p=>p.$progress>50?p.theme.colors.white:p.theme.colors.black};
`;

export const ProgressBar = ({ className, progress, textTemplate="{progress}%" }) => {
  const [active, setActive] = useState(false);
  const text = textTemplate.replace("{progress}",parseInt(progress))
  useEffect(()=>{
    console.log("progress:",progress);
    if(!progress) return false;
    if(progress!==100&&!active) setActive(true);
    if(progress===100&&active) setTimeout(()=>setActive(false),1000);
  }, [progress, active])
  if(!active) return null;
  return (
    <Bar className={className}>
      <Progress $progress={progress}/>
      <Text $progress={progress}>{text}</Text>
    </Bar>
  )
}
export default ProgressBar;