import React from 'react'
import styled from 'styled-components';
import getUrl from '../../lib/getUrl';
import Button, { ButtonRow } from '../../atoms/Button';
import _Link from '../../atoms/Link';

const ImageLink = styled(_Link)`
  width: 30%;
  cursor: pointer;
  ${p => p.theme.breakpoints.tablet} {
    width: 40%;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
  }
  ${p => p.theme.breakpoints.phone} {
    width: 60%;
  }
`;
const HeaderLink = styled(_Link)`
  
`;
const Image = styled.img`
  transition: transform 1s;
  width:100%;
  height:100%
`;
const ArticleRoot = styled.div`
  display: flex;
  width: 90%;
  margin: 4rem auto;
  position: relative;
  &:hover ${Image}{
    transform: scale(1.1);
  }
  ${p => p.theme.breakpoints.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;
const ArticleContent = styled.div`
  width: 70%;
  padding: 0 3rem;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
  // align-items: start;
  ${p => p.theme.breakpoints.tablet} {
    margin: 2rem 0;
    width: 80%;
  }
  ${p => p.theme.breakpoints.phone} {
    align-items: center;
  }
`;
const Dateline = styled.p`
  color: #2C3038;
  margin: 0;
  font-size: .75rem;
`;
const Title = styled.p`
  margin: 0.5rem 0;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: Futura Now Text, Futura, sans-serif;
  color: #2C3038;
`;
const Text = styled.p`
  margin: 0.75rem 0;
  font-family: Lora;
  font-size: 1.1rem;
`;
const ArticleContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;
export const Card = ({ id, cardImgUrl, createdAt, title, snippet }) => {
  const date = new Date(createdAt||"").toLocaleDateString('en-US', {
    month: 'long',
    day:'numeric',year: 'numeric',
  })
  return (
    <ArticleRoot>
      <ImageLink to={getUrl('post','detail',id)}><Image loading="lazy" src={cardImgUrl} /></ImageLink>
      <ArticleContent>
        <ArticleContentText>
          <Dateline>{date}</Dateline>
          <HeaderLink to={getUrl('post','detail',id)}><Title>{title}</Title></HeaderLink>
          <Text>{snippet}</Text>
        </ArticleContentText>
        <ButtonRow><Button gold to={getUrl('post','detail',id)}>READ MORE</Button></ButtonRow>
      </ArticleContent>
    </ArticleRoot>
  )
}


export default Card;