import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components';
import TextField from '../../atoms/TextField';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import _Container from '../../atoms/Container';
import csv2json from '../../lib/csv2json';
import download from 'downloadjs';

// import download from 'downloadjs';
// import { transforms, destHeader } from './transforms';
import DownloadIcon from '../../icons/Download';
import { FabGroup, Fab } from '../../atoms/Fab';
// import { listVendorKeys } from '../../graphql/queries';
// import Query from '../../atoms/Query';
import Row from '../../atoms/Row';

const resultHeaders = ["User","Type","Date","Time","Number","Name","isClient","Cost","Duration"];
// export const destHeader = "DATE,TYPE,VENDOR,EXPENSE,INCOME,CATEGORY,DESCRIPTION,DETAIL,FROM,RECEIPT,CHECK,NEGATIVE\r\n";
// import Tray from './Tray';
const Container = styled(_Container)`
  padding-top: 2em;
`;
const BigText = styled(Text)`
  font-size: 3em;
`;
const Pre = styled.pre``;
// const Table = styled.table`
//   margin-top: 2em;
//   width: 100%;
//   border-collapse: collapse; 
// `;
// const TableRow = styled.tr`
// `;
// const HeaderCell = styled.th`
//   padding: .5em;
//   text-align: left;
// `;
// const Cell = styled.td`
//   padding: .5em;
//   border-top: solid 1px rgb(204, 204, 204);
// `;
// const Body = styled.tbody``;
// const Head = styled.thead``;
export const Converter = () => {
//   const vendorKeys = listVendorKeys.items;
//   const [output, setOutput] = useState([])
  const [from, setFrom] = useState("")
  const [calls, setCalls] = useState([]);
  const [solved, setSolved] = useState([]);
  const [selection, setSelection] = useState("")
//   const handleChange = (e,i,j) => {
//     // [...vendorKeys.slice(0,index), ...vendorKeys.slice(index+1)]
//     const trx = [...calls[i].slice(0,j), e.target.value, ...calls[i].slice(j+1)];
//     setCalls([...calls.slice(0,i), trx, ...calls.slice(i+1)])
//     // setVendors({...vendors, [i]:e.target.value});
//   }
  const gather = (list, number, name, isClient) => {
    let leftovers = [];
    let matches = [];
    for (let i = 0; i < list.length; i++) {
      if(list[i].Number===number) matches.push({ ...list[i], Name: name, isClient });
      else leftovers.push(list[i])
    }
    return [matches, leftovers]
  }
  const handleRead = e => {
    const reader = new FileReader()
    reader.onload = () => {
      const json = csv2json(reader.result);
      setCalls(json);
    }
    reader.readAsBinaryString(e.target.files[0])
  }
  const handleMatch = (client, notFound) => {
    const [matches, leftovers] = gather(calls, calls[0].Number, notFound?"Not Found":from, client);
    setCalls(leftovers);
    setSolved([...solved, ...matches]);
    if(!notFound) setFrom("")
  }
  const handleConvert = () => {
    const output = [...solved, ...calls];
    // keys=[""]
    let result = resultHeaders.join(",")+"\r\n"+output.map((call,i)=>{
      return '"'+resultHeaders.map(key=>call[key]).join(`","`)+'"';
      // return '"'+call.map((field,j)=>getValue(i,j)).join(`","`)+'"';
      // return '"'+[...trx.slice(0,2),vendor,...trx.slice(3,8), from, ...trx.slice(9,12)]
    }).join("\r\n");
    // // console.log(`result`, result);
    download(result, "calls.csv", "text/plain");
  }
  useEffect(() => {
    if(calls.length) navigator.clipboard.writeText(calls[0].Number);
  },[calls])
  return (
    <Container maxWidth={800}>
      <Row>
        <TextField label="Name" value={from} onChange={e => setFrom(e.target.value)}/>
        <input type="file" accept=".csv" onChange={handleRead}/>
      </Row>

      <BigText>Next Number: {calls.length?calls[0].Number:null}</BigText>
      <BigText>Numbers left: {calls.length}</BigText>
      {/* <Button onClick={copyNumber}>Copy</Button> */}
      <Button onClick={()=>handleMatch(false, true)}>Not Found</Button>
      <Button onClick={()=>handleMatch(true)}>Client</Button>
      <Button onClick={()=>handleMatch(false)}>Not Client</Button>

      {/* <Text>Calls:</Text>
      <Pre>{JSON.stringify(calls, null, 2)}</Pre> */}
      <Text>Solved:</Text>
      <Pre>{JSON.stringify(solved, null, 2)}</Pre>
      <FabGroup right>
        {solved.length?<Fab primary Icon={DownloadIcon} onClick={handleConvert}/>:null}
      </FabGroup>
    </Container>
  )
}

export default Converter;