import { useRef, useEffect } from "react";

export const useClickAway = onClickAway => {
    const ref = useRef(null);
    useEffect(() => {
        const handleClickAway = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                if (onClickAway) onClickAway();
                else alert("You clicked outside of me!");
            }
        }
        document.addEventListener("mousedown", handleClickAway);
        return () => {
            document.removeEventListener("mousedown", handleClickAway);
        };
    // ---eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
    return ref;
}

export default useClickAway;