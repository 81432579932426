import React from 'react'
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { getPostQuery } from './queries'
import Query from '../../atoms/Query';
import useUser from '../../hooks/useUser';
import { FabGroup, BackFab, EditFab } from '../../atoms/Fab';
import getUrl from '../../lib/getUrl';
import WriterCard from '../Writers/Card';
import Recent from './Recent'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
`;
const Content = styled.div`
    flex: 0 1 800px;
`;

const Heading = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 1em 0 0;
`;
const Dateline = styled.p`
  // color: #2C3038;
  // margin: 0;
  // text-align: center;
  // font-size: 1rem;
`;
const Banner = styled.img`
  width: 100%;
  height:100%;
`;
const Metadata = styled.div`
  display: flex;
  font-size: 1rem;
  gap: 1em;
  color: #6f6f6f;
`;
const Category = styled.div`
  
`;
// const Tags = styled.div`

// `;
// const Tag = styled.div `
  
// `;
const Body = styled.p `
  font-size: 1.2rem;
  margin-top: 2em;
  font-family: Lora;
`;
export const Detail = props => {
  const [user] = useUser()
  const post = props.getPost||{};
  // console.log(`post`, post);
  const date = new Date(post.createdAt||"").toLocaleDateString('en-US', {
    month: 'long',
    day:'numeric',year: 'numeric',
  })
  return (
    <Container>
      <Content>
        <Heading>{post.title}</Heading>
        <Banner loading="lazy" src={post.bannerImgUrl}/>
        <Metadata>
          <Dateline>Published: {date}</Dateline>
          <Category>Posted in: {post.category}</Category>
          {/*<Tags>Tags: {(post.tags||[]).map(tag=><Tag key={tag}>{tag}</Tag>)}</Tags>*/}
        </Metadata>
        <Body>
          {post.body}
        </Body>
        <WriterCard {...post.writer}/>
      </Content>
      <Recent/>
      <FabGroup left><BackFab/></FabGroup>
      {user&&<FabGroup right><EditFab to={getUrl('post','edit', post.id)}/></FabGroup>}
    </Container>
  )
}

const PostWithData = props => {
  const { id } = useParams();
  return <Query Component={Detail} query={getPostQuery} variables={{ id }} id={id} {...props}/>
}
export default PostWithData;