/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://qjj43u3rma.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://o7uiyqpawbavzp7jgob3mph3uu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-677slvjvafdavjexnit6pjk2re",
    "aws_cognito_identity_pool_id": "us-east-1:028741ce-fa13-42dc-9b71-b0d0ff3efd1d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HxUK9dPPh",
    "aws_user_pools_web_client_id": "2e6lhepoleg3h12tc25h04rke6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "woodbourne-storage170732-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
