import styled from 'styled-components/macro';
export const Content = styled.div`
  // visibility: hidden;
  opacity: 0;
  transition: opacity .5s ease-in-out .5s;
  width: ${p=>p.width||120}px;
  background-color: ${p=>p.theme.colors.black};
  color: ${p => p.theme.colors.darkgrey};
  text-align: center;
  padding: .5rem;
  border-radius: ${p=>p.theme.borderRadius};

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 200;
  top: calc(100% + .5rem);
  left: calc(${p=>p.width||120}px / -2 + 50%);
  pointer-events:none;
  @media screen and (max-width: 991px) {
    
  }
  @media screen and (max-width: 767px) {

  }
  @media screen and (max-width: 478px) {
    width: 50px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  // display: inline-block;
  // border-bottom: 1px dotted black;
  &:hover ${Content}{
    // visibility: visible;
    opacity: 100%;
  }
`;

export const Tooltip = ({ className, title, children, onClick, ...props}) => (
  <Wrapper className={className} onClick={onClick}>
    {title && <Content {...props}>{title}</Content>}
    {children}
  </Wrapper>
)
export default Tooltip;
