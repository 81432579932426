/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      bump
      updatedAt
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      bump
      updatedAt
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      bump
      updatedAt
      __typename
    }
  }
`;
export const createWriter = /* GraphQL */ `
  mutation CreateWriter(
    $input: CreateWriterInput!
    $condition: ModelWriterConditionInput
  ) {
    createWriter(input: $input, condition: $condition) {
      id
      name
      avatarUrl
      description
      posts {
        nextToken
        __typename
      }
      drafts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWriter = /* GraphQL */ `
  mutation UpdateWriter(
    $input: UpdateWriterInput!
    $condition: ModelWriterConditionInput
  ) {
    updateWriter(input: $input, condition: $condition) {
      id
      name
      avatarUrl
      description
      posts {
        nextToken
        __typename
      }
      drafts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWriter = /* GraphQL */ `
  mutation DeleteWriter(
    $input: DeleteWriterInput!
    $condition: ModelWriterConditionInput
  ) {
    deleteWriter(input: $input, condition: $condition) {
      id
      name
      avatarUrl
      description
      posts {
        nextToken
        __typename
      }
      drafts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDraft = /* GraphQL */ `
  mutation CreateDraft(
    $input: CreateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    createDraft(input: $input, condition: $condition) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateDraft = /* GraphQL */ `
  mutation UpdateDraft(
    $input: UpdateDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    updateDraft(input: $input, condition: $condition) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteDraft = /* GraphQL */ `
  mutation DeleteDraft(
    $input: DeleteDraftInput!
    $condition: ModelDraftConditionInput
  ) {
    deleteDraft(input: $input, condition: $condition) {
      id
      type
      createdAt
      cardImgUrl
      bannerImgUrl
      category
      tags
      title
      snippet
      body
      writerID
      writer {
        id
        name
        avatarUrl
        description
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateContactUs = /* GraphQL */ `
  mutation UpdateContactUs(
    $input: UpdateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    updateContactUs(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactUs = /* GraphQL */ `
  mutation DeleteContactUs(
    $input: DeleteContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    deleteContactUs(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNewsletterSignup = /* GraphQL */ `
  mutation UpdateNewsletterSignup(
    $input: UpdateNewsletterSignupInput!
    $condition: ModelNewsletterSignupConditionInput
  ) {
    updateNewsletterSignup(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNewsletterSignup = /* GraphQL */ `
  mutation DeleteNewsletterSignup(
    $input: DeleteNewsletterSignupInput!
    $condition: ModelNewsletterSignupConditionInput
  ) {
    deleteNewsletterSignup(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      name
      key
      type
      children {
        nextToken
        __typename
      }
      parentID
      filePath
      creator
      size
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      name
      key
      type
      children {
        nextToken
        __typename
      }
      parentID
      filePath
      creator
      size
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      name
      key
      type
      children {
        nextToken
        __typename
      }
      parentID
      filePath
      creator
      size
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      kind
      displayName
      org
      firstName
      lastName
      middleName
      prefix
      suffix
      nickname
      phones {
        tags
        type
        number
        pref
        person
        __typename
      }
      addresses {
        type
        pref
        street
        city
        state
        stateCode
        postalCode
        country
        countryCode
        __typename
      }
      emails {
        type
        address
        pref
        person
        __typename
      }
      related {
        type
        value
        __typename
      }
      photo
      birthday
      anniversary
      gender
      department
      lang
      tz
      geo
      title
      role
      logo
      tags
      note
      web
      hours
      source
      congregation
      invoices {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      kind
      displayName
      org
      firstName
      lastName
      middleName
      prefix
      suffix
      nickname
      phones {
        tags
        type
        number
        pref
        person
        __typename
      }
      addresses {
        type
        pref
        street
        city
        state
        stateCode
        postalCode
        country
        countryCode
        __typename
      }
      emails {
        type
        address
        pref
        person
        __typename
      }
      related {
        type
        value
        __typename
      }
      photo
      birthday
      anniversary
      gender
      department
      lang
      tz
      geo
      title
      role
      logo
      tags
      note
      web
      hours
      source
      congregation
      invoices {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      kind
      displayName
      org
      firstName
      lastName
      middleName
      prefix
      suffix
      nickname
      phones {
        tags
        type
        number
        pref
        person
        __typename
      }
      addresses {
        type
        pref
        street
        city
        state
        stateCode
        postalCode
        country
        countryCode
        __typename
      }
      emails {
        type
        address
        pref
        person
        __typename
      }
      related {
        type
        value
        __typename
      }
      photo
      birthday
      anniversary
      gender
      department
      lang
      tz
      geo
      title
      role
      logo
      tags
      note
      web
      hours
      source
      congregation
      invoices {
        nextToken
        __typename
      }
      accounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      contactId
      contact {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      clientName
      type
      items {
        id
        name
        quantity
        price
        total
        description
        __typename
      }
      total
      number
      paid
      date
      due
      month
      sentOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      contactId
      contact {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      clientName
      type
      items {
        id
        name
        quantity
        price
        total
        description
        __typename
      }
      total
      number
      paid
      date
      due
      month
      sentOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      contactId
      contact {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      clientName
      type
      items {
        id
        name
        quantity
        price
        total
        description
        __typename
      }
      total
      number
      paid
      date
      due
      month
      sentOn
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVendorKey = /* GraphQL */ `
  mutation CreateVendorKey(
    $input: CreateVendorKeyInput!
    $condition: ModelVendorKeyConditionInput
  ) {
    createVendorKey(input: $input, condition: $condition) {
      id
      key
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVendorKey = /* GraphQL */ `
  mutation UpdateVendorKey(
    $input: UpdateVendorKeyInput!
    $condition: ModelVendorKeyConditionInput
  ) {
    updateVendorKey(input: $input, condition: $condition) {
      id
      key
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVendorKey = /* GraphQL */ `
  mutation DeleteVendorKey(
    $input: DeleteVendorKeyInput!
    $condition: ModelVendorKeyConditionInput
  ) {
    deleteVendorKey(input: $input, condition: $condition) {
      id
      key
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      name
      number
      clientId
      client {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      type
      entries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      name
      number
      clientId
      client {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      type
      entries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      name
      number
      clientId
      client {
        id
        kind
        displayName
        org
        firstName
        lastName
        middleName
        prefix
        suffix
        nickname
        photo
        birthday
        anniversary
        gender
        department
        lang
        tz
        geo
        title
        role
        logo
        tags
        note
        web
        hours
        source
        congregation
        createdAt
        updatedAt
        __typename
      }
      type
      entries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEntry = /* GraphQL */ `
  mutation CreateEntry(
    $input: CreateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    createEntry(input: $input, condition: $condition) {
      id
      date
      description
      amount
      number
      accountId
      account {
        id
        name
        number
        clientId
        type
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEntry = /* GraphQL */ `
  mutation UpdateEntry(
    $input: UpdateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    updateEntry(input: $input, condition: $condition) {
      id
      date
      description
      amount
      number
      accountId
      account {
        id
        name
        number
        clientId
        type
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEntry = /* GraphQL */ `
  mutation DeleteEntry(
    $input: DeleteEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    deleteEntry(input: $input, condition: $condition) {
      id
      date
      description
      amount
      number
      accountId
      account {
        id
        name
        number
        clientId
        type
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSnippet = /* GraphQL */ `
  mutation CreateSnippet(
    $input: CreateSnippetInput!
    $condition: ModelSnippetConditionInput
  ) {
    createSnippet(input: $input, condition: $condition) {
      contactId
      field
      bank
      key
      vendor
      category
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSnippet = /* GraphQL */ `
  mutation UpdateSnippet(
    $input: UpdateSnippetInput!
    $condition: ModelSnippetConditionInput
  ) {
    updateSnippet(input: $input, condition: $condition) {
      contactId
      field
      bank
      key
      vendor
      category
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSnippet = /* GraphQL */ `
  mutation DeleteSnippet(
    $input: DeleteSnippetInput!
    $condition: ModelSnippetConditionInput
  ) {
    deleteSnippet(input: $input, condition: $condition) {
      contactId
      field
      bank
      key
      vendor
      category
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactUs = /* GraphQL */ `
  mutation CreateContactUs(
    $input: CreateContactUsInput!
    $condition: ModelContactUsConditionInput
  ) {
    createContactUs(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNewsletterSignup = /* GraphQL */ `
  mutation CreateNewsletterSignup(
    $input: CreateNewsletterSignupInput!
    $condition: ModelNewsletterSignupConditionInput
  ) {
    createNewsletterSignup(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
